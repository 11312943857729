import { SetStateAction, useContext, useRef, useState } from "react";
import { VscCloudUpload } from "react-icons/vsc";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "./modal/Modal";
import { toast } from "react-toastify";
import {
  UPLOAD_DELIVERY_MANIFEST,
  UPLOAD_ORDER_MANIFEST,
} from "../helpers/constant";
import api from "../http/api/api_client";

interface AddTruckModalProps {
  orderId: any;
  handleFetchOrderDetails: any;
  showUploadManifest: any;
  setShowUploadManifest: any;
  isDelivered: boolean;
}

const UploadOrderManifest = ({
  showUploadManifest,
  setShowUploadManifest,
  handleFetchOrderDetails,
  orderId,
  isDelivered,
}: AddTruckModalProps) => {
  const [selectedDocuments, setSelectedDocuments] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [error, setError] = useState<string>(""); // State to hold error message
  const formRef = useRef<HTMLFormElement | null>(null);

  const uploadToCloudinary = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "truckiupload");

    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dsrasvzjn/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();
    return data.secure_url;
  };

  const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const newFiles = Array.from(event.target.files);
    const validFiles = newFiles.filter((file) => isValidFileType(file));

    setSelectedDocuments((prevDocuments) => [...prevDocuments, ...validFiles]);
  };

  const isValidFileType = (file: File) => {
    const allowedExtensions = ["png", "jpg", "pdf", "jpeg"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    return allowedExtensions.includes(extension!);
  };

  const removeDocument = (file: File) => {
    setSelectedDocuments((prevDocuments) =>
      prevDocuments.filter((doc) => doc !== file)
    );
  };

  const handleAddTrucks = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(""); // Clear any previous errors
    setStatus("Uploading images...");

    try {
      const documentUrls = await Promise.all(
        selectedDocuments.map(uploadToCloudinary)
      );

      const requestBody = {
        documents: documentUrls,
        orderId: orderId,
      };

      setStatus("Submitting...");
      const response = await api.post(
        isDelivered ? UPLOAD_DELIVERY_MANIFEST : UPLOAD_ORDER_MANIFEST,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSelectedDocuments([]);
      setShowUploadManifest(false);
      handleFetchOrderDetails();
      toast("Order Manifest Uploaded Successfully");
    } catch (error: any) {
      console.error("Form submission error:", error);
      setError("Failed to upload manifest. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isVisible={showUploadManifest}
      onClosed={() => {
        setSelectedDocuments([]);
        setShowUploadManifest(false);
      }}
    >
      <div>
        <h1 className="font-black">
          {isDelivered ? "Upload Delivery Documents" : "Upload Manifest"}
        </h1>
        <form ref={formRef} onSubmit={handleAddTrucks}>
          <div className="pb-4 w-full border-dashed border border-darkBlue-100 pt-1">
            <div className="pb-4">
              <label className="form-file-holder text-xs flex flex-col justify-center items-center cursor-pointer">
                <span className="text-lg w-fit text-darkBlue-100 mx-auto pt-3">
                  <VscCloudUpload />
                </span>
                <span className="text-darkBlue-100">
                  {isDelivered
                    ? "Upload Delivery Documents"
                    : "Upload Documents"}{" "}
                  (PNG, JPG, or PDF):
                </span>
                <p className="flex items-center gap-1 pb-3">
                  <span>Drag & drop file or</span>
                  <span className="underline font-bold text-darkBlue-100">
                    Browse
                  </span>
                </p>
                <input
                  type="file"
                  id="documents"
                  name="documents"
                  multiple
                  accept=".png,.jpg,.pdf,.jpeg"
                  onChange={handleDocumentUpload}
                />
              </label>
            </div>
          </div>
          {selectedDocuments.length > 0 && (
            <div className="mt-2">
              {selectedDocuments.map((document) => (
                <div
                  key={document.name}
                  className="rounded flex flex-row justify-between bg-teal-50 m-2 p-3"
                >
                  <p>{document.name}</p>
                  <span
                    className="text-red-500 cursor-pointer"
                    onClick={() => removeDocument(document)}
                  >
                    <RiDeleteBin6Line />
                  </span>
                </div>
              ))}
            </div>
          )}
          {error && <p className="text-red-500 mt-2">{error}</p>} {/* Error message */}
          <div className="py-3 lg:py-5 text-center">
            <button
              className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursor-pointer text-white-100 ${isLoading ? "opacity-50" : ""
                }`}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? `${status}...` : "Upload"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UploadOrderManifest;
