"use client";

import React, { ChangeEvent, useEffect, useState } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { BsArrowUp } from "react-icons/bs";
import { GoArrowDown } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {
  DashboardData,
  GTVData,
  ManagerDashboardData,
  OrderStats,
} from "../../../Types/DashboardData";
import api, { ApiResponse } from "../../../http/api/api_client";
import SummaryCard from "../../../components/summary-card/SummaryCard";
import {
  FinancialStatusCard,
  StatsCard,
} from "../../../components/finaincial-status/FinancialStatusCard";
import FinancialBarChart from "../../../components/barchart/FinancialBarChart";
import ExpensesChart from "../../../components/barchart/ExpensesChart";
import DrivingChart from "../../../components/barchart/DrivingChart";
import {
  GET_DASHBOARD_GTV_ENDPOINT,
  GET_DASHBORA_ENDPOINT,
  GET_MANAGER_DASHBOARD_DATA,
  Get_Manager_Gtv_DashBoard_Summary,
  GET_ORDER_STATS,
} from "../../../helpers/constant";
import { motion } from "framer-motion";
import DetailsShimmerCard from "../../../components/shimmer/DetailsShimmerCard";
import { useSelector } from "react-redux";
import TripBarChart from "../../../components/barchart/TripBarChart";
import { formatMoney } from "../../../utiles/moneyFormatter";

interface RootState {
  auth: {
    userInfo: {
      // Properties of userInfo
      id: string;
    };
  };
}

const Dashboard = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const router = useNavigate();
  const [dashboardData, setDashboardData] = useState<ManagerDashboardData>();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [gtvData, setGtvData] = useState<GTVData>({
    totalGtv: 0,
    totalPayout: 0,
    totalRevenue: 0,
    monthlyData: [],
  });

  const [orderStats, setOrderStats] = useState<OrderStats>({
    completedOrders: 0,
    flaggedOrders: 2,
    inTransitOrders: 0,
    totalOrders: 0,
    monthlyData: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(true);

  const fetchDashboardGTV = async (start: string, end: string) => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${Get_Manager_Gtv_DashBoard_Summary}?startDate=${start}&endDate=${end}`
      );
      if (response.statusCode === 200) {
        const data = response.data;
        setGtvData(data.data);
        console.log(data.data, "GTV data");
      }
    } catch (error) {
      console.error("Error fetching GTV data:", error);
    }
  };

  const fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(GET_MANAGER_DASHBOARD_DATA);
      if (response.statusCode === 200) {
        const data = response.data;
        setDashboardData(data.data);
        console.log(data.data, "dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrderStats = async () => {
    try {
      const response: ApiResponse<any> = await api.get(GET_ORDER_STATS);
      if (response.statusCode === 200) {
        const data = response.data;
        setOrderStats(data.data);
        console.log(data.data, "order stats data");
      }
    } catch (error) {
      console.error("Error fetching order stats:", error);
    }
  };

  const handleDateChange =
    (
      setter: React.Dispatch<React.SetStateAction<string>>,
      isStartDate: boolean
    ) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setter(value);

        const newStartDate = isStartDate ? value : startDate;
        const newEndDate = !isStartDate ? value : endDate;

        if (new Date(newStartDate) > new Date(newEndDate)) {
          console.error("Start date cannot be greater than end date");
          return;
        }

        fetchDashboardGTV(newStartDate, newEndDate);
        //  fetchOrderStats(newStartDate, newEndDate);
      };

  useEffect(() => {
    // Wait for 3 seconds
    setTimeout(() => {
      setPreload(false);
    }, 3000);

    setIsLoading(true);
    fetchDashboardData();
    fetchOrderStats();
    const defaultStart = new Date(
      new Date().setMonth(new Date().getMonth() - 5)
    )
      .toISOString()
      .split("T")[0];
    const defaultEnd = new Date().toISOString().split("T")[0];
    setStartDate(defaultStart);
    setEndDate(defaultEnd);
    fetchDashboardGTV(defaultStart, defaultEnd);
    setIsLoading(false);
  }, []);

  return (
    <div>
      <div>
        <h1 className="text-xl pb-3">Dashboard</h1>
        <motion.div
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          className="flex flex-wrap gap-5"
        >
          <SummaryCard
            img={<img src="/group1.png" width={50} height={50} alt="" />}
            title="Total Orders"
            rate={dashboardData?.totalOrder}
            handleCardClick={() => {
              router("/manager/orders");
            }}
          />
          <SummaryCard
            img={<img src="/group2.png" width={50} height={50} alt="" />}
            title="Orders in Transit"
            rate={dashboardData?.inTransitOrders}
            handleCardClick={() => {
              router("/manager/trucks");
             }}
          />
          <SummaryCard
            img={<img src="/group3.png" width={50} height={50} alt="" />}
            title="Completed Orders"
            rate={dashboardData?.completedOrders}
            handleCardClick={() => {
              router("/manager/orders");
            }}
          />
          <SummaryCard
            img={<img src="/group4.png" width={50} height={50} alt="" />}
            title="Flagged Order"
            rate={dashboardData?.flaggedOrders}
            handleCardClick={() => {
              router("/manager/orders");
            }}
          />
        </motion.div>
        <div className="flex flex-col lg:flex-row gap-5 justify-between mt-4">
          <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 300, opacity: 0 }}
            className="w-[100%] lg:w-[50%] bg-white-100 rounded-md p-2"
          >
            <div className="sm:flex justify-between items-center p-2">
              <span>Financial</span>
              <div className="flex sm:gap-x-2 gap-x-1">
                <div className="text-sm text-darkBlue p-2 rounded-md">
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleDateChange(setStartDate, true)}
                    className="py-3 px-0 sm:px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                  />
                </div>
                <div className="text-sm text-darkBlue p-2 sm:p-2 rounded-md">
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleDateChange(setEndDate, false)}
                    className="py-3 px-0 sm:px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2  md:grid-cols-4 py-2 gap-3 px-2 lg:py-4 bg-white-100 overflow-x-auto overflow-hidden md:overflow-x-scroll">
              <FinancialStatusCard
                title="Total GTV"
                amount={formatMoney(gtvData!.totalGtv)}
                percentage={<BsArrowUp />}
              />
              <FinancialStatusCard
                title="Total Payout"
                amount={formatMoney(gtvData!.totalPayout)}
                percentage={<BsArrowUp />}
              />
              <FinancialStatusCard
                title="Total Revenue"
                amount={formatMoney(gtvData!.totalRevenue)}
                percentage={<GoArrowDown />}
              />
              <FinancialStatusCard
                title="Income"
                amount={formatMoney(
                  gtvData!.totalRevenue - gtvData!.totalPayout
                )}
                percentage={<BsArrowUp />}
              />
            </div>
            <div className="mt-[20px] w-[100%] overflow-x-auto overflow-hidden">
              {preload ? (
                <DetailsShimmerCard />
              ) : (
                <FinancialBarChart data={gtvData!.monthlyData} />
              )}
            </div>
          </motion.div>
          <div className="w-[100%] lg:w-[50%] bg-white-100 rounded-md p-2">
            <div className="flex justify-between items-center p-2">
              <span>Expenses</span>
              <div className="text-sm text-darkBlue p-2 rounded-md">
                <input
                  type="date"
                  className="py-3 px-2 w-full border border-[darkBlue] rounded-md focus:border-darkBlue bg-transparent"
                />
              </div>
            </div>
            <div className="">
              <div className="relative z-0">
                <ExpensesChart />
              </div>
              {/* <div className="flex justify-between md:block mt-0 md:mt-10 pt-4 md:pt-6 ml-0 md:ml-0 lg:ml-20">
                <div className="flex items-center text-[12px] my-3">
                  <span className="rounded-full text-mygreen-200 bg-mygreen-200 w-[6px] h-[6px] flex items-center"></span>
                  <p className="pl-2 flex flex-col">
                    <span className="">Maintainance</span>
                    <span className="flex justify-between text-[10px] text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
                <div className="flex items-center text-[12px] my-3">
                  <span className="rounded-full text-darkBlue-100 bg-darkBlue-100 w-[6px] h-[6px] flex items-center"></span>
                  <p className="pl-2 flex flex-col">
                    <span className="">Labour Cost</span>
                    <span className="flex justify-between text-[10px] text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
                <div className="flex items-center text-[12px] my-3">
                  <span className="rounded-full text-myyellow-200 bg-myyellow-200 w-[6px] h-[6px] flex items-center"></span>
                  <p className="pl-2 flex flex-col">
                    <span className="">Fuel</span>
                    <span className="flex justify-between gap-x-4 text-[10px] text-gray-500 ">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
                <div className="flex items-center text-[12px] my-3">
                  <span className="rounded-full text-myyellow-100 bg-myyellow-100 w-[6px] h-[6px] flex items-center"></span>
                  <p className="pl-2 flex flex-col">
                    <span className="">Others</span>
                    <span className="flex justify-between gap-x-4 text-[10px] text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 justify-between mt-4">
          <div className="w-[100%] lg:w-[52%] bg-white-100 rounded-md p-2 overflow-x-auto overflow-hidden">
            <div>
              <div className="flex justify-between items-center p-2">
                <span>Driving Distance</span>
                <div className="flex items-center justify-start text-sm text-darkBlue-100 border border-darkBlue-100 p-2 rounded-md">
                  <span className="pr-2">January 2024</span>
                  <span>
                    <MdOutlineDateRange />
                  </span>
                </div>
              </div>
              {preload ? <DetailsShimmerCard /> : <DrivingChart />}
            </div>
          </div>
          <div className="w-[100%] lg:w-[48%] bg-white-100 rounded-md p-2">
            <div className="flex justify-between items-center p-2">
              <span>Trips Performance</span>
              <div className="flex items-center justify-start text-sm text-darkBlue-100 border border-darkBlue-100 p-2 rounded-md">
                <span className="pr-2">January 2024</span>
                <span>
                  <MdOutlineDateRange />
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2  md:grid-cols-4 py-2 gap-2 px-2 lg:py-4 bg-white overflow-x-auto">
              <StatsCard
                title="Pending"
                amount={orderStats.totalOrders}
                percentage={<BsArrowUp />}
              />
              <StatsCard
                title="Intransit"
                amount={orderStats.inTransitOrders}
                percentage={<BsArrowUp />}
              />
              <StatsCard
                title="Flagged"
                amount={orderStats.flaggedOrders}
                percentage={<GoArrowDown />}
              />
              <StatsCard
                title="Completed"
                amount={orderStats.completedOrders}
                percentage={<BsArrowUp />}
              />
            </div>
            <div className="mt-[20px] w-[100%] overflow-x-auto overflow-hidden">
              {preload ? (
                <DetailsShimmerCard />
              ) : (
                <TripBarChart data={orderStats!.monthlyData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
