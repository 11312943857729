import { format, parseISO } from "date-fns";

const FormattedDate2 = ({ date }: any) => {
  const formatDate = (dateString: string) => {
    const parsedDate = parseISO(dateString);
    return format(parsedDate, "MMM d yyyy ");
  };

  return <span>{formatDate(date)}</span>;
};
export default FormattedDate2;
