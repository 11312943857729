import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { logout } from "../../http/auth";
interface IdleTimeoutProps {
  onIdle: () => void;
  idleTime?: number; // Default value will be 1 if not provided
}

const useIdleTimeout = ({ onIdle, idleTime = 1 }: IdleTimeoutProps) => {
  const idleTimeout = 1000 * idleTime; // Convert idleTime to milliseconds
  const [isIdle, setIdle] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleIdle = () => {
    setIdle(true);
    handleLogout(); // Handle logout
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2, // Prompt before idle
    onPrompt: onIdle, // Call the onIdle prop when prompting
    onIdle: handleIdle, // Call when user becomes idle
    debounce: 500,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};

export default useIdleTimeout;
