"use client";

import React from "react";
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import { MonthlyDatum } from "../../Types/DashboardData";

const FinancialBarChart = ({ data }: { data: MonthlyDatum[] }) => {
  console.log(data);
  return (
    <>
      <div className="sm:-mx-3 lg:-mx-7 text-xs  lg:px-10">
        <AreaChart
          width={600}
          height={320}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3 3" />
          <XAxis dataKey="name" />
          <YAxis dataKey="income" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="revenue"
            stroke="#CE2A96"
            fill="#CE2A96"
          />
          <Area
            type="monotone"
            dataKey="income"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="payout"
            stroke="#6B60FF"
            fill="#6B60FF"
          />
        </AreaChart>
      </div>

    </>
  );
};

export default FinancialBarChart;
