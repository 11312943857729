import { useEffect, useState } from "react";
import { CiMail } from "react-icons/ci";
import {
  MdLockOutline,
  MdOutlineRemoveRedEye,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { authenticateUser } from "../../http/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../components/forgot-password/ForgotPassword";
import EmailConfirmation from "../../components/email-confirmation/EmailConfirmationModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface RootState {
  auth: {
    userInfo: {
      // Properties of userInfo
    };
  };
}

export default function Login() {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [isSubmitting, setIssubmitting] = useState<boolean>(false);
  const [passwordState, setPasswordState] = useState<boolean>(true); // true = hidden, false = visible
  const [showForgotPassword, setShowForgotPassword] = useState(true);
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const dispatch = useDispatch();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const router = useNavigate();

  const backToLogin = () => {
    setShowEmailConfirmation(false);
    setShowSignup(false);
  };

  const toDashboard = () => {
    router("/dashboard");
  };

  const validateForm = (): boolean => {
    const isValid = !!email && !!password;
    if (!isValid) {
      setErrorMsg("Email and password are required");
    } else {
      setErrorMsg("");
    }
    return isValid;
  };

  const handleSubmitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIssubmitting(true);

    try {
      const { token, role, isPasswordChanged } = await authenticateUser(
        email,
        password,
        dispatch
      );
      if (token) {
        if (role) {
          if (role.includes("admin")) {
            toDashboard();
          } else if (
            role.includes("manager") ||
            role.includes("finance") ||
            role.includes("chiefmanager")
          ) {
            if (isPasswordChanged === false) {
              router("/reset");
            } else {
              router("/manager");
            }
          } else if (role.includes("field officer")) {
            if (isPasswordChanged === false) {
              router("/reset");
            } else {
              router("/manager");
            }
          } else if (role.includes("finance")) {
            if (isPasswordChanged === false) {
              router("/reset");
            } else {
              router("/manager");
            }
          } else {
            console.warn("Unrecognized user role:", role);
          }
        }
      } else {
        setErrorMsg("Invalid email or password");
      }
    } catch (error) {
      setErrorMsg("An error occurred during login");
    } finally {
      setIssubmitting(false);
    }
  };

  useEffect(() => {
    if (userInfo) {
      if ((userInfo as { id: string }).id === "admin") {
        router("/dashboard");
      } else {
        if (
          (userInfo as { isPasswordChanged: boolean }).isPasswordChanged ===
          false
        ) {
          router("/reset");
        } else {
          router("/manager");
        }
      }
    } else {
      router("/");
    }
  }, [router, userInfo]);

  return (
    <>
      {showForgotPassword ? (
        <main
          className="flex items-center justify-center min-h-screen bg-cover bg-center relative"
          style={{ backgroundImage: "url('/login-bg.png')" }}
        >
          {showSignup ? (
            <div className="w-full h-[60vh] lg:h-[100vh]">
              {/* Signup form */}
            </div>
          ) : (
            <div className="w-full h-[70vh] lg:h-[100vh]">
              <div className="w-[90%] lg:w-[40%] h-auto rounded-xl mx-auto lg:ml-10 bg-white-100 py-14 px-2 md:px-16 lg:px-20 mt-[10%]">
                <form onSubmit={handleSubmitLogin} className="opacity-90">
                  <div className="text-center pb-5">
                    <h1 className="text-3xl font-semibold">Log in</h1>
                    <p className="text-gray-450 text-xl py-3">Welcome back!</p>
                  </div>
                  <div>
                    <span className="absolute mt-5 ml-2 z-10 text-gray-450">
                      <CiMail className="w-5 h-5" />
                    </span>
                    <input
                      type="email"
                      placeholder="Email address"
                      value={email}
                      onChange={handleEmailChange}
                      className="py-4 pl-8 pr-4 w-full border border-gray-400 rounded-xl focus:outline-none focus:border-gray-400 bg-transparent relative"
                    />
                  </div>
                  <div className="mt-5 relative">
                    <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                      <MdLockOutline className="w-5 h-5" />
                    </span>
                    <input
                      type={passwordState ? "password" : "text"}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      className="py-4 pl-8 pr-12 w-full border border-gray-400 rounded-xl focus:outline-none focus:border-gray-400 bg-transparent relative"
                    />
                    <span
                      className="absolute right-3 top-4 z-10 text-gray-450 cursor-pointer"
                      onClick={() => setPasswordState(!passwordState)}
                    >
                      {passwordState ? (
                        <MdOutlineRemoveRedEye className="w-5 h-5" />
                      ) : (
                        <MdOutlineVisibilityOff className="w-5 h-5" />
                      )}
                    </span>
                  </div>
                  {errorMsg && (
                    <i className="text-red-500 text-sm mt-1">{errorMsg}</i>
                  )}
                  <div className="text-right pt-2">
                    <span
                      className="text-darkBlue-100 text-sm cursor-pointer"
                      onClick={() => setShowForgotPassword(!showForgotPassword)}
                    >
                      Forgot Password
                    </span>
                  </div>
                  <div className="mt-[10%] cursor-pointer">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`bg-darkBlue-100 py-4 text-white-100 w-full rounded-md ${
                        isSubmitting ? "opacity-50" : ""
                      }`}
                    >
                      {isSubmitting ? "Logging in..." : "Log In"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </main>
      ) : (
        <>
          <div className="lg:pl-20 py-4 mb-10">
            <img src="/trucki-logo.png" width={100} height={100} alt="" />
          </div>
          <div className="w-[90%] lg:w-[40%] mx-auto">
            <ForgotPassword
              handleShowLogin={() => setShowForgotPassword(!showForgotPassword)}
            />
          </div>
        </>
      )}
      {showEmailConfirmation && (
        <div className="bg-gray-300 h-screen w-screen flex justify-center items-center absolute top-0 left-0 z-50">
          <EmailConfirmation continueLogin={backToLogin} />
        </div>
      )}
    </>
  );
}
