// Trucks.tsx

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { TruckiType } from "../../../Types/Trucks";
import { useLocation, useNavigate } from "react-router-dom";
import api, { ApiResponse } from "../../../http/api/api_client";
import {
  GETORDER_BYSTATUS,
} from "../../../helpers/constant";

import SummaryCard from "../../../components/summary-card/SummaryCard";
import TruckCard from "../../../components/truck-card/TruckCard";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineDateRange } from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import OrderTrackingMap from "../../../components/OrderTrackingMap";
import { OrdersData } from "../../../Types/Orders";
import FormattedDate from "../../../components/FormattedDate";

const Trucks = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [orderTableData, setOrderTableData] = useState<OrdersData[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<OrdersData | null>(null); // Track selected order
  const pathname = location.pathname;

  useEffect(() => {
    fetchAllOrders();
  }, [navigate, searchQuery]);

  const [activeComponent, setActiveComponent] = useState<
    "all" | "en route" | "available route" | "out of service"
  >("all");

  const handleClick = (
    component: "all" | "en route" | "available route" | "out of service"
  ) => {
    setActiveComponent(component);
  };

  const fetchAllOrders = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(`${GETORDER_BYSTATUS}?status=2`);
      if (response.statusCode === 200) {
        const data = response.data;
        const sortedOrders = data.data.sort((a: OrdersData, b: OrdersData) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setOrderTableData(sortedOrders);
        setIsLoading(false);
        console.log(sortedOrders, "displaying order data");
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      setIsLoading(false);
    }
  };

  const initialLocation = { lat: 37.7749, lng: -122.4194 };

  return (
    <>
      <div>
        <h1 className="text-xl pb-3">Trucks</h1>
        <motion.div
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          className="flex flex-wrap gap-5 lg:gap-0 lg:flex-row lg:justify-between"
        >
          <SummaryCard
            img={<img src="/group5.png" width={50} height={50} alt="" />}
            title="Truck with errors"
            rate={0}
            handleCardClick={() => { }}
          />
          <SummaryCard
            img={<img src="/group3.png" width={50} height={50} alt="" />}
            title="Truck with warning"
            rate={0}
            handleCardClick={() => { }}
          />
          <SummaryCard
            img={
              <img src="/group-location.png" width={50} height={50} alt="" />
            }
            title="Side route"
            rate={0}
            handleCardClick={() => { }}
          />
          <SummaryCard
            img={<img src="/group4.png" width={50} height={50} alt="" />}
            title="Late delivery"
            rate={0}
            handleCardClick={() => { }}
          />
        </motion.div>
        <div className="flex flex-col lg:flex-row gap-2 mt-4">
          <div className="w-[100%] lg:w-[30%] flex flex-col gap-2">
            {orderTableData.map((e) => (
              <TruckCard key={e.id} onClick={() => setSelectedOrder(e)}>
                <div className="flex flex-col text-xs">
                  <span className="flex gap-1 items-center rounded-sm px-2 bg-white-200">
                    <span className="rounded-full text-darkBlue-100 bg-darkBlue-100 w-[6px] h-[6px]"></span>
                    <span>In Transit</span>
                  </span>
                  <span className="mt-2">Truck No: {e.truckNo}</span>
                  <span className="flex items-center gap-2 my-2">
                    <span className="text-darkBlue-200">
                      <CiLocationOn />
                    </span>
                    <span className="text-gray-350">
                      {e.routes?.fromRoute || "N/A"}
                    </span>
                  </span>
                  <span className="flex items-center gap-2 my-2">
                    <span className="text-darkBlue-200">
                      <MdOutlineDateRange />
                    </span>
                    <span className="text-gray-350">
                      {e.startDate === undefined ? (
                        `N/A`
                      ) : (
                        <FormattedDate date={e.startDate} />
                      )}
                    </span>
                  </span>
                  <span className="flex items-center gap-2 my-2">
                    <span className="text-darkBlue-200">
                      <IoTimeOutline />
                    </span>
                    <span className="text-gray-350">1h 15min</span>
                  </span>
                </div>
              </TruckCard>
            ))}
          </div>
          <div className="w-[100%] lg:w-[70%] h-100">
            {selectedOrder ? (
              selectedOrder.routes ? (
                <OrderTrackingMap
                  initialLocation={initialLocation}
                  orderLocation={{
                    lat: Number(selectedOrder.deliveryLocationLat),
                    lng: Number(selectedOrder.deliveryLocationLong),
                  }}
                  routeFrom={{
                    lat: Number(selectedOrder.routes.fromRouteLat),
                    lng: Number(selectedOrder.routes.fromRouteLng),
                  }}
                  routeTo={{
                    lat: Number(selectedOrder.routes.toRouteLat),
                    lng: Number(selectedOrder.routes.toRouteLng),
                  }}
                  currentLocation={{
                    lat: Number(selectedOrder.deliveryLocationLat),
                    lng: Number(selectedOrder.deliveryLocationLong),
                  }}
                />
              ) : (
                <p className="text-center mt-5">No route information available for this order.</p>
              )
            ) : (
              <p className="text-center mt-5">Select an order to view its location on the map</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Trucks;
