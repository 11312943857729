import React, { useState } from "react";
import api, { ApiResponse } from "../http/api/api_client";
import { EDITROUTE_ENDPOINT } from "../helpers/constant";
import Modal from "./modal/Modal";
import AddressForm from "./AddressForm";
import { RoutesEntity } from "../Types/Business";

interface EditRouteModalProps {
  isVisible: boolean;
  onClose: () => void;
  routeToEdit: RoutesEntity;
  onRouteUpdated: () => void; // Callback to refresh routes in the parent
}

const EditRouteModal: React.FC<EditRouteModalProps> = ({
  isVisible,
  onClose,
  routeToEdit,
  onRouteUpdated,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fromRouteLat, setFromRouteLat] = useState<string | undefined>(routeToEdit.fromRouteLat);
  const [fromRouteLng, setFromRouteLng] = useState<string | undefined>(routeToEdit.fromRouteLng);
  const [toRouteLat, setToRouteLat] = useState<string | undefined>(routeToEdit.toRouteLat);
  const [toRouteLng, setToRouteLng] = useState<string | undefined>(routeToEdit.toRouteLng);
  const [fromAddress, setFromAddress] = useState(routeToEdit.fromRoute);
  const [toAddress, setToAddress] = useState(routeToEdit.toRoute);

  const handleEditRoute = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData(e.target as HTMLFormElement);
      const requestBody = {
        id: routeToEdit.id,
        name: formData.get("name"),
        fromRoute: fromAddress,
        fromRouteLat,
        fromRouteLng,
        toRoute: toAddress,
        toRouteLat,
        toRouteLng,
        price: formData.get("price"),
        ntons: formData.get("ntons"),
        gtv: formData.get("gtv"),
      };

      const response: ApiResponse<any> = await api.post(EDITROUTE_ENDPOINT, requestBody);

      if (response.statusCode === 200) {
        console.log("Route updated successfully:", response.data);
        onRouteUpdated();
        onClose();
      }
    } catch (error) {
      console.error("Error updating route:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isVisible={isVisible} onClosed={onClose}>
      <div>
        <h1 className="mb-10 font-extrabold">Edit Route</h1>
        <form onSubmit={handleEditRoute}>
          <div className="flex flex-col justify-between gap-5">
            <input
              type="hidden"
              name="name"
              defaultValue={routeToEdit.name}
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <AddressForm
              onAddressSelect={(lat, lng, address) => {
                setFromRouteLat(lat.toString());
                setFromRouteLng(lng.toString());
                setFromAddress(address);
              }}
              AddressLabel="From Route"
              clearLocationAddress={false}
              defaultLat={fromRouteLat ? parseFloat(fromRouteLat) : undefined}
              defaultLng={fromRouteLng ? parseFloat(fromRouteLng) : undefined}
              defaultAddress={fromAddress}
            />
            <AddressForm
              onAddressSelect={(lat, lng, address) => {
                setToRouteLat(lat.toString());
                setToRouteLng(lng.toString());
                setToAddress(address);
              }}
              AddressLabel="To Route"
              clearLocationAddress={false}
              defaultLat={toRouteLat ? parseFloat(toRouteLat) : undefined}
              defaultLng={toRouteLng ? parseFloat(toRouteLng) : undefined}
              defaultAddress={toAddress}
            />
            <input
              type="text"
              name="ntons"
              defaultValue={routeToEdit.ntons}
              placeholder="Tonnage"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="price"
              defaultValue={routeToEdit.price}
              placeholder="Price"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="gtv"
              defaultValue={routeToEdit.gtv}
              placeholder="GTV"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <div className="py-5 text-center">
              <button
                className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursor-pointer text-white-100 ${
                  isLoading ? "opacity-50" : ""
                }`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditRouteModal;
