import { useState } from "react";
import {
  MdLockOutline,
  MdOutlineRemoveRedEye,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import React from "react";
import { useNavigate } from "react-router-dom";
import api, { ApiResponse } from "../../http/api/api_client";
import { RESETUSERSPASSWORD, UPDATE_USER_PASSWORD } from "../../helpers/constant";
import { toast } from "react-toastify";


export default function Reset() {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordState, setPasswordState] = useState<boolean>(true);
  const [oldPasswordState, setOldPasswordState] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const router = useNavigate();
  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission
    setErrorMessage(""); // Reset error message

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    if (oldPassword === "") {
      setErrorMessage("Old Password cannot be null.");
      return;
    }

    setIsLoading(true);
    const requestBody = {
      "oldPassword": oldPassword,
      "newPassword": password,
      "confirmNewPassword": confirmPassword
    };

    try {
      const response: ApiResponse<any> = await api.post(UPDATE_USER_PASSWORD, requestBody);
      if (response.statusCode === 200) {
        const userString = localStorage.getItem("userKey");
        console.log(userString, "the string");
        const user = userString ? JSON.parse(userString) : null;
        if (user!.role) {
          if (user!.role.includes("admin")) {
            router("/dashboard");
          } else if (
            user!.role.includes("manager") ||
            user!.role.includes("finance") ||
            user!.role.includes("chiefmanager")
          ) {
            router("/manager");
          } else if (user!.role.includes("field officer")) {
            router("/manager");
          } else if (user!.role.includes("finance")) {

            router("/manager");
          } else {
            console.warn("Unrecognized user role:", user!.role);
          }
        }
        toast.success("Password updated successfully!");
      } else {
        setErrorMessage("Failed to update password.");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="lg:pl-20 py-4 mb-10">
        <img src="/trucki-logo.png" width={100} height={100} alt="" />
      </div>
      <main className="mx-auto w-[90%] text-center">
        <div className="w-fit mx-auto">
          <img
            src="/forg-pass.png"
            width={300}
            height={300}
            alt="verify-logo"
          />
        </div>
        <h1 className="text-3xl font-medium mt-5 py-4">Reset Password</h1>
        <p className="text-gray-450">
          You new password must be different from previously used password
        </p>
        <form
          onSubmit={handleResetPassword}
          className="opacity-90 w-[90%] lg:w-[40%] mx-auto"
        >
          <div className="mt-5 flex flex-col justify-between gap-5">
            <div className="relative">
              <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                <MdLockOutline className="w-5 h-5" />
              </span>
              <input
                type={oldPasswordState ? "password" : "text"}
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="py-4 pl-8 pr-12 w-full border border-gray-400 rounded-xl focus:outline-none focus:border-gray-400 bg-transparent relative"
              />
              <span
                className="absolute right-3 top-4 z-10 text-gray-450 cursor-pointer"
                onClick={() => setOldPasswordState(!oldPasswordState)}
              >
                {!oldPasswordState ? (
                  <MdOutlineRemoveRedEye className="w-5 h-5" />
                ) : (
                  <MdOutlineVisibilityOff className="w-5 h-5" />
                )}
              </span>
            </div>
            <div className="relative">
              <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                <MdLockOutline className="w-5 h-5" />
              </span>
              <input
                type={passwordState ? "password" : "text"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="py-4 pl-8 pr-12 w-full border border-gray-400 rounded-xl focus:outline-none focus:border-gray-400 bg-transparent relative"
              />
              <span
                className="absolute right-3 top-4 z-10 text-gray-450 cursor-pointer"
                onClick={() => setPasswordState(!passwordState)}
              >
                {!passwordState ? (
                  <MdOutlineRemoveRedEye className="w-5 h-5" />
                ) : (
                  <MdOutlineVisibilityOff className="w-5 h-5" />
                )}
              </span>
            </div>
            <div className="mt-1 relative">
              <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                <MdLockOutline className="w-5 h-5" />
              </span>
              <input
                type={passwordState ? "password" : "text"}
                placeholder="Confirm user's password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="py-4 pl-8 pr-12 w-full border border-gray-400 rounded-xl focus:outline-none focus:border-gray-400 bg-transparent relative"
              />
              <span
                className="absolute right-3 top-4 z-10 text-gray-450 cursor-pointer"
                onClick={() => setPasswordState(!passwordState)}
              >
                {!passwordState ? (
                  <MdOutlineRemoveRedEye className="w-5 h-5" />
                ) : (
                  <MdOutlineVisibilityOff className="w-5 h-5" />
                )}
              </span>
            </div>

            {errorMessage && (
              <span className="text-red-500 text-sm">{errorMessage}</span>
            )}
            <div className="py-3 text-center">
              <button
                className={`text-ms py-3 bg-darkBlue-100 w-full rounded-md cursor-pointer text-white-100 ${isLoading ? "opacity-50" : ""
                  }`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Resetting..." : "Reset"}
              </button>
            </div>
          </div>

          <div className="mt-[3%] flex justify-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                router("/manager");
              }}
              className="text-lg font-semibold text-[#98A2B3]"
            >
              Remind Me Later
            </button>
          </div>
        </form>
      </main>
    </>
  );
}
