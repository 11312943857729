import React, { ReactNode, useState } from "react";
import axios from "axios";
import CircularProgressIndicator from "../CircularProgressIndicator";

const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/dsrasvzjn/upload";
const CLOUDINARY_UPLOAD_PRESET = "truckiupload";

interface FormFileProps {
  id: string; // Unique ID for the file input
  browse: string;
  fileText: string;
  fileText2: string;
  icon: ReactNode;
  onUpload: any;
}

const FormFile: React.FC<FormFileProps> = ({
  id,
  browse,
  fileText,
  fileText2,
  icon,
  onUpload,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      setIsLoading(true);

      reader.onload = async () => {
        setSelectedImage(reader.result as string);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);

        try {
          const response = await axios.post(CLOUDINARY_URL, formData);
          const imageUrl = response.data.secure_url;
          onUpload?.(imageUrl);
        } catch (error) {
          console.error("Error uploading to Cloudinary:", error);
        } finally {
          setIsLoading(false);
          // Reset the file input so the same file can be reselected
          e.target.value = "";
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="w-full border-dashed border border-darkBlue-100 pt-1">
      {selectedImage ? (
        isLoading ? (
          <div className="h-[100px] w-[236px] mx-auto my-auto flex items-center justify-center">
            <CircularProgressIndicator />
          </div>
        ) : (
          <label
            className="h-[150px] w-[236px] mx-auto cursor-pointer"
            htmlFor={id}
          >
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-full object-contain"
            />
            <input
              id={id}
              className="hidden"
              type="file"
              accept=".png,.jpg,.pdf,.jpeg"
              onChange={handleUploadImage}
            />
          </label>
        )
      ) : (
        <label
          className="form-file-holder text-xs flex flex-col justify-center items-center cursor-pointer"
          htmlFor={id}
        >
          <span className="text-lg w-fit text-darkBlue-100 mx-auto pt-3">
            {icon}
          </span>
          <span className="text-darkBlue-100">{fileText}</span>
          <p className="flex items-center gap-1 pb-3">
            <span>{fileText2}</span>
            <span className="underline font-bold text-darkBlue-100">
              {browse}
            </span>
          </p>
          <input id={id} className="hidden" type="file" onChange={handleUploadImage} />
        </label>
      )}
    </div>
  );
};

export default FormFile;
