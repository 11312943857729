import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { logout } from '../auth';
import { BASE_URL } from '../../helpers/constant';
import { store } from '../../redux/store';
import { logingout } from '../../redux/Slices/authSlice';

export interface ApiResponse<T> {
    data: T;
    message?: string;
    isSuccessful: boolean;
    statusCode: number;
    status: number;
    statusText: string;
    headers: any;
    config: any;
}

// Factory function to create custom API responses
export const formatResponse = <T>(
    data: T,
    message?: string,
    isSuccessful = true,
    statusCode = 200,
    status = 0,
    statusText = 'ok',
    headers?: any,
    config?: any,
): ApiResponse<T> => {
    return {
        data,
        message,
        status,
        statusText,
        config,
        headers,
        isSuccessful,
        statusCode,
    };
};

const getAuthTokenFromLocalStorage = () => {
    try {
        const token = localStorage.getItem('tokenKey');
        if (token) {
            return token;
        }
    } catch (err) {
        console.error('Error retrieving token from local storage:', err);
    }
    return null;
};

const api: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000, // Set a timeout of 10 seconds
});

api.interceptors.response.use(
    (response: AxiosResponse) => {
        return formatResponse(response.data, response.statusText, true, response.status);
    },
    (error: AxiosError) => {
        console.log('Error response status:', error.response?.status); // Add this line
        // Handle 401 Unauthorized errors
        if (error.response?.status === 401) {
            console.log('Logout function is called');
            try {
                store.dispatch(logingout()); // Dispatch your logout action
                logout(); // Perform any additional logout logic
                window.location.href = '/'; // Redirect to the login page or home page
            } catch (err) {
                console.error(err);
            }
        }

        // Handle 500 Server Errors with a popup
        if (error.response?.status === 500) {
            toast.error("Something went wrong. Please try again later.");
        }

        // Handle other errors
        const errorResponse = formatResponse(
            null,
            error.message,
            false,
            error.response?.status || 500
        );

        return Promise.reject(errorResponse);
    }
);

api.interceptors.request.use(
    (config: any) => {
        const useToken = config.useToken || true;

        if (useToken) {
            const token = getAuthTokenFromLocalStorage();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                console.warn('Token not found in local storage. Request may be unauthorized.');
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
