import React from "react";
import { FinancialStatusCardProps, StatusCardProps } from "../../Types";

const FinancialStatusCard: React.FC<FinancialStatusCardProps> = ({
  title,
  amount,
  percentage,
}) => (
  <div className="flex justify-start items-center p-2 md:py-2 md:pl-2 bg-white-100  shadow-sm w-full border border-[#F5F5F5] rounded-md">
    <div className="flex flex-col text-[10px] lg:text-sm">
      <span className="text-gray-500">{title}</span>
      <span className="text-darkBlue-100">{amount}</span>
    </div>
  </div>
);

const StatsCard: React.FC<StatusCardProps> = ({
  title,
  amount,
  percentage,
}) => (
  <div className="flex justify-between lg:justify-start items-center p-2 md:py-2 md:pl-2 bg-white-100 w-full shadow-sm border border-[#F5F5F5] rounded-md">
    <div className="flex flex-col text-[10px] lg:text-xs">
      <span className="text-darkBlue-100">{title}</span>
      <span className="text-gray-500"> {amount}</span>
    </div>
    <div className="flex">
      <span className="pl-1 flex items-center text-[10px] md:text-lg">
        {percentage}
      </span>
      <span className="text-xs">0.0%</span>
    </div>
  </div>
);

// Named exports
export { FinancialStatusCard, StatsCard };
