import React, { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

import { MdAddCircleOutline } from "react-icons/md";
import {
  ADDBUSINESS_ENDPOINT,
  ALLBUSINESS_ENDPOINT,
  DELETEBUSINESS_ENDPOINT,
  EDITBUSINESS_ENDPOINT,
  SEARCH_BUSINESSES,
} from "../../../helpers/constant";
import { useNavigate } from "react-router-dom";
import api, { ApiResponse } from "../../../http/api/api_client";
import TableShimmer from "../../../components/shimmer/TableShimmer";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import AddRouteModal from "../../../components/add-route-modal";
import AddressForm from "../../../components/AddressForm";

interface BusinessData {
  id?: any;
  name: string;
  location: string;
  address: string;
  isActive?: boolean;
}

const Business = () => {
  const [showAddBusinessModal, setShowAddBusinessModal] =
    useState<boolean>(false);
  const [showEditBusinessModal, setShowEditBusinessModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddRoute, setShowAddRoute] = useState<boolean>(false);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [businessTableData, setBusinessTableData] = useState<BusinessData[]>(
    []
  );
  const [businessToEdit, setBusinessToEdit] = useState<BusinessData>();
  const [businessIdToAddRoute, setBusinessIdToAddRoute] = useState<string>();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useNavigate();

  const handleSearchBusiness = async () => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${SEARCH_BUSINESSES}?searchWords=${searchQuery}`
      );
      if (response.statusCode === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error("Error searching business:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const fetchAllBusiness = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(ALLBUSINESS_ENDPOINT);
      if (response.statusCode === 200) {
        const data = response.data;
        setBusinessTableData(data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(businessTableData.length / itemsPerPage);

  const currentBusinessData = businessTableData.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  const addNewBusiness = async (
    e: React.FormEvent<HTMLFormElement>
    // businessId: number | null
  ) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name");
    const location = formData.get("location");
    const address = formData.get("address");
    const isActive = formData.get("isActive") === "true"; // Handle boolean conversion

    // Construct the request body with extracted values
    const requestBody = {
      name,
      location,
      address,
      isActive,
    };
    const response: ApiResponse<any> = await api.post(
      ADDBUSINESS_ENDPOINT,
      requestBody
    );
    if (response.statusCode === 201) {
      await fetchAllBusiness();
      setShowAddBusinessModal(false);
    }
  };

  const EditBusiness = async (
    e: React.FormEvent<HTMLFormElement>
    // businessId: number | null
  ) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target as HTMLFormElement);
    const id = formData.get("id");
    const name = formData.get("name");
    const location = formData.get("location");
    const address = formData.get("address");
    const isActive = formData.get("isActive") === "true";
    // Construct the request body with extracted values
    const requestBody = {
      id,
      name,
      location,
      address,
      isActive,
    };
    const response: ApiResponse<any> = await api.post(
      EDITBUSINESS_ENDPOINT,
      requestBody
    );
    if (response.statusCode === 200) {
      await fetchAllBusiness();
      setShowEditBusinessModal(false);
      setIsLoading(false);
    }
  };

  const handleAddRouteClick = (businessId: string) => {
    // Set the businessId state and open the modal
    setShowAddRoute(true);
    // Optionally, store the businessId in another state variable
    setBusinessIdToAddRoute(businessId);
  };
  const handleEditBusinessClick = (business: BusinessData) => {
    // Set the businessId state and open the modal
    setShowEditBusinessModal(true);
    // Optionally, store the businessId in another state variable
    setBusinessToEdit(business);
  };
  const handleDeleteBusiness = (businessId: string) => {
    // Set the businessId state and open the modal
    setShowDeleteModal(true);
    setBusinessIdToAddRoute(businessId);
    // Optionally, store the businessId in another state variable
  };
  const DeleteBusiness = async () => {
    if (businessIdToAddRoute !== "" || businessIdToAddRoute != null) {
      const response: ApiResponse<any> = await api.post(
        `${DELETEBUSINESS_ENDPOINT}?businessId=${businessIdToAddRoute}`
      );
      if (response.statusCode === 200) {
        await fetchAllBusiness();
        setShowDeleteModal(false);
        setBusinessIdToAddRoute("");
      }
    }
  };

  useEffect(() => {
    fetchAllBusiness();
    if (searchQuery !== "") {
      handleSearchBusiness();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, searchQuery]);
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-start mb-10 py-2 border-b border-gray-300">
        <div className="flex items-center mb-4 md:mb-0">
          <h1>Business</h1>
          <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
            <input
              type="text"
              placeholder="Search Business"
              value={searchQuery}
              onChange={handleChange}
              className="outline-none"
            />
            <span
              className="text-gray-500 cursor-pointer"
              onClick={handleSearchBusiness}
            >
              <GoSearch className="w-[20px] h-[20px]" />
            </span>
          </div>
        </div>
        <span className="font-bold">
          <button
            className="text-white-100 bg-darkBlue-100 hover:bg-gray-500 rounded-md flex items-center py-3 pr-4 text-xs"
            onClick={() => setShowAddBusinessModal(true)}
          >
            <span>
              <FaPlus className="w-10" />
            </span>{" "}
            <span>Add Business</span>
          </button>
        </span>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
          <thead className="bg-white-100 text-gray-450 py-4 font-light rounded-t-lg">
            <tr className="border-b border-gray-200">
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Business Name
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Location
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Address
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider text-right whitespace-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="min-w-full divide-y">
            {isLoading ? (
              <TableShimmer />
            ) : (
              (searchResults.length > 0
                ? searchResults
                : currentBusinessData
              ).map((data) => (
                <tr
                  key={data.id}
                  className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                >
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/business/${data.id}`)}
                  >
                    {data.name}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/business/${data.id}`)}
                  >
                    {data.location}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/business/${data.id}`)}
                  >
                    {data.address}
                  </td>
                  <td className="flex items-center py-4 px-2">
                    <div className="flex items-center gap-x-3 ml-auto">
                      <span
                        className="flex gap-1 items-center sm:text-sm text-mygreen-100  hover:bg-gray-300 p-3 rounded-md"
                        onClick={() => handleAddRouteClick(data.id)}
                      >
                        <span>
                          <MdAddCircleOutline />
                        </span>
                        <span className="hidden lg:block">Add Route</span>
                      </span>
                      <span
                        className="text-darkBlue-100 sm:text-sm cursor-pointer  hover:bg-gray-300 p-3 rounded-md"
                        onClick={() => handleEditBusinessClick(data)}
                      >
                        <FaRegEdit />
                      </span>
                      <span
                        className="text-red-500 sm:text-sm cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                        onClick={() => handleDeleteBusiness(data.id)}
                      >
                        <RiDeleteBin6Line />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            )}
            {searchResults.length === 0 && searchQuery && !isLoading && (
              <tr>
                <td colSpan={4}>
                  <div className="flex justify-center items-center h-60">
                    <img
                      src="/nodata.png"
                      width={100}
                      height={100}
                      alt="No data"
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
        <div className="flex items-center gap-2 text-sm text-gray-450">
          <span>View</span>
          <span>
            <select
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}
              className="p-1 bg-white-100 cursor-pointer"
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </span>
          <span>businesses per page</span>
        </div>
        <Pagination pageCount={pageCount} handlePageClick={handlePageChange} />
      </div>

      <Modal
        isVisible={showAddBusinessModal}
        onClosed={() => setShowAddBusinessModal(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Add New Business</h1>
          <form onSubmit={addNewBusiness}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                placeholder="Business Name"
                name="name"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                placeholder="Location"
                name="location"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                placeholder="Address"
                name="address"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-full rounded-md cursor-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Add Business"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isVisible={showEditBusinessModal}
        onClosed={() => setShowEditBusinessModal(false)}
      >
        <div>
          <h1 className="mb-10 font-extrabold">Edit Business</h1>
          <form onSubmit={EditBusiness}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="name"
                defaultValue={businessToEdit?.name}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="location"
                defaultValue={businessToEdit?.location}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                name="id"
                defaultValue={businessToEdit?.id}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="address"
                defaultValue={businessToEdit?.address}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <div className="py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-full rounded-md cursor-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        isVisible={showDeleteModal}
        onClosed={() => setShowDeleteModal(false)}
      >
        <div className="w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Business?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm cursor-pointer"
              onClick={() => DeleteBusiness()}
            >
              Delete
            </button>
          </div>
        </div>
      </ConfirmationModal>
      <AddRouteModal
        businessId={businessIdToAddRoute!}
        isVisible={showAddRoute}
        onClosed={() => setShowAddRoute(false)}
      />
    </>
  );
};

export default Business;
