
export interface Truck {
  id: string;
  documents: string[];
  certOfOwnerShip: string;
  plateNumber: string;
  truckCapacity: string;
  driverId: string;
  driverName: string;
  capacity: string;
  truckOwnerId: string;
  truckOwnerName: string;
  truckName: string;
  truckType: number;
  truckLicenseExpiryDate: string;
  roadWorthinessExpiryDate: string;
  insuranceExpiryDate: string;
  truckNumber: string;
  truckStatus: number;
  createdAt: Date;
}


export interface AddTruckData {
  documents: string[];
  plateNumber: string;
  truckCapacity: string;
  driverId: string;
  truckName: string;
  truckOwnerId: string;
  truckType: number;
  truckLicenseExpiryDate: string;
  roadWorthinessExpiryDate: string;
  insuranceExpiryDate: string;
}

export enum TruckiType {
  Flatbed,
  BoxBody,
  BucketBody,
  Lowbed,
  ContainerizedBody,
  Refrigerator,
}

export const TruckiTypeNames: Record<number, string> = {
  [TruckiType.Flatbed]: "Flatbed",
  [TruckiType.BoxBody]: "Box Body",
  [TruckiType.BucketBody]: "Bucket Body",
  [TruckiType.Lowbed]: "Lowbed",
  [TruckiType.ContainerizedBody]: "Containerized Body",
  [TruckiType.Refrigerator]: "Refrigerator",
};
// Define reverse mapping
