"use client";

import React from "react";
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import {  MonthlyStatDatum } from "../../Types/DashboardData";

const TripBarChart = ({ data }: { data: MonthlyStatDatum[] }) => {
  console.log(data);
  return (
    <>
      <div className="sm:-mx-3 lg:-mx-7 text-xs  lg:px-10">
        <AreaChart
          width={500}
          height={320}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="completedOrders"
            stroke="#CE2A96"
            fill="#CE2A96"
          />
          <Area
            type="monotone"
            dataKey="flaggedOrders"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="inTransitOrders"
            stroke="#6B60FF"
            fill="#6B60FF"
          />

          <Area
            type="monotone"
            dataKey="totalOrders"
            stroke="#15B8A6"
            fill="#15B8A6"
          />
        </AreaChart>
      </div>
    </>
  );
};

export default TripBarChart;
