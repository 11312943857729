import React, { useEffect } from 'react';
import { authenticateUser } from '../../http/auth';
import {useNavigate} from "react-router-dom";

const withAuth = (Component: React.ComponentType<any>) => {
  return function WithAuth(props: any) {
    const router = useNavigate();

    useEffect(() => {
      const checkAuth = async () => {
        try {
          const email = 'Admin@trucki.com';
          const password = 'Admin@1234';
          const dispatch = "";
 

          const isAuthenticated = await authenticateUser(
            email,
            password,
            dispatch,
          );
          if (!isAuthenticated) {
            router('/');
          } else if (isAuthenticated) {
            return <Component {...props} />
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          router('/');
        }
      };

      checkAuth();
    }, [props, router]);
    return <Component {...props} />;
  };
};

export default withAuth;

