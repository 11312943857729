import Select from "react-select";
import { useEffect, useState } from "react";
import api, { ApiResponse } from "../http/api/api_client";
import {
  ASSIGN_TRUCK_TO_ORDER,
  GET_ALL_CUSTOMERS_ENDPOINT,
  GET_ALL_TRUCKS,
  GET_ROUTES_BY_BUSINESSID,
} from "../helpers/constant";
import Modal from "./modal/Modal";

// Define interfaces for data structures
interface Truck {
  id: string;
  truckCapacity: string;
  plateNumber: string;
}

interface AddOrderModalProps {
  businessId: string | undefined;
  orderId: string | undefined;
  assignOrder: boolean;
  setAssignOrder: React.Dispatch<React.SetStateAction<boolean>>;
  onClosed: () => void;
}

function UpdateOrderModal({
  businessId,
  orderId,
  assignOrder,
  setAssignOrder,
  onClosed,
}: AddOrderModalProps) {
  // State variables
  const [isFetchingTrucks, setIsFetchingTrucks] = useState<boolean>(false); // For fetching trucks
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // For assigning truck
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // For error messages

  // Fetch trucks when the modal is opened
  useEffect(() => {
    if (assignOrder) {
      fetchAllTrucks();
    }
    // Optionally, reset error messages when modal opens
    return () => {
      setErrorMessage(null);
    };
  }, [assignOrder]);

  // Function to fetch all trucks
  const fetchAllTrucks = async () => {
    setIsFetchingTrucks(true);
    setErrorMessage(null); // Reset previous errors
    try {
      const response: ApiResponse<any> = await api.get(GET_ALL_TRUCKS);
      if (response.statusCode === 200) {
        const data = response.data;
        setTrucks(data.data);
        console.log(data.data, "displaying truck data");
      } else {
        // Handle API errors
        setErrorMessage(response.message || "Failed to fetch trucks. Please try again.");
      }
    } catch (error: any) {
      // Handle network or unexpected errors
      setErrorMessage(error.message || "Failed to fetch trucks. Please try again.");
    } finally {
      setIsFetchingTrucks(false);
    }
  };

  // Function to handle truck assignment
  const handleAssignTruckToOrder = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null); // Reset previous errors
    try {
      const formData = new FormData(e.target as HTMLFormElement);
      const truckId = formData.get("truckId") as string | null;

      if (!truckId) {
        setErrorMessage("Please select a truck to assign.");
        setIsSubmitting(false);
        return;
      }

      const requestBody = {
        orderId,
        truckId,
      };

      const response: ApiResponse<any> = await api.post(
        ASSIGN_TRUCK_TO_ORDER,
        requestBody
      );

      if (response.statusCode === 200) {
        setAssignOrder(false);
        onClosed();
      } else {
        // Handle API errors
        setErrorMessage(response.message || "Something went wrong, try again.");
      }
    } catch (error: any) {
      // Handle network or unexpected errors
      setErrorMessage(error.message || "Something went wrong, try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Prepare truck options for the Select component
  const truckOptions = trucks.map((truck) => ({
    value: truck.id,
    label: `${truck.plateNumber} - ${truck.truckCapacity}`,
  }));

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "2px solid #4F46E5" : "1px solid #CCCCCD", // Enhanced focus border
      boxShadow: state.isFocused ? "0 0 0 1px #4F46E5" : "none",
      padding: "0.4rem 10px",
      borderRadius: "8px", // Adjusted radius for better aesthetics
      transition: "border-color 0.2s, box-shadow 0.2s",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#9CA3AF", // Gray color for placeholder
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#4F46E5"
        : state.isFocused
        ? "#E0E7FF"
        : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };

  return (
    <Modal isVisible={assignOrder} onClosed={() => setAssignOrder(false)}>
      <div className="p-4">
        <h1 className="font-black mb-4 text-lg">Assign Truck</h1>
        <form onSubmit={handleAssignTruckToOrder}>
          <div className="mb-4">
            {isFetchingTrucks ? (
              <div className="flex items-center justify-center">
                {/* Simple spinner */}
                <svg
                  className="animate-spin h-5 w-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
                <span className="ml-2 text-gray-500">Fetching trucks...</span>
              </div>
            ) : (
              <Select
                name="truckId"
                styles={customSelectStyles}
                options={truckOptions}
                placeholder="Select Truck"
                isDisabled={isSubmitting} // Disable Select during submission
                className="w-full"
              />
            )}
          </div>

          {errorMessage && (
            <div className="mb-4 text-red-500 text-sm">
              {errorMessage}
            </div>
          )}

          <div className="py-3 lg:py-5 text-center">
            <button
              className={`text-ms py-3 bg-darkBlue-100 w-full rounded-md cursor-pointer text-white-100 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-darkBlue-200"
              }`}
              type="submit"
              disabled={isSubmitting || isFetchingTrucks}
            >
              {isSubmitting ? "Assigning..." : "Assign"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateOrderModal;
