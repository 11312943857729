import React, { useState } from 'react'
import Pagination from '../pagination/Pagination';

const activityTableData = [
    {
      id: 1,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "christo@gmail.com",
      activity: "View Trucks Owner",
    },
    {
      id: 2,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "eijer33@yahoo.com",
      activity: "View Trucks Owner",
    },
  
    {
      id: 3,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "christybaby@yahoo.com",
      activity: "View Trucks Owner",
    },
    {
      id: 4,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",   
      email: "mathina99@gmail.com",
      activity: "View Trucks Owner"
    },
    {
      id: 5,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "emmanuel98oseme@gmail.com",
      activity: "View Trucks Owner"
    },
    {
      id: 6,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "christinanana@gmail.com",
      activity: "View Trucks Owner"
    },
    {
      id: 7,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "patrickr335@gmail.com",
      activity: "View Trucks Owner",
    },
    {
      id: 8,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "kalisco@yahoo.com",
      activity: "View Trucks Owner",
    },
    {
      id: 9,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "fatianku@gmail.com",
      activity: "View Trucks Owner"
    },
    {
      id: 10,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",   
      email: "enzizimabandzi@gmail.com",
      activity: "View Trucks Owner"
    },
    {
      id: 11,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "vidash79@hotmail.com",
      activity: "View Trucks Owner"
    },
    {
      id: 12,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "jahseed89@gmail.com",
      activity: "View Trucks Owner"
    },
  
    {
      id: 13,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "w9tefd@gmail.com",
      activity: "View Trucks Owner",
    },
    {
      id: 14,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "aasquere@hotmail.com",
      activity: "View Trucks Owner",
    },
    {
      id: 15,
      date: "29/01/2024",
      time: "10:00 AM",
      user: "Jone Doe",
      email: "james990@gmail.com",
      activity: "View Trucks Owner"
    },
  ];



  interface ActivityLogProps {
    handleDetails: ()=> void
  } 
  

const ActivityLog: React.FC<ActivityLogProps> = ({handleDetails}) => {
    const [itemOffset, setItemOffset] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);

    const handlePageChange = ({ selected }: { selected: number }) => {
        const newOffset = selected * itemsPerPage;
        setItemOffset(newOffset);
      };
    
      const handleItemsPerPageChange = (
        e: React.ChangeEvent<HTMLSelectElement>
      ) => {
        const newItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(newItemsPerPage);
        setItemOffset(0);
      };
    
      const pageCount = Math.ceil(activityTableData.length / itemsPerPage);
    
      const currentItems = activityTableData.slice(
        itemOffset,
        itemOffset + itemsPerPage
      );
    return (
        <>
        <div className="overflow-x-auto mt-6 bg-white-100">
            <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
            <thead className="bg-white-100 text-gray-450 py-4 font-light">
              <tr className="border-b border-gray-200">
                <th className="py-4 px-2 text-gray-350 tracking-wider">
                  Date
                </th>
                <th className="py-4 px-2 text-gray-350 tracking-wider">
                  Time
                </th>
                <th className="py-4 px-2 text-gray-350 tracking-wider">
                  User
                </th>
                <th className="py-4 px-2 text-gray-350 tracking-wider">
                  Email Address
                </th>
                <th className="py-4 px-2 text-gray-350 tracking-wider">
                  Activity
                </th>
              </tr>
            </thead>
            <tbody className="min-w-full divide-y">
              {currentItems.map((data) => (
                <tr
                  key={data.id}
                  className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                >
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={handleDetails}
                  >
                    {data.date}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={handleDetails}
                  >
                    {data.time}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={handleDetails}
                  >
                    {data.user}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={handleDetails}
                  >
                    {data.email}
                  </td>
                  <td className="py-4 px-2 whitespace-nowrap" 
                    onClick={handleDetails}
                  >
                    {data.activity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
                <div className="flex items-center gap-2 text-sm text-gray-450">
                  <span>View</span>
                  <span>
                    <select
                      onChange={handleItemsPerPageChange}
                      value={itemsPerPage}
                      className="p-1 bg-white-100"
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </span>
                  <span>truck per page</span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  handlePageClick={handlePageChange}
                />
              </div>
        </>
    )
}
export default ActivityLog;

