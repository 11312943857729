import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { GrDocumentPdf } from "react-icons/gr";
import useLocalStorage from "../../../../context/useLocalstorage";
import api, { ApiResponse } from "../../../../http/api/api_client";
import {
  GET_ORDER_BY_ID,
  PAY_40_PERCENT,
  PAY_60_PERCENT,
} from "../../../../helpers/constant";
import ManagerLayout from "../../../../components/mng-layout/ManagerLayout";
import { Link, useParams } from "react-router-dom";
import UploadOrderManifest from "../../../../components/upload-manifest-modal";
import UpdateOrderModal from "../../../../components/update_order_modal";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import FormattedDate from "../../../../components/FormattedDate";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import ErrorLoading from "../../../../components/shimmer/ErrorLoading";
enum TruckType {
  Flatbed = 0,
  BoxBody = 1,
  BucketBody = 2,
  Lowbed = 3,
  ContainerizedBody = 4,
  Refrigerator = 5,
}
interface OrderDetailsInterface {
  id: string;
  orderId: string;
  businessId: string;
  truckNo: string;
  quantity: string;
  cargoType: string;
  orderStatus: number;
  fieldOfficerName: string;
  routeFrom: string;
  routeTo: string;
  startDate: string;
  endDate: string;
  price: number;
  driver: string;
  customer: string;
  deliveryLocation: string;
  documents: string[];
  deliveryDocuments: string[];
  truckOwnerName: string;
  truckOwnerBankName: string;
  truckOwnerBankAccountNumber: string;
  is60Paid: boolean;
  is40Paid: boolean;
  deliveryLocationLat: string;
  deliveryLocationLong: string;
  consignment: string;

}

const OrderDetails = () => {
  const { orderId } = useParams();
  const [showUploadManifest, setShowUploadManifest] = useState<boolean>(false);
  const [isDelivery, setIsDelivery] = useState<boolean>(false);

  const [assignOrder, setAssignOrder] = useState<boolean>(false);

  const [order, setOrder] = useState<OrderDetailsInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | Error | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // const userString = localStorage.getItem("userKey");
  // const user = userString ? JSON.parse(userString) : null;
  const [user, setUser] = useLocalStorage<any>("userKey", null);
  const fetchOrderDetails = async () => {
    try {
      const response = await api.get(`${GET_ORDER_BY_ID}?orderId=${orderId}`);
      setOrder(response.data.data); // Axios directly gives you the typed data
    } catch (error) {
      setError("An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, []);
  const pay60Percent = async () => {
    setIsLoading(true);

    const response: ApiResponse<any> = await api.post(
      `${PAY_60_PERCENT}`,
      order?.id
    );
    if (response.statusCode == 200) {
      await fetchOrderDetails();
      setShowDeleteModal(false);
    }
    setIsLoading(false);
  };
  const pay40Percent = async () => {
    setIsLoading(true);

    const response: ApiResponse<any> = await api.post(
      `${PAY_40_PERCENT}`,
      order?.id
    );
    if (response.statusCode == 200) {
      await fetchOrderDetails();
      setShowDeleteModal(false);
    }
    setIsLoading(false);
  };
  return (
    <>
      <div>
        <Link
          to="/manager/orders"
          className="flex items-center gap-3 text-darkBlue-100"
        >
          <span>
            <FaCircleArrowLeft />
          </span>
          <span>Back</span>
        </Link>
        <div className="flex items center gap-3 pt-4 pb-1 border-b border-gray-200">
          <h1 className="text-gray-350">Orders</h1>/<span>Order Details</span>
        </div>
        {isLoading ? (
          <DetailsShimmerCard />
        ) : error ? (
          <ErrorLoading
            error={error instanceof Error ? error.message : String(error)}
          />
        ) : order ? (
          <>
            <div className="rounded-md bg-white-100 p-4 text-sm text-nowrap max-w-[100vw] overflow-x-auto">
              <div className="flex justify-between gap-3">
                <div>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Order ID</span>
                    <span>{order.orderId ? order.orderId : `N/A`}</span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Route From </span>
                    <span>{order.routeFrom ? order.routeFrom : `N/A`}</span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Price</span>
                    <span>{ `N/A`}</span>
                  </p>
                </div>
                <div>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Consignment</span>
                    <span>{order.consignment ? order.consignment : `N/A`}</span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Route To</span>
                    <span>{order.routeTo ? order.routeTo : `N/A`}</span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Customer</span>
                    <span>{order.customer ? order.customer : `N/A`}</span>
                  </p>
                </div>

                <div>
                  <p className="flex flex-col my-6 text-nowrap">
                    <span className="text-gray-350 mb-1">Quantity</span>
                    <span>{order.quantity ? order.quantity : `N/A`}</span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Start Date</span>
                    <span>
                      {order.startDate === undefined ? (
                        `N/A`
                      ) : (
                        <FormattedDate date={order?.startDate} />
                      )}
                    </span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Cargo Type</span>
                    <span>
                      {order.cargoType !== undefined && !isNaN(Number(order.cargoType))
                        ? TruckType[Number(order.cargoType)]
                        : 'N/A'}                      </span>
                  </p>
                </div>
                <div>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Truck No.</span>
                    <span>{order.truckNo ? order.truckNo : `N/A`}</span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">End Date</span>
                    <span>
                      {order.endDate === undefined ? (
                        `N/A`
                      ) : (
                        <FormattedDate date={order?.endDate} />
                      )}
                    </span>
                  </p>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">Driver</span>
                    <span>{order.driver ? order.driver : `N/A`}</span>
                  </p>
                </div>
                <div>
                  <p className="flex flex-col my-6">
                    <span className="text-gray-350 mb-1">
                      Owner Bank Account Name
                    </span>
                    <span>
                      {order.truckOwnerBankName
                        ? order.truckOwnerBankName
                        : `N/A`}
                    </span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">
                      Owner Bank Account Number
                    </span>
                    <span>
                      {order.truckOwnerBankAccountNumber
                        ? order.truckOwnerBankAccountNumber
                        : `N/A`}
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-[100%] lg:w-[30%] flex gap-5 items-center my-5 text-sm">
                {order.orderStatus === 0 && (
                  <button
                    onClick={() => setAssignOrder(true)}
                    className="bg-darkBlue-100 text-white-100 py-2 px-6 lg:px-2 lg:w-60 rounded-md hover:bg-gray-500"
                  >
                    Assign Truck
                  </button>
                )}
                {order.orderStatus === 1 && (
                  <button
                    onClick={() => {
                      setIsDelivery(false);
                      setShowUploadManifest(true);
                    }}
                    className="bg-darkBlue-100 text-white-100 py-2 px-6 lg:px-2 lg:w-60 rounded-md hover:bg-gray-500"
                  >
                    Upload Manifest
                  </button>
                )}
                {order.orderStatus === 3 && (
                  <button
                    onClick={() => {
                      setIsDelivery(true);
                      setShowUploadManifest(true);
                    }}
                    className="bg-darkBlue-100 text-white-100 py-2 px-6 lg:px-2 lg:w-60 rounded-md hover:bg-gray-500"
                  >
                    Upload Delivery Manifest
                  </button>
                )}
                <button className="bg-myred-400 text-white-100 py-2 px-4 lg:px-2 lg:w-60 rounded-md hover:bg-gray-500">
                  Flag Order
                </button>
              </div>
            </div>
            <div className={"sm:flex flex-row justify-between"}>
              {order.orderStatus >= 2 && (
                <div className={"flex flex-col"}>
                  <p className="mt-6 text-sm">Uploaded Documents</p>
                  <div>
                    {order!.documents!.length > 0 &&
                      order?.documents!.map((documentUrl) => (
                        <p
                          className="sm:px-4 py-1 bg-gray-100 rounded-md mt-2"
                          key={documentUrl}
                        >
                          <a
                            href={documentUrl}
                            target="_blank"
                            className="flex items-center text-sm sm:text-base justify-between gap-3"
                            rel="noopener noreferrer"
                          >
                            <div className="flex items-center gap-x-2 sm:gap-x-5 flex-row">
                              <span className="text-myred-100 ">
                                <GrDocumentPdf />
                              </span>
                              <span>{documentUrl.split("/").pop()}</span>
                            </div>
                            <p>View File</p>
                          </a>
                        </p>
                      ))}
                  </div>
                  <div className="text-sm mt-5">
                    {user!.role.includes("finance") ? (
                      order.is60Paid ? (
                        <p>60% Payment Completed</p>
                      ) : (
                        <button
                          onClick={() => pay60Percent()}
                          className="bg-darkBlue-100 text-white-100 py-2 px-4 rounded-md hover:bg-gray-500"
                        >
                          Pay 60%
                        </button>
                      )
                    ) : (
                      <>
                        {order.is60Paid ? (
                          ""
                        ) : (
                          <button className="bg-darkBlue-100 text-white-100 py-2 px-4 rounded-md hover:bg-gray-500">
                            Request 60%
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              {order.orderStatus > 3 && (
                <div className={"flex flex-col"}>
                  <p className="mt-6 text-sm">Delivery Documents</p>
                  <div>
                    {order!.deliveryDocuments !== null &&
                      order!.deliveryDocuments!.length > 0 &&
                      order?.deliveryDocuments!.map((documentUrl) => (
                        <p
                          className="sm:px-4 py-1 bg-gray-100 rounded-md mt-2"
                          key={documentUrl}
                        >
                          <a
                            href={documentUrl}
                            target="_blank"
                            className="flex items-center text-sm sm:text-base justify-between gap-3"
                            rel="noopener noreferrer"
                          >
                            <div className="flex items-center gap-x-2 sm:gap-x-5 flex-row">
                              <span className="text-myred-100">
                                <GrDocumentPdf />
                              </span>
                              <span>{documentUrl.split("/").pop()}</span>
                            </div>
                            <p>View File</p>
                          </a>
                        </p>
                      ))}
                  </div>
                  <div className="text-sm mt-5">
                    {user!.role.includes("finance") ? (
                      order.is40Paid ? (
                        <p>Payment Completed</p>
                      ) : (
                        <button
                          onClick={() => pay40Percent()}
                          className="bg-darkBlue-100 text-white-100 py-2 px-4 rounded-md hover:bg-gray-500"
                        >
                          Complete Payment
                        </button>
                      )
                    ) : (
                      <>
                        {order.is40Paid ? (
                          ""
                        ) : (
                          <button className="bg-darkBlue-100 text-white-100 py-2 px-4 rounded-md hover:bg-gray-500">
                            Send for Order Completion
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <p>Order not found.</p>
        )}
        <UploadOrderManifest
          isDelivered={isDelivery}
          showUploadManifest={showUploadManifest}
          setShowUploadManifest={setShowUploadManifest}
          orderId={order?.id}
          handleFetchOrderDetails={() => fetchOrderDetails()}
        />
        <UpdateOrderModal
          assignOrder={assignOrder}
          businessId={order?.businessId}
          orderId={order?.orderId}
          onClosed={() => {
            setAssignOrder(false);
            fetchOrderDetails();
          }}
          setAssignOrder={setAssignOrder}
        />
        <ConfirmationModal
          isVisible={showDeleteModal}
          onClosed={() => {
            setShowDeleteModal(false);
          }}
        >
          <div className="w-0 lg:w-[90%] text-sm">
            <p>
              Are you sure you want to{" "}
              <span className="text-red-500">Make Payment</span> for this Order?
            </p>
            <div className="flex justify-between items-center text-sm py-4 mt-6">
              <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
              <button
                className="bg-red-500 text-white-100 py-1 px-6 rounded-sm hover:bg-gray-500"
              // onClick={handleConfirmDelete}
              >
                Pay
              </button>
            </div>
          </div>
        </ConfirmationModal>
      </div>
    </>
  );
};
export default OrderDetails;
