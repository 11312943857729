"use client";

import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { GoSearch } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import api, { ApiResponse } from "../../../http/api/api_client";
import {
  ALLBUSINESS_ENDPOINT,
  CREATE_OFFICERS_ENDPOINT,
  DELETEOFFICER_ENDPOINT,
  EDIT_OFFICERS_ENDPOINT,
  GET_ALL_OFFICERS_ENDPOINT,
  SEARCH_OFFICERS,
} from "../../../helpers/constant";
import MainLayout from "../../../components/main-layout/MainLayout";
import TableShimmer from "../../../components/shimmer/TableShimmer";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { ResetUserPassword } from "../../../components/ResetUserPassword";

interface OfficerData {
  id?: any;
  officerName: string;
  phoneNumber: string;
  emailAddress: string;
  companyId: string;
  officerId: string;
  officerType: number;
}

interface Business {
  id: string;
  name: string;
  location: string;
}
const Officers = () => {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [fieldOfficerPerPage, setFieldOfficerPerPage] = useState<number>(10);
  const [itSafetyOffItemOffset, setItSafetyOffItemOffset] = useState<number>(0);
  const [safetyOfficerPerPage, setSafetyOfficerPerPage] = useState<number>(10);
  const [addOfficerModal, setAddOfficerModal] = useState<boolean>(false);
  const [toggleOfficer, setToggleOfficer] = useState<boolean>(true);
  const [editFiledOfficer, setEditFieldOfficer] = useState<boolean>(false);
  const [removeOfficer, setRemoveOfficer] = useState<boolean>(false);
  const [deletingOfficerId, setDeletingOfficerId] = useState<string | null>(
    null
  );
  const [business, setBusiness] = useState<Business[]>([]);
  const [fieldOfficerTableData, setFieldOfficerTableData] = useState<
    OfficerData[]
  >([]);
  const [safetyOfficerTableData, setSafetyOfficerTableData] = useState<
    OfficerData[]
  >([]);
  const [fieldOfficerToEdit, setFieldOfficerToEdit] = useState<OfficerData>();
  const [selectedOfficerType, setSelectedOfficerType] = useState<string | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useNavigate();
  const [isResetPasswordModalVisible, setResetPasswordModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const handleFieldOfficerPageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * fieldOfficerPerPage;
    setItemOffset(newOffset);
  };

  const handleFieldOffPersonPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newFieldOfficerPerPage = parseInt(e.target.value);
    setFieldOfficerPerPage(newFieldOfficerPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(
    fieldOfficerTableData.length / fieldOfficerPerPage
  );

  const currFieldOfficer = fieldOfficerTableData.slice(
    itemOffset,
    itemOffset + fieldOfficerPerPage
  );
  const fetchAllBusiness = async () => {
    try {
      const response: ApiResponse<any> = await api.get(ALLBUSINESS_ENDPOINT);
      if (response.statusCode === 200) {
        const data = response.data;
        setBusiness(data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };
  // ******--------Pagination functionality for Financial Officers------ *********
  const handleSafetyOfficerPageChange = ({
    selected,
  }: {
    selected: number;
  }) => {
    const newOffset = selected * safetyOfficerPerPage;
    setItSafetyOffItemOffset(newOffset);
  };

  const handleSafetyOffPersonPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSafetyOfficerPerPage = parseInt(e.target.value);
    setSafetyOfficerPerPage(newSafetyOfficerPerPage);
    setItSafetyOffItemOffset(0);
  };

  const safetyOfficerPageCount = Math.ceil(
    safetyOfficerTableData.length / safetyOfficerPerPage
  );

  const currSafetyOfficer = safetyOfficerTableData.slice(
    itSafetyOffItemOffset,
    itSafetyOffItemOffset + safetyOfficerPerPage
  );

  const handleSearchOfficer = async () => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${SEARCH_OFFICERS}?searchWords=${searchQuery}`
      );
      if (response.statusCode === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error("Error searching officer:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const fetchOfficersData = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(
        `${GET_ALL_OFFICERS_ENDPOINT}?page=${itemOffset}&&size=${fieldOfficerPerPage}`
      );
      if (response.statusCode !== 200) {
        console.log(response.data, "loading response data");
        throw new Error("Failed to fetch officer");
      } else {
        const data = response.data;
        const officer = data.data.data || [];
        console.log(officer, "loading officer");
        setFieldOfficerTableData(officer);
      }
    } catch (error) {
      console.error("Error fetching officer data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleAddOfficer = async (officer: {}, officerType: number) => {
    try {
      const response = await api.post(CREATE_OFFICERS_ENDPOINT, officer);
      if (response.data.statusCode === 201) {
        const responseData = response.data;
        if (responseData.isSuccessful) {
          fetchOfficersData();
          setAddOfficerModal(false);
          setIsLoading(false);
        } else {
          console.error("Failed to add officer:", responseData.responseMessage);
        }
      } else {
        console.error("Failed to add officer:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOfficerDetail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const officerName = formData.get("officerName") as string;
    const officerType = formData.get("officerType") === "Field Officer" ? 0 : 1;
    const phoneNumber = formData.get("phoneNumber") as string;
    const emailAddress = formData.get("emailAddress") as string;
    const companyId = formData.get("companyId") as string;

    handleAddOfficer(
      {
        officerName: officerName,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
        companyId: companyId,
        officerType: officerType,
      },
      officerType
    );
  };

  const handleEditFieldOfficer = (officer: OfficerData) => {
    setFieldOfficerToEdit(officer);
    setEditFieldOfficer(true);
    setSelectedOfficerType("Field Officer");
  };

  const handleEditSubmission = async (
    e: React.FormEvent<HTMLFormElement>,
    officerType: number
  ) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target as HTMLFormElement);
    const officerId = formData.get("officerId");
    const officerName = formData.get("officerName");
    const phoneNumber = formData.get("phoneNumber");
    const emailAddress = formData.get("emailAddress");

    const requestBody = {
      officerId: officerId,
      officerName: officerName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
    };
    const response: ApiResponse<any> = await api.post(
      EDIT_OFFICERS_ENDPOINT,
      requestBody
    );
    if (response.statusCode === 200) {
      await fetchOfficersData();
      setEditFieldOfficer(false);
      setIsLoading(false);
    } else {
      console.error("Failed to edit manager:", response.statusText);
      setIsLoading(false);
    }
  };

  const handleDeleteOfficer = async (officerId: string) => {
    setDeletingOfficerId(officerId);
    setRemoveOfficer(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingOfficerId) {
      const response: ApiResponse<any> = await api.post(
        `${DELETEOFFICER_ENDPOINT}?officerId=${deletingOfficerId}`
      );
      if (response.statusCode === 200) {
        setRemoveOfficer(false);
        setDeletingOfficerId("");
        setFieldOfficerTableData((prevData) =>
          prevData.filter((officer) => officer.id !== deletingOfficerId)
        );
      } else {
        console.error("Failed to delete manager:", response.statusText);
      }
    }
  };

  const businessOptions = business.map((bus) => ({
    value: bus.id,
    label: `${bus.name} - ${bus.location}`,
  }));

  useEffect(() => {
    fetchAllBusiness();
    if (searchQuery !== "") {
      handleSearchOfficer();
    }
    fetchOfficersData();
  }, [router, searchQuery]);

  // *********styling the select component**********
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "none" : "1px solid #CCCCCD",
      boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
      padding: "0.4rem 6px",
      borderRadious: "10",
    }),
  };

  return (
    <>
      {toggleOfficer ? (
        <>
          <div className="pb-1 border-b border-gray-300">
            <div className="flex justify-between items-center py-4">
              <div className="flex items-center flex-wrap gap-y-3">
                <h1>Officers</h1>
                <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
                  <input
                    type="text"
                    placeholder="Search Officer"
                    value={searchQuery}
                    onChange={handleChange}
                    className="outline-none"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchOfficer();
                      }
                    }}
                  />
                  <span
                    className="text-gray-500 cursor-pointer"
                    onClick={handleSearchOfficer}
                  >
                    <GoSearch className="w-[20px] h-[20px]" />
                  </span>
                </div>
              </div>
              <span className="font-bold">
                <button
                  className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
                  onClick={() => setAddOfficerModal(true)}
                >
                  <span>
                    <FaPlus className="w-10" />
                  </span>{" "}
                  <span>Add Officers</span>
                </button>
              </span>
            </div>
            <div className="flex items-center gap-5 text-xs">
              <h1
                className={`cursor-pointer font-medium ${toggleOfficer
                  ? "text-myred-100 border-b border-myred-100 pb-1"
                  : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                  }`}
                onClick={() => setToggleOfficer(true)}
              >
                FIELD OFFICER
              </h1>
              <h1
                className={`cursor-pointer font-medium ${!toggleOfficer
                  ? "text-myred-100 border-b border-myred-100 pb-1"
                  : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                  }`}
                onClick={() => setToggleOfficer(false)}
              >
                SAFETY OFFICER
              </h1>
            </div>
          </div>
          <div className="overflow-x-auto mt-6">
            <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
              <thead className="bg-white-100 text-gray-450 py-4 font-light">
                <tr className="border-b border-gray-200">
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Name
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Mobile Number
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Email Address
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="min-w-full divide-y">
                {isLoading ? (
                  <TableShimmer />
                ) : (
                  (searchResults.length > 0
                    ? searchResults
                    : currFieldOfficer
                  ).map((data) => (
                    <tr
                      key={data.id}
                      className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                    >
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/officers/${data.id}`)}
                      >
                        {data.officerName}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/officers/${data.id}`)}
                      >
                        {data.phoneNumber}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/officers/${data.id}`)}
                      >
                        {data.emailAddress}
                      </td>
                      <td className="flex items-center gap-x-4 py-4 px-2">
                        <span
                          className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 py-1 px-3 rounded-md"
                          onClick={() => {
                            setSelectedUserId(data.userId); // Set the user ID
                            setResetPasswordModal(true); // Show the modal
                          }}
                        >
                          Reset Password
                        </span>
                        <span
                          className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                          onClick={() => handleEditFieldOfficer(data)}
                        >
                          <FaRegEdit />
                        </span>
                        <span
                          className="text-red-500  cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                          onClick={() => handleDeleteOfficer(data.id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  ))
                )}
                {searchResults.length === 0 && searchQuery && (
                  <tr>
                    <td colSpan={4}>
                      <div className="flex justify-center items-center h-60">
                        <img
                          src="/nodata.png"
                          width={100}
                          height={100}
                          alt="No data"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleFieldOffPersonPageChange}
                  value={fieldOfficerPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Officer per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handleFieldOfficerPageChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="pb-1 border-b border-gray-300">
            <div className="flex justify-between items-center py-4">
              <h1>Officers</h1>
              <span className="font-bold">
                <button
                  className="text-white-100 bg-darkBlue-100 rounded-sm flex items-center py-3 pr-4 text-xs"
                  onClick={() => setAddOfficerModal(true)}
                >
                  <span>
                    <FaPlus className="w-10" />
                  </span>{" "}
                  <span>Add Officer</span>
                </button>
              </span>
            </div>
            <div className="flex items-center gap-5 text-xs">
              <h1
                className={`cursor-pointer font-medium ${toggleOfficer
                  ? "text-myred-100 border-b border-myred-100 pb-1"
                  : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                  }`}
                onClick={() => setToggleOfficer(true)}
              >
                FIELD OFFICER
              </h1>
              <h1
                className={`cursor-pointer font-medium ${!toggleOfficer
                  ? "text-myred-100 border-b border-myred-100 pb-1"
                  : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                  }`}
                onClick={() => setToggleOfficer(false)}
              >
                SAFETY OFFICER
              </h1>
            </div>
          </div>
          <div className="overflow-x-auto mt-6">
            <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
              <thead className="bg-white-100 text-gray-450 py-4 font-light">
                <tr className="border-b border-gray-200">
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Name
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Mobile Number
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Email Address
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="min-w-full divide-y">
                {safetyOfficerTableData.length === 0 ? (
                  <tr>
                    <td colSpan={9}>
                      <div className="flex justify-center items-center h-60">
                        <img
                          src="/nodata.png"
                          width={100}
                          height={100}
                          alt="no data"
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  currSafetyOfficer.map((data) => (
                    <tr
                      key={data.id}
                      className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                    >
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/officers/${data.id}`)}
                      >
                        {data.officerName}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/officers/${data.id}`)}
                      >
                        {data.phoneNumber}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/officers/${data.id}`)}
                      >
                        {data.emailAddress}
                      </td>
                      <td className="flex items-center py-4 gap-x-4 px-2">
                        <span
                          className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                        // onClick={() => handleEditSafetyOfficer(data)}
                        >
                          <FaRegEdit />
                        </span>
                        <span
                          className="text-red-500  cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                          onClick={() => setRemoveOfficer(true)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleSafetyOffPersonPageChange}
                  value={safetyOfficerPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Officer per page</span>
            </div>
            <Pagination
              pageCount={safetyOfficerPageCount}
              handlePageClick={handleSafetyOfficerPageChange}
            />
          </div>
        </>
      )}
      <ResetUserPassword
        isVisible={isResetPasswordModalVisible}
        setIsVisible={setResetPasswordModal}
        userId={selectedUserId!}
      />
      <Modal
        isVisible={addOfficerModal}
        onClosed={() => setAddOfficerModal(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Add New Officer</h1>
          <form onSubmit={handleOfficerDetail}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="officerName"
                placeholder="Officer Name"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="officerType"
                styles={customSelectStyles}
                options={[
                  {
                    value: "Field Officer",
                    label: "Field Officer",
                  },
                  { value: "Safety Officer", label: "Safety Officer" },
                ]}
                placeholder="Select Officer Type"
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <input
                type="text"
                name="phoneNumber"
                placeholder="Mobile Number"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="companyId"
                styles={customSelectStyles}
                options={businessOptions}
                placeholder="Select Company"
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />

              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${isLoading
                    ? "opacity-50"
                    : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                    }`}
                  type="submit"
                >
                  {isLoading ? "Loading..." : "Add Officer"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isVisible={editFiledOfficer}
        onClosed={() => setEditFieldOfficer(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Edit Officer</h1>
          <form onSubmit={(e) => handleEditSubmission(e, 0)}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="officerName"
                defaultValue={fieldOfficerToEdit?.officerName}
                placeholder=""
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                placeholder=""
                defaultValue={fieldOfficerToEdit?.id}
                name="officerId"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="officerType"
                styles={customSelectStyles}
                options={[
                  {
                    value: "Field Officer",
                    label: "Field Officer",
                  },
                ]}
                placeholder="Default Officer"
                defaultValue={{
                  value: selectedOfficerType || "",
                  label: selectedOfficerType || "",
                }}
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <input
                type="text"
                placeholder=""
                defaultValue={fieldOfficerToEdit?.phoneNumber}
                name="phoneNumber"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                placeholder=""
                defaultValue={fieldOfficerToEdit?.emailAddress}
                name="emailAddress"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <Select
                name="company"
                styles={customSelectStyles}
                options={[
                  { value: "Trucki Ltd", label: "Trucki Ltd" },
                  { value: "Beto Company", label: "Beto Company" },
                  { value: "Dangote Ltd", label: "Dangote Ltd" },
                ]}
                defaultValue={
                  selectedCompany
                    ? { value: selectedCompany, label: selectedCompany }
                    : undefined
                }
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${isLoading
                    ? "opacity-50"
                    : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                    }`}
                  type="submit"
                >
                  {isLoading ? "Updating..." : " Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        isVisible={removeOfficer}
        onClosed={() => setRemoveOfficer(false)}
      >
        <div className="w-[60%] mx-auto lg:w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Officer?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setRemoveOfficer(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleConfirmDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Officers;
