import { LOGIN_ENDPOINT } from "../helpers/constant";
import { setCredentials } from "../redux/Slices/authSlice";
import api from "./api/api_client";
import { AxiosRequestConfig } from "axios";

interface ApiRequestConfig extends AxiosRequestConfig {
  useToken?: boolean;
}

export async function authenticateUser(
  email: string,
  password: string,
  dispatch: any // Dispatch is passed from the component
): Promise<{ token: string | null; role: string[] | null; isPasswordChanged: boolean| null }> {
  try {
    console.log("Authenticating user...");
    console.log("Email:", email);
    console.log("Password:", password);

    const response = await api.post(
      LOGIN_ENDPOINT,
      {
        email,
        password,
      },
      { useToken: false } as ApiRequestConfig
    );

    console.log("Response:", response);

    if (response.data.isSuccessful) {
      const token = response.data.data.token;
      const user = response.data.data;
      console.log("Authentication successful. Token:", token);
      console.log(user.role);
      dispatch(setCredentials(user)); // Dispatch the credentials
      localStorage.setItem("tokenKey", token);
      localStorage.setItem("userKey", JSON.stringify(user));

      // Return both token and role
      return {
        token,
        role: user.role,
        isPasswordChanged: user.isPasswordChanged,
      };
    } else {
      console.error("Authentication failed. Status:", response.status);
      console.error("Response data:", response.data);

      return { token: null, role: null, isPasswordChanged: null };
    }
  } catch (error) {
    console.error("Error occurred during authentication:", error);
    return { token: null, role: null, isPasswordChanged: null };
  }
}

export function logout() {
  localStorage.removeItem("authToken");
}
