// import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Login from './Pages/Login/Login';
import MainLayout from './components/main-layout/MainLayout';
import Business from './Pages/dashboard/businesses/page';
import BusinessDetails from './Pages/dashboard/businesses/[businessId]/page';
import Managers from './Pages/dashboard/managers/page';
import ManagersDetails from './Pages/dashboard/managers/[managerId]/page';
import Drivers from './Pages/dashboard/drivers/page';
import DriverDetails from './Pages/dashboard/drivers/[driversId]/page';
import TruckOwners from './Pages/dashboard/truck-owners/page';
import TruckownerDetails from './Pages/dashboard/truck-owners/[truckownerId]/page';
import Trucks from './Pages/dashboard/trucks/page';
import TruckDetails from './Pages/dashboard/trucks/[truckId]/page';
import Dashboard from './Pages/dashboard/dashboard/page';
import Officers from './Pages/dashboard/officers/page';
import OfficersDetails from './Pages/dashboard/officers/[officerId]/page';
import Customers from './Pages/dashboard/customers/page';
import CustomerDetails from './Pages/dashboard/customers/[customerId]/page';
import Settings from './Pages/dashboard/settings/page';
import ManagerLayout from './components/mng-layout/ManagerLayout';
import ManagerDashboard from "./Pages/manager/dashboard/page";
import Orders from './Pages/manager/orders/page';
import OrderDetails from './Pages/manager/orders/[orderId]/page';
import Transactions from './Pages/manager/transactions/page';
import ManagersTrucks from "./Pages/manager/trucks/page";
import ManagersCustomers from "./Pages/manager/customers/page";
import ManagerCustomerDetails from './Pages/manager/customers/[customerId]/page';
import AuthRoute from './redux/AuthRoute';
import { Provider } from "react-redux";
import { persistor, store } from './redux/store';
import Reset from './Pages/Reset/Reset';
import { PersistGate } from 'redux-persist/integration/react';
import TermsAndConditions from './Pages/privacy';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Login />} />
      <Route path='/terms' element={<TermsAndConditions />} />
      <Route path="" element={<AuthRoute />}>
        <Route path="reset" element={<Reset />} />
        <Route path="dashboard" element={<MainLayout isLoading />}>
          <Route index element={<Dashboard />} />
          <Route path="business">
            <Route index element={<Business />} />
            <Route path=":businessId" element={<BusinessDetails />} />
          </Route>
          <Route path="managers">
            <Route index element={<Managers />} />
            <Route path=":managerId" element={<ManagersDetails />} />
          </Route>
          <Route path="drivers">
            <Route index element={<Drivers />} />
            <Route path=":driversId" element={<DriverDetails />} />
          </Route>
          <Route path="truck-owners">
            <Route index element={<TruckOwners />} />
            <Route path=":truckownerId" element={<TruckownerDetails />} />
          </Route>
          <Route path="trucks">
            <Route index element={<Trucks />} />
            <Route path=":truckId" element={<TruckDetails />} />
          </Route>
          <Route path="officers">
            <Route index element={<Officers />} />
            <Route path=":officerId" element={<OfficersDetails />} />
          </Route>
          <Route path="customers">
            <Route index element={<Customers />} />
            <Route path=":customerId" element={<CustomerDetails />} />
          </Route>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="manager" element={<ManagerLayout />}>
          <Route index element={<ManagerDashboard />} />
          <Route path="orders">
            <Route index element={<Orders />} />
            <Route path=":orderId" element={<OrderDetails />} />
          </Route>
          <Route path="transactions" element={<Transactions />} />
          <Route path="trucks" element={<ManagersTrucks />} />
          <Route path="customers">
            <Route index element={<ManagersCustomers />} />
            <Route path=":customerId" element={<ManagerCustomerDetails />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
}

export default App;
