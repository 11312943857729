import React, { useState } from "react";
import { CiMail } from "react-icons/ci";
import ChangePassword from "../change-password/ChangePassword";
import {useNavigate} from "react-router-dom";

interface ForgotPasswordProps {
  handleShowLogin: ()=> void
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ handleShowLogin }) => {
  const [email, setEmail] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(true);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const router = useNavigate()
  const toLoginPage = () => {
    router('/')
  }

  return (
    <>
      {showChangePassword ? (
        <div className="w-[100%]">
          <form className="mx-auto w-[90%] text-center">
            <div className="w-fit mx-auto">
              <img
                src="/forg-pass.png"
                width={300}
                height={300}
                alt="verify-logo"
              />
            </div>
            <h1 className="text-3xl font-medium mt-10 py-4">Forgot Password</h1>
            <p className="text-gray-450">
              We will send you a link to your email to reset your password
            </p>
            <div className="mt-10">
              <span className="absolute mt-5 ml-2 z-10 text-gray-450">
                <CiMail className="w-5 h-5" />
              </span>
              <input
                type="email"
                placeholder="Email address"
                onChange={handleEmailChange}
                value={email}
                className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
              />
            </div>
            <div className="mt-[10%]">
              <button
                type="button"
                className="bg-darkBlue-200 py-4 text-white-100 w-full rounded-md cursor-pointer"
                onClick={()=> setShowChangePassword(!showChangePassword)}
              >
                Send Reset Link
              </button>
            </div>
            <div className="pt-3 text-dark-105">
              <span>Remember your password?</span>{" "}
              <span
                className="text-red-400 cursor-pointer underline"
                onClick={handleShowLogin}
              >
                Log In
              </span>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <ChangePassword handlePasswordChange={toLoginPage} />
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
