import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import {
  APPROVE_TRUCK_OWNER,
  BLOCK_TRUCK_OWNER,
  GET_TRUCK_OWNER_BY_ID_ENDPOINT,
  REJECT_TRUCK_OWNER,
  UNBLOCK_TRUCK_OWNER,
} from "../../../../helpers/constant";
import api, { ApiResponse } from "../../../../http/api/api_client";
import { Link, useParams } from "react-router-dom";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import { TruckownerDetailModels } from "../../../../Types/Truckowners";
import ImageComponent from "../../../../components/imagePreview";
const TruckownerDetails = () => {
  const { truckownerId } = useParams();
  const [truckownerDetails, setTruckownerDetails] =
    useState<TruckownerDetailModels | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchAllOwnerDetails = async () => {
      setIsLoading(true);
      try {
        const response: ApiResponse<any> = await api.get(
          `${GET_TRUCK_OWNER_BY_ID_ENDPOINT}?ownerId=${truckownerId}`
        );
        if (response.statusCode === 200) {
          const data = response.data;
          console.log(data, "displaying the manager data");
          setTruckownerDetails(data.data);
        }
      } catch (error) {
        console.error("Error fetching truck owners data:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };
    fetchAllOwnerDetails();
  }, [truckownerId]);

  const fetchAllOwnerDetail = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(
        `${GET_TRUCK_OWNER_BY_ID_ENDPOINT}?ownerId=${truckownerId}`
      );
      if (response.statusCode === 200) {
        const data = response.data;
        console.log(data, "displaying the manager data");
        setTruckownerDetails(data.data);
      }
    } catch (error) {
      console.error("Error fetching truck owners data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  const handleApprove = async () => {
    const response: ApiResponse<any> = await api.post(
      `${APPROVE_TRUCK_OWNER}?ownerId=${truckownerId}`
    );
    if (response.statusCode === 200) {
      await fetchAllOwnerDetail();
    }
  };

  const handleNotApprove = async () => {
    const response: ApiResponse<any> = await api.post(
      `${REJECT_TRUCK_OWNER}?ownerId=${truckownerId}`
    );
    if (response.statusCode === 200) {
      await fetchAllOwnerDetail();
    }
  };

  const handleBlock = async () => {
    const response: ApiResponse<any> = await api.post(
      `${BLOCK_TRUCK_OWNER}?ownerId=${truckownerId}`
    );
    if (response.statusCode === 200) {
      await fetchAllOwnerDetail();
    }
  };

  const handleUnblock = async () => {
    const response: ApiResponse<any> = await api.post(
      `${UNBLOCK_TRUCK_OWNER}?ownerId=${truckownerId}`
    );
    if (response.statusCode === 200) {
      await fetchAllOwnerDetail();
    }
  };
  const getStatusStringByOwnersStatus = (status: number | undefined) => {
    if (status == 0) {
      return "Pending Approval";
    }
    if (status == 1) {
      return "Approved";
    }
    if (status == 2) {
      return "Not Approved";
    }
    if (status == 3) {
      return "Blocked";
    }
  }
  return (
    <div>
      <Link
        to="/dashboard/truck-owners/"
        className="flex items-center gap-3 text-darkBlue-100"
      >
        <span>
          <FaCircleArrowLeft />
        </span>
        <span>Back</span>
      </Link>
      <div className="flex flex-row justify-between">
        <div className="flex items center gap-3 pt-4 pb-1 border-b border-gray-200">
          <h1 className="text-gray-350">Truck Owners</h1>/
          <span>Truck Owerners Details</span>
        </div>
        <div className="sm:flex-col">
          <button
            className="text-sm text-white-100 mx-3 bg-yellow-600 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
            onClick={() => handleBlock()}
          >
            <span>Edit Bank Details</span>
          </button>
          {truckownerDetails?.ownersStatus === 1 && (
            <button
              className="text-sm text-white-100 bg-yellow-600 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
              onClick={() => handleBlock()}
            >
              <span>Block</span>
            </button>
          )}
          {truckownerDetails?.ownersStatus === 3 && (
            <button
              className="text-sm text-white-100 bg-blue-500 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
              onClick={() => handleUnblock()}
            >
              <span>Unblock</span>
            </button>
          )}

          {truckownerDetails?.ownersStatus === 0 && (
            <button
              className="text-sm text-white-100 bg-slate-800 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
              onClick={() => handleApprove()}
            >
              <span>Approve</span>
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <DetailsShimmerCard />
      ) : (
        <div className="rounded-lg px-4 py-6 bg-white-100 text-sm flex justify-between">
          <div className="flex flex-col">
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Name</span>
              <span>{truckownerDetails?.name}</span>
            </p>
            <p className="flex flex-col my-8">
              <span className="text-gray-350 pb-4">Address</span>
              <span>{truckownerDetails?.address}</span>
            </p>
            <p className="flex flex-col my-8">
              <span className="text-gray-350 pb-4">Status</span>
              <span>{getStatusStringByOwnersStatus(truckownerDetails?.ownersStatus)}</span>
            </p>
          </div>
          <div>
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Email Address</span>
              <span>{truckownerDetails?.emailAddress}</span>
            </p>
            {truckownerDetails?.bankDetails && (
              <p className="flex flex-col mt-8">
                <span className="text-gray-350 pb-4">Bank Name</span>
                <span>{truckownerDetails?.bankDetails.bankName}</span>
                <span>{truckownerDetails?.bankDetails.bankAccountName}</span>
                <span>{truckownerDetails?.bankDetails.bankAccountNumber}</span>
              </p>
            )}
            <div className="mt-6 w-[80%]">
              <p className="text-gray-350">Profile Profile</p>
              {truckownerDetails?.profilePictureUrl && (
                <ImageComponent
                  imageUrl={truckownerDetails?.profilePictureUrl}
                  altText={`${truckownerDetails.name} Profile Picture`}
                />
              )}
            </div>
          </div>
          <div className="space-y-5">
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Phone Number</span>
              <span>{truckownerDetails?.phone}</span>
            </p>
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4 mt-8">Number of Trucks</span>
              <span>{truckownerDetails?.noOfTrucks}</span>
            </p>
            <div className="mt-6 w-[80%]">
              <p className="text-gray-350">Uploaded ID</p>
              {truckownerDetails?.idCardUrl && (
                <ImageComponent
                  imageUrl={truckownerDetails?.idCardUrl}
                  altText={`${truckownerDetails.name} License`}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TruckownerDetails;
