import React, { useState } from "react";
import { VscCloudUpload } from "react-icons/vsc";
import { ADD_TRUCK_OWNER_ENDPOINT } from "../../../../helpers/constant";
import Modal from "../../../../components/modal/Modal";
import FormFile from "../../../../components/formfile/FormFile";
import api from "../../../../http/api/api_client";

interface AddTruckOwnerModalProps {
  isVisible: boolean;
  onClose: () => void;
  fetchTruckOwners: () => void;
}

const AddTruckOwnerModal: React.FC<AddTruckOwnerModalProps> = ({
  isVisible,
  onClose,
  fetchTruckOwners,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTruckOwnerIdCard, setSelectedTruckOwnerIdCard] = useState<String | null>(null);
  const [selectedTruckOwnerPassport, setSelectedTruckOwnerPassport] = useState<String | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleCreateTruckOwner = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null); // Clear any previous error messages

    const { name, emailAddress, phone, address, BankName, BankAccountNumber, BankAccountName } =
      e.currentTarget.elements as typeof e.currentTarget.elements & {
        name: HTMLInputElement;
        emailAddress: HTMLInputElement;
        phone: HTMLInputElement;
        address: HTMLInputElement;
        BankName: HTMLInputElement;
        BankAccountNumber: HTMLInputElement;
        BankAccountName: HTMLInputElement;
      };

    const requestBody = {
      idCard: selectedTruckOwnerIdCard,
      profilePicture: selectedTruckOwnerPassport,
      name: name.value,
      emailAddress: emailAddress.value,
      phone: phone.value,
      address: address.value,
      bankName: BankName.value,
      bankAccountNumber: BankAccountNumber.value,
      bankAccountName: BankAccountName.value,
    };

    try {
      const response = await api.post(ADD_TRUCK_OWNER_ENDPOINT, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.statusCode === 201) {
        fetchTruckOwners();
        onClose();
        setSelectedTruckOwnerIdCard(null);
        setSelectedTruckOwnerPassport(null);
      } else {
        setErrorMessage(response.data.message || "Something went wrong");
      }
    } catch (error: any) {
      setErrorMessage(
        error.response?.data?.message || "Something went wrong. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isVisible={isVisible} onClosed={onClose}>
      <div>
        <h1 className="mb-10 font-bold">Add New Truck Owner</h1>
        <form onSubmit={(e) => handleCreateTruckOwner(e)}>
          <div className="pb-4">
            <FormFile
            id="Upload Truck Owners id card"
              fileText="Upload Truck Owners id card (PNG, JPG or PDF)"
              fileText2="Drag & drop file or"
              browse="Browse"
              icon={<VscCloudUpload />}
              onUpload={(e: React.SetStateAction<String | null>) =>
                setSelectedTruckOwnerIdCard(e)
              }
            />
          </div>
          <div className="pb-4">
            <FormFile
            id="Upload Truck Owners Passport"
              fileText="Upload Truck Owners Passport (PNG, JPG or PDF)"
              fileText2="Drag & drop file or"
              browse="Browse"
              icon={<VscCloudUpload />}
              onUpload={(e: React.SetStateAction<String | null>) =>
                setSelectedTruckOwnerPassport(e)
              }
            />
          </div>
          <div className="flex flex-col justify-between gap-5">
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="emailAddress"
              placeholder="Email Address"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="BankName"
              placeholder="Bank Name"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="BankAccountNumber"
              placeholder="Account Number"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="BankAccountName"
              placeholder="Account Name"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            {errorMessage && (
              <div className="text-red-500 text-center mt-2">{errorMessage}</div>
            )}
            <div className="py-3 lg:py-5 text-center">
              <button
                className={`py-3 bg-darkBlue-100 w-[100%] rounded-md cursor-pointer text-white-100 ${isLoading ? "opacity-50" : ""
                  }`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Adding..." : "Add Truck Owner"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddTruckOwnerModal;
