"use client";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import Select from "react-select";
import api, { ApiResponse } from "../../../http/api/api_client";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../context/useLocalstorage";
import {
  ADD_ORDER_ENDPOINT,
  ALLBUSINESS_ENDPOINT,
  GET_ALL_CUSTOMERS_ENDPOINT,
  GET_ALL_OFFICERS_ENDPOINT,
  GET_ALL_ORDER,
  GET_ALL_TRUCKS,
  GET_ROUTES_BY_BUSINESSID,
  GETBUSINESSBYID_ENDPOINT,
  SEARCH_ORDER_ENDPOINT,
} from "../../../helpers/constant";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import UpdateOrderModal from "../../../components/update_order_modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { toast } from "react-toastify";
import DetailsShimmerCard from "../../../components/shimmer/DetailsShimmerCard";
import ErrorLoading from "../../../components/shimmer/ErrorLoading";
import FormattedDate2 from "../../../components/FormattedDate2";
import { OrdersData } from "../../../Types/Orders";
import AddressForm from "../../../components/AddressForm";
import OrderSearchFilter from "../../../components/OrderSearchFilter";

interface OfficerMap {
  id: string;
  officerName: string;
}
interface BusinessMap {
  id: string;
  name: string;
}

interface Truck {
  id: string;
  truckCapacity: string;
  plateNumber: string;
}

interface CustomerMap {
  id: string;
  customerName: string;
  phoneNumber: string;
}

interface RouteMap {
  id: string;
  name: string;
  price: string;
}
enum TruckType {
  Flatbed = 0,
  BoxBody = 1,
  BucketBody = 2,
  Lowbed = 3,
  ContainerizedBody = 4,
  Refrigerator = 5,
}
const Orders = () => {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [orderTableData, setOrderTableData] = useState<OrdersData[]>([]);
  const [editOrderId, setEditOrderId] = useState<string | undefined>(undefined);
  const [orderBusinessId, setOrderBusinessId] = useState<string | undefined>(
    undefined
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteOrderId, setDeleteOrderId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(false);
  const [selectedTruckNumber, setSelectedTruckNumber] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [business, setBusiness] = useState<BusinessMap[]>([]);
  const [officer, setOfficer] = useState<OfficerMap[]>([]);
  const [activeComponent, setActiveComponent] = useState<
    | "all"
    | "pending"
    | "transit"
    | "loaded"
    | "destination"
    | "delivered"
    | "flagged"
    | "archived"
  >("all");
  const [showAddOrder, setShowAddOrder] = useState<boolean>(false);
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const [assignOrder, setAssignOrder] = useState<boolean>(false);

  const [customers, setCustomers] = useState<CustomerMap[]>([]);
  const [routes, setRoutes] = useState<RouteMap[]>([]);
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [price, setPrice] = useState<string | null>(null);
  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: customer.customerName + " - " + customer.phoneNumber,
  }));

  const truckOptions = trucks.map((truck) => ({
    value: truck.id,
    label: truck.plateNumber + " - " + truck.truckCapacity,
  }));

  const routesOptions = routes.map((route) => ({
    value: route.id,
    label: route.name,
  }));

  const handleRouteChange = (selectedOption: any) => {
    if (selectedOption) {
      const selectedRoute = routes.find(
        (route) => route.id === selectedOption.value
      );
      if (selectedRoute) {
        setPrice(selectedRoute.price);
      }
    }
  };

  useEffect(() => {
    if (showAddOrder) {
      fetchAllCustomers();
      fetchAllTrucks();
    }
  }, [showAddOrder]);

  const handleClick = (
    component:
      | "all"
      | "pending"
      | "transit"
      | "loaded"
      | "destination"
      | "delivered"
      | "flagged"
      | "archived"
  ) => {
    setActiveComponent(component);
  };

  const router = useNavigate();

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * itemsPerPage;
    setItemOffset(newOffset);
  };
  // const userString = localStorage.getItem("userKey");
  // const user = userString ? JSON.parse(userString) : null;
  const [user, setUser] = useLocalStorage<any>("userKey", null);
  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(orderTableData.length / itemsPerPage);

  const currentItems = orderTableData.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  const getOrderStatusByIndex = (index: number | undefined) => {
    if (index === 0) {
      return "Pending";
    }
    if (index === 1) {
      return "Assigned";
    }
    if (index === 2) {
      return "Loaded";
    }
    if (index === 3) {
      return "InTransit";
    }
    if (index === 4) {
      return "Destination";
    }
    if (index === 5) {
      return "Delivered";
    }
  };
  const [error, setError] = useState<Error | null>(null);
  const fetchAllOrders = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(GET_ALL_ORDER);
      if (response.statusCode === 200) {
        const data = response.data;
        const sortedOrders = data.data.sort((a: OrdersData, b: OrdersData) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setOrderTableData(sortedOrders);
        setIsLoading(false);
        console.log(sortedOrders, "displaying order data");
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      setIsLoading(false);
    }
  };
  const fetchAllBusiness = async () => {
    try {
      const response: ApiResponse<any> = await api.get(ALLBUSINESS_ENDPOINT);
      if (response.statusCode === 200) {
        const data = response.data;
        setBusiness(data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };
  const fetchAllOfficer = async () => {
    try {
      const response: ApiResponse<any> = await api.get(
        GET_ALL_OFFICERS_ENDPOINT
      );
      if (response.statusCode === 200) {
        const data = response.data.data;
        setOfficer(data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };

  const fetchAllTrucks = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(GET_ALL_TRUCKS);
      if (response.statusCode == 200) {
        const data = response.data;
        setTrucks(data.data);
        console.log(data.data, "displaying truck data");
      }
    } catch (error) {
      console.error("Error fetching customers data:", error);
    }
  };

  const fetchAllCustomers = async () => {
    // setIsLoading(true)
    try {
      const response: ApiResponse<any> = await api.get(
        GET_ALL_CUSTOMERS_ENDPOINT
      );
      if (response.statusCode === 200) {
        const data = response.data.data;
        setCustomers(data);
        console.log(data.data, "displaying customers data");
      }
    } catch (error) {
      console.error("Error fetching customers data:", error);
    }
  };

  const GetRoutesByBusinessId = async (id: string) => {
    // setIsLoading(true)
    try {
      const response: ApiResponse<any> = await api.get(
        `${GET_ROUTES_BY_BUSINESSID}?businessId=${id}`
      );
      if (response.statusCode === 200) {
        const data = response.data;
        setRoutes(data.data);
        console.log(data.data, "displaying Routes data");
      }
    } catch (error) {
      console.error("Error fetching Routes data:", error);
    }
  };

  const handleTruckChange = (selectedOption: any) => {
    setSelectedTruckNumber(selectedOption.label);
  };

  const fetchRouteForBusiness = async (businessId: string) => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${GETBUSINESSBYID_ENDPOINT}?businessId=${businessId}`
      );

      if (response.statusCode === 200) {
        const routeData = response.data;

        setBusiness([routeData]);
      } else {
        console.error("Error fetching route data:", response);
      }
    } catch (error) {
      console.error("Error fetching route data:", error);
    }
  };

  const businessOptions = business.map((bus) => ({
    value: bus.id,
    label: bus.name,
  }));
  const officerOptions = officer.map((bus) => ({
    value: bus.id,
    label: bus.officerName,
  }));

  const handleBusinessChange = (selectedOption: any) => {
    if (selectedOption) {
      setSelectedBusiness(selectedOption.value as string);
    }
  };
  const handleAssignOrder = (
    orderId: string | undefined,
    businessId: string | undefined
  ) => {
    {
      setEditOrderId(orderId);
      console.log(editOrderId);
      setOrderBusinessId(businessId);
      console.log(orderBusinessId);
      setAssignOrder(true);
    }
  };
  useEffect(() => {
    fetchAllOrders();
    fetchAllBusiness();
    fetchAllOfficer();
    if (selectedBusiness) {
      fetchRouteForBusiness(selectedBusiness);
    }
  }, [selectedBusiness]);

  // *********styling the select component**********
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "none" : "1px solid #CCCCCD",
      boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
      padding: "0.4rem 10px",
      borderRadious: "10",
    }),
  };
  const [deliveryLocationLat, setDeliveryLocationLat] = useState<number | null>(null);
  const [deliveryLocationLong, setDeliveryLocationLong] = useState<number | null>(null);
  const [deliveryAddress, setDeliveryAddress] = useState<string | null>(null);

  const handleAddressSelect = (lat: number, lng: number, address: string) => {
    setDeliveryLocationLat(lat);
    setDeliveryLocationLong(lng);
    setDeliveryAddress(address);
  };

  const handleCreateOrder = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoadingOrder(true);
    const formData = new FormData(e.target as HTMLFormElement);
    const id = formData.get("id");
    const Consignment = formData.get("Consignment");
    const quantity = formData.get("quantity");
    const CompanyId = formData.get("CompanyId");
    const customerId = formData.get("customerId");
    const CargoType = formData.get("CargoType");
    const routeId = formData.get("routeId");
    const startDate = formData.get("startDate");

    const requestBody = {
      id,
      Consignment,
      quantity,
      CompanyId,
      customerId,
      CargoType,
      routeId,
      deliveryAddress,
      startDate,
      deliveryLocationLat: deliveryLocationLat ? String(deliveryLocationLat) : "",
      deliveryLocationLong: deliveryLocationLong ? String(deliveryLocationLong) : "",
    };

    const response: ApiResponse<any> = await api.post(
      ADD_ORDER_ENDPOINT,
      requestBody
    );
    if (response.statusCode === 200) {
      await fetchAllOrders();
      setShowAddOrder(false);
      toast("Order Created Successfully");
    }
    setIsLoadingOrder(false);
  };

  const handleSearch = async (searchParams: any) => {
    // Perform API request here with searchParams as the request body
    setIsLoading(true);
    const requestBody = searchParams;
    try {
      const response: ApiResponse<any> = await api.post(
        SEARCH_ORDER_ENDPOINT,
        requestBody
      );

      if (response.statusCode === 200) {
        const data = response.data;
        const sortedOrders = data.data.sort((a: OrdersData, b: OrdersData) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setOrderTableData(sortedOrders);
        setIsLoading(false);
        console.log(sortedOrders, "displaying order data");
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-start mb-10 py-2 border-b border-gray-300">
        <h1>Orders</h1>
        {user!.role.includes("finance") ? (
          <></>
        ) : (
          <span className="font-bold">
            <button
              className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
              onClick={() => setShowAddOrder(true)}
            >
              <span>
                <FaPlus className="w-10" />
              </span>
              <span>Add New Orders</span>
            </button>
          </span>
        )}
      </div>
      <div className="md:w-[60%] lg:w-[30%] relative  md:ml-[20%] lg:ml-0">
        <OrderSearchFilter onSearch={handleSearch} />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 text-sm my-4 max-w-[100vw] overflow-x-auto text-nowrap">
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "all"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:bg-gray-200 hover:text-darkBlue-100"
              }`}
            onClick={() => handleClick("all")}
            onDoubleClick={() => fetchAllOrders()}
          >
            All
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "pending"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("pending")}
          >
            Pending
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "transit"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("transit")}
          >
            In Transit
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "loaded"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("loaded")}
          >
            Loaded
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "destination"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("destination")}
          >
            Destination
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "delivered"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("delivered")}
          >
            Delivered
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "flagged"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("flagged")}
          >
            Flagged
          </button>
          <button
            className={`px-2 py-2 rounded-md bg-white-100 text-xs w-20 ${activeComponent === "archived"
              ? "border border-darkBlue-100 bg-[#3704680A]/10 text-darkBlue-100"
              : "hover:border hover:border-darkBlue-100 hover:text-darkBlue-100 hover:bg-[#3704680A]"
              }`}
            onClick={() => handleClick("archived")}
          >
            Archived
          </button>
        </div>
      </div>
      {activeComponent === "all" && (
        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <DetailsShimmerCard />
            ) : error ? (
              <ErrorLoading
                error={error instanceof Error ? error.message : String(error)}
              />
            ) : (
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left max-w-[100vw] divide-y text-nowrap">
                <thead className="bg-white-100 py-4 text-nowrap">
                  <tr className="bg-gray-200 text-nowrap">
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Order No.
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Business
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Qty
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Consignment
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Truck No.
                    </th>

                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Route
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Start Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      End Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Status
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody className="min-w-full divide-y">
                  {orderTableData.length === 0 ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="no data"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems.map((data) => (
                      <tr
                        key={data.id}
                        className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                      >
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.orderId}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.business?.name}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.quantity}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.consignment}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.truckNo}
                        </td>

                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.routes ? data.routes.name : "nil"}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.startDate === undefined ? (
                            `N/A`
                          ) : (
                            <FormattedDate2 date={data?.startDate} />
                          )}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {data.endDate === undefined ? (
                            `N/A`
                          ) : (
                            <FormattedDate2 date={data?.endDate} />
                          )}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/manager/orders/${data.id}`)}
                        >
                          {getOrderStatusByIndex(data.orderStatus)}
                        </td>
                        <td className="flex items-center py-4 px-2">
                          <div className="flex items-center ml-auto text-sm sm:text-base md:text-lg">
                            {data.orderStatus === 0 && (
                              <span
                                className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                onClick={() =>
                                  handleAssignOrder(
                                    data.orderId,
                                    data.business?.id
                                  )
                                }
                              >
                                <span>
                                  <MdAddCircleOutline />
                                </span>
                                <span className="hidden lg:block">
                                  Assign Truck
                                </span>
                              </span>
                            )}
                            {data.orderStatus === 1 ||
                              (data.orderStatus === 2 && (
                                <span
                                  className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                  onClick={() =>
                                    handleAssignOrder(
                                      data.orderId,
                                      data.business?.id
                                    )
                                  }
                                >
                                  <span>
                                    <MdAddCircleOutline />
                                  </span>
                                  <span>Reassign Truck</span>
                                </span>
                              ))}
                            <span
                              className="text-darkBlue-100 cursor-pointer mx-3 hover:bg-gray-300 p-3 rounded-md"
                            // onClick={() => handleEditClick(data.id)}
                            >
                              <FaRegEdit />
                            </span>
                            <span
                              className="text-red-500 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                            // onClick={() => handleDeleteOrder(data.id)}
                            >
                              <RiDeleteBin6Line />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="py-8 mb-[-40px] sm:flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Orders per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </>
      )}
      {activeComponent === "pending" && (
        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <DetailsShimmerCard />
            ) : error ? (
              <ErrorLoading
                error={error instanceof Error ? error.message : String(error)}
              />
            ) : (
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
                <thead className="bg-white-100 py-4">
                  <tr className="bg-gray-200">
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Order No.
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Business
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Qty
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Consignment
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Truck No.
                    </th>

                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Route
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Start Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      End Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Status
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-full divide-y">
                  {orderTableData.length === 0 ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="no data"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems
                      .filter((data) => data.orderStatus === 0)
                      .map((data) => (
                        <tr
                          key={data.id}
                          className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                        >
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.orderId}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.business?.name}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.quantity}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.consignment}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.truckNo}
                          </td>

                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.routes ? data.routes.name : "nil"}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.startDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.startDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.endDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.endDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {getOrderStatusByIndex(data.orderStatus)}
                          </td>
                          <td className="flex items-center py-4 px-2">
                            <div className="flex items-center ml-auto">
                              {data.orderStatus === 0 && (
                                <span
                                  className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                  onClick={() =>
                                    handleAssignOrder(
                                      data.orderId,
                                      data.business?.id
                                    )
                                  }
                                >
                                  <span>
                                    <MdAddCircleOutline />
                                  </span>
                                  <span>Assign Truck</span>
                                </span>
                              )}
                              {data.orderStatus === 1 ||
                                (data.orderStatus === 2 && (
                                  <span
                                    className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                    onClick={() =>
                                      handleAssignOrder(
                                        data.orderId,
                                        data.business?.id
                                      )
                                    }
                                  >
                                    <span>
                                      <MdAddCircleOutline />
                                    </span>
                                    <span>Reassign Truck</span>
                                  </span>
                                ))}
                              <span
                                className="text-darkBlue-100 cursor-pointer mx-3 hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleEditClick(data.id)}
                              >
                                <FaRegEdit />
                              </span>
                              <span
                                className="text-red-500 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleDeleteOrder(data.id)}
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="py-8 mb-[-40px] sm:flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Orders per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </>
      )}
      {activeComponent === "transit" && (
        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <DetailsShimmerCard />
            ) : error ? (
              <ErrorLoading
                error={error instanceof Error ? error.message : String(error)}
              />
            ) : (
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
                <thead className="bg-white-100 py-4">
                  <tr className="bg-gray-200">
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Order No.
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Business
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Qty
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Consignment
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Truck No.
                    </th>

                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Route
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Start Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      End Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Status
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-full divide-y">
                  {orderTableData.length === 0 ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="no data"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems
                      .filter((data) => data.orderStatus === 3)
                      .map((data) => (
                        <tr
                          key={data.id}
                          className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                        >
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.orderId}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.business?.name}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.quantity}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.consignment}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.truckNo}
                          </td>

                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.routes ? data.routes.name : "nil"}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.startDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.startDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.endDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.endDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {getOrderStatusByIndex(data.orderStatus)}
                          </td>
                          <td className="flex items-center py-4 px-2">
                            <div className="flex items-center ml-auto">
                              {data.orderStatus === 0 && (
                                <span
                                  className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                  onClick={() =>
                                    handleAssignOrder(
                                      data.orderId,
                                      data.business?.id
                                    )
                                  }
                                >
                                  <span>
                                    <MdAddCircleOutline />
                                  </span>
                                </span>
                              )}
                              {data.orderStatus === 1 ||
                                (data.orderStatus === 3 && (
                                  <span
                                    className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                    onClick={() =>
                                      handleAssignOrder(
                                        data.orderId,
                                        data.business?.id
                                      )
                                    }
                                  >
                                    <span>
                                      <MdAddCircleOutline />
                                    </span>
                                  </span>
                                ))}
                              <span
                                className="text-darkBlue-100 cursor-pointer mx-3 hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleEditClick(data.id)}
                              >
                                <FaRegEdit />
                              </span>
                              <span
                                className="text-red-500 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleDeleteOrder(data.id)}
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="py-8 mb-[-40px] sm:flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Orders per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </>
      )}
      {activeComponent === "loaded" && (
        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <DetailsShimmerCard />
            ) : error ? (
              <ErrorLoading
                error={error instanceof Error ? error.message : String(error)}
              />
            ) : (
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
                <thead className="bg-white-100 py-4">
                  <tr className="bg-gray-200">
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Order No.
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Business
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Qty
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Consignment
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Truck No.
                    </th>

                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Route
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Start Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      End Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Status
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-full divide-y">
                  {orderTableData.length === 0 ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="no data"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems
                      .filter((data) => data.orderStatus === 2)
                      .map((data) => (
                        <tr
                          key={data.id}
                          className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                        >
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.orderId}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.business?.name}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.quantity}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.consignment}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.truckNo}
                          </td>

                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.routes ? data.routes.name : "nil"}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.startDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.startDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.endDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.endDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {getOrderStatusByIndex(data.orderStatus)}
                          </td>
                          <td className="flex items-center py-4 px-2">
                            <div className="flex items-center ml-auto">
                              {data.orderStatus === 0 && (
                                <span
                                  className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                  onClick={() =>
                                    handleAssignOrder(
                                      data.orderId,
                                      data.business?.id
                                    )
                                  }
                                >
                                  <span>
                                    <MdAddCircleOutline />
                                  </span>
                                  <span>Assign Truck</span>
                                </span>
                              )}
                              {data.orderStatus === 1 ||
                                (data.orderStatus === 2 && (
                                  <span
                                    className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"
                                    onClick={() =>
                                      handleAssignOrder(
                                        data.orderId,
                                        data.business?.id
                                      )
                                    }
                                  >
                                    <span>
                                      <MdAddCircleOutline />
                                    </span>
                                    <span>Reassign Truck</span>
                                  </span>
                                ))}
                              <span
                                className="text-darkBlue-100 cursor-pointer mx-3 hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleEditClick(data.id)}
                              >
                                <FaRegEdit />
                              </span>
                              <span
                                className="text-red-500 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleDeleteOrder(data.id)}
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="py-8 mb-[-40px] sm:flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Orders per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </>
      )}
      {activeComponent === "destination" && (
        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <DetailsShimmerCard />
            ) : error ? (
              <ErrorLoading
                error={error instanceof Error ? error.message : String(error)}
              />
            ) : (
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
                <thead className="bg-white-100 py-4">
                  <tr className="bg-gray-200">
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Order No.
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Business
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Qty
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Consignment
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Truck No.
                    </th>

                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Route
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Start Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      End Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Status
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-full divide-y">
                  {orderTableData.length === 0 ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="no data"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems
                      .filter((data) => data.orderStatus === 4)
                      .map((data) => (
                        <tr
                          key={data.id}
                          className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                        >
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.orderId}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.business?.name}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.quantity}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.consignment}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.truckNo}
                          </td>

                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.routes ? data.routes.name : "nil"}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.startDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.startDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.endDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.endDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {getOrderStatusByIndex(data.orderStatus)}
                          </td>
                          <td className="flex items-center py-4 px-2">
                            <div className="flex items-center ml-auto">
                              {/*    {data.orderStatus === 0 && <span*/}
                              {/*        className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"*/}
                              {/*        onClick={() => handleAssignOrder(data.orderId, data.business?.id)}*/}
                              {/*    >*/}
                              {/*  <span>*/}
                              {/*    <MdAddCircleOutline/>*/}
                              {/*  </span>*/}
                              {/*  <span>Assign Truck</span>*/}
                              {/*</span>}*/}
                              {/*    {data.orderStatus === 1 ||data.orderStatus === 2   && <span*/}
                              {/*        className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"*/}
                              {/*        onClick={() => handleAssignOrder(data.orderId, data.business?.id)}*/}
                              {/*    >*/}
                              {/*  <span>*/}
                              {/*    <MdAddCircleOutline/>*/}
                              {/*  </span>*/}
                              {/*  <span>Reassign Truck</span>*/}
                              {/*</span>}*/}
                              <span
                                className="text-darkBlue-100 cursor-pointer mx-3 hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleEditClick(data.id)}
                              >
                                <FaRegEdit />
                              </span>
                              <span
                                className="text-red-500 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleDeleteOrder(data.id)}
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="py-8 mb-[-40px] sm:flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Orders per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </>
      )}{" "}
      {activeComponent === "delivered" && (
        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <DetailsShimmerCard />
            ) : error ? (
              <ErrorLoading
                error={error instanceof Error ? error.message : String(error)}
              />
            ) : (
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
                <thead className="bg-white-100 py-4">
                  <tr className="bg-gray-200">
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Order No.
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Business
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Qty
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Consignment
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Truck No.
                    </th>

                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Route
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Start Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      End Date
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                      Status
                    </th>
                    <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-full divide-y">
                  {orderTableData.length === 0 ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="no data"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems
                      .filter((data) => data.orderStatus === 5)
                      .map((data) => (
                        <tr
                          key={data.id}
                          className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                        >
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.orderId}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.business?.name}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.quantity}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.consignment}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.truckNo}
                          </td>

                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.routes ? data.routes.name : "nil"}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.startDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.startDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {data.endDate === undefined ? (
                              `N/A`
                            ) : (
                              <FormattedDate2 date={data?.endDate} />
                            )}
                          </td>
                          <td
                            className="py-4 px-2 whitespace-nowrap"
                            onClick={() => router(`/manager/orders/${data.id}`)}
                          >
                            {getOrderStatusByIndex(data.orderStatus)}
                          </td>
                          <td className="flex items-center py-4 px-2">
                            <div className="flex items-center ml-auto">
                              {/*    {data.orderStatus === 0 && <span*/}
                              {/*        className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"*/}
                              {/*        onClick={() => handleAssignOrder(data.orderId, data.business?.id)}*/}
                              {/*    >*/}
                              {/*  <span>*/}
                              {/*    <MdAddCircleOutline/>*/}
                              {/*  </span>*/}
                              {/*  <span>Assign Truck</span>*/}
                              {/*</span>}*/}
                              {/*    {data.orderStatus === 1 ||data.orderStatus === 2   && <span*/}
                              {/*        className="flex gap-1 items-center text-mygreen-100 text-sm hover:bg-gray-300 p-3 rounded-md"*/}
                              {/*        onClick={() => handleAssignOrder(data.orderId, data.business?.id)}*/}
                              {/*    >*/}
                              {/*  <span>*/}
                              {/*    <MdAddCircleOutline/>*/}
                              {/*  </span>*/}
                              {/*  <span>Reassign Truck</span>*/}
                              {/*</span>}*/}
                              <span
                                className="text-darkBlue-100 cursor-pointer mx-3 hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleEditClick(data.id)}
                              >
                                <FaRegEdit />
                              </span>
                              <span
                                className="text-red-500 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                              // onClick={() => handleDeleteOrder(data.id)}
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="py-8 mb-[-40px] space-y-2 sm:flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Orders per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </>
      )}
      <Modal isVisible={showAddOrder} onClosed={() => setShowAddOrder(false)}>
        <div>
          <h1 className="font-black mb-4">New Order</h1>
          <form onSubmit={handleCreateOrder}>
            <div className="flex flex-col gap-3">
              <div>
                <label className="text-gray-400 text-sm mb-2">
                  Consignment
                </label>
                <input
                  type="text"
                  name="Consignment"
                  placeholder=""
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>
              <div>
                <label className="text-gray-400 text-sm mb-2">Quantity</label>
                <input
                  type="text"
                  name="quantity"
                  placeholder=""
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>

              <div>
                <label className="text-gray-400 text-sm">Company</label>
                <Select
                  name="CompanyId"
                  styles={customSelectStyles}
                  options={businessOptions}
                  placeholder="Select Company"
                  onChange={(e) =>
                    GetRoutesByBusinessId(`${e?.value}`)

                  }
                  className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
                />
              </div>

              <div>
                <label className="text-gray-400 text-sm">Customer</label>
                <Select
                  name="customerId"
                  styles={customSelectStyles}
                  options={customerOptions}
                  placeholder="Select Customer"
                  className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
                />
              </div>

              <div>
                <label className="text-gray-400 text-sm">Truck Type</label>
                <Select
                  styles={customSelectStyles}
                  name={"CargoType"}
                  // onChange={handleSelectChange}
                  options={[
                    { value: TruckType.Flatbed, label: "Flatbed" },
                    { value: TruckType.BoxBody, label: "Box Body" },
                    { value: TruckType.BucketBody, label: "Bucket Body" },
                    { value: TruckType.Lowbed, label: "Lowbed" },
                    {
                      value: TruckType.ContainerizedBody,
                      label: "Containerized Body",
                    },
                    { value: TruckType.Refrigerator, label: "Refrigerator" },
                  ]}
                  placeholder="Select Truck Type"
                />
              </div>

              <AddressForm onAddressSelect={handleAddressSelect} AddressLabel="Delivery Address:" clearLocationAddress={false} />
              <div>
                <label className="text-gray-400 text-sm">Route</label>
                <Select
                  name="routeId"
                  styles={customSelectStyles}
                  options={routesOptions}
                  onChange={handleRouteChange}
                  placeholder="Select Route"
                  className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
                />
              </div>

              <div className="relative">
                <input
                  type="hidden"
                  name="price"
                  value={price ?? undefined}
                  readOnly={true}
                  placeholder=""
                  className="pl-8 py-3 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>

              <div>
                <label className="text-gray-400 text-sm mb-2">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  placeholder=""
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>

              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100`}
                  type="submit"
                >
                  {isLoadingOrder ? "Loading..." : "Submit Order"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <UpdateOrderModal
        assignOrder={assignOrder}
        businessId={orderBusinessId}
        orderId={editOrderId}
        onClosed={() => {
          setEditOrderId(undefined);
          setOrderBusinessId(undefined);
          fetchAllOrders();
        }}
        setAssignOrder={setAssignOrder}
      />
      <Modal
        isVisible={editOrder}
        onClosed={() => {
          setEditOrder(false);
          setDeleteOrderId(null);
        }}
      >
        <div>
          <h1 className="font-black">Edit Order</h1>
          <form>
            <div className="flex flex-col gap-3">
              <div>
                <label htmlFor="">Truck No.</label>
                <input
                  type="text"
                  name="truckNo"
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>
              <div>
                <label htmlFor="">Quantity</label>
                <input
                  type="text"
                  name="qty"
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>
              <div>
                <label htmlFor="">Route From</label>
                <input
                  type="text"
                  name="routeFrom"
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>
              <div>
                <label htmlFor="">Route To</label>
                <input
                  type="text"
                  name="routeTo"
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>
              <div className="flex justify-between gap-1">
                <div className="w-[50%]">
                  <label htmlFor="">Start Date</label>
                  <input
                    type="date"
                    name="startDate"
                    className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                  />
                </div>
                <div className="w-[50%]">
                  <label htmlFor="">Start Time</label>
                  <input
                    type="text"
                    name="startTime"
                    className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-1">
                <div className="w-[50%]">
                  <label htmlFor="">End Date</label>
                  <input
                    type="date"
                    name="endDate"
                    className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                  />
                </div>
                <div className="w-[50%]">
                  <label htmlFor="">End Time</label>
                  <input
                    type="text"
                    name="endTime"
                    className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="">Status</label>
                <input
                  type="text"
                  name="status"
                  className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                />
              </div>

              <div className="py-3 lg:py-5 text-center">
                <button
                  className="text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100"
                  type="submit"
                >
                  Submit Order
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        isVisible={showDeleteModal}
        onClosed={() => {
          setShowDeleteModal(false);
          setDeleteOrderId(null);
        }}
      >
        <div className="w-0 lg:w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Order?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
            // onClick={handleConfirmDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Orders;
function debounce(arg0: (value: string) => Promise<void>, arg1: number) {
  throw new Error("Function not implemented.");
}
