import React from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  { name: "Jan", rate: 18000 },
  { name: "Feb", rate: 50000 },
  { name: "Mar", rate: 150000 },
  { name: "Apr", rate: 100000 },
  { name: "May", rate: 110000 },
  { name: "Jun", rate: 100000 },
  { name: "Jul", rate: 200000 },
  { name: "Aug", rate: 100000 },
  { name: "Sep", rate: 180000 },
  { name: "Oct", rate: 130000 },
  { name: "Nov", rate: 155000 },
  { name: "Dec", rate: 150000 },
];

const DrivingChart = () => {
  return (
    <div className="h-fit flex justify-start mt-[5%] lg:mt-[15%] text-darkBlue-100 text-xs">
      <BarChart width={500} height={300} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar dataKey="rate" fill="currentColor" radius={5} />
      </BarChart>
    </div>
  );
};

export default DrivingChart;
