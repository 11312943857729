import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { GET_ALL_DRIVERS_BYID } from "../../../../helpers/constant";
import MainLayout from "../../../../components/main-layout/MainLayout";
import api, { ApiResponse } from "../../../../http/api/api_client";
import { AllDriverDetails as DriverDetail, DriverDetailsResponseModel } from "../../../../Types/Drivers";
import { Link, useParams } from "react-router-dom";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import { TruckiTypeNames } from "../../../../Types/Trucks";
import ImageComponent from "../../../../components/imagePreview";

const DriverDetails = () => {
  const { driversId } = useParams();
  const [driverDetails, setDriverDetails] = useState<DriverDetailsResponseModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchDriversDetail = async () => {
      setIsLoading(true);
      try {
        const response: ApiResponse<any> = await api.get(`${GET_ALL_DRIVERS_BYID}?id=${driversId}`);
        if (response.statusCode === 200) {
          const data = response.data;
          console.log(data.data, 'displaying the driver"s data');
          setDriverDetails(data.data);
        }
      } catch (error) {
        console.error("Error fetching drivers data:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };
    fetchDriversDetail();
  }, [driversId]);

  return (
        <div>
          <Link to="/dashboard/drivers/" className="flex items-center gap-3 text-darkBlue-100">
          <span>
            <FaCircleArrowLeft />
          </span>
            <span>Back</span>
          </Link>
          <div className="flex items-center gap-3 pt-4 pb-1 border-b border-gray-200">
            <h1 className="text-gray-350">Drivers</h1>/<span>Driver Details</span>
          </div>
          {isLoading ? (
              <DetailsShimmerCard />
          ) : driverDetails ? (
              <div className="flex justify-between text-sm bg-white-100 mt-5 p-4 rounded-md">
                <div className="flex flex-col gap-5">
                  <p className="flex flex-col">
                    <span className="text-gray-350 lg:mb-2">Name</span>
                    <span>{driverDetails.name}</span>
                  </p>
                  {/*<p className="flex flex-col">*/}
                  {/*  <span className="text-gray-350 lg:mb-2">Truck Company</span>*/}
                  {/*  <span>Trucki Ltd.</span>*/}
                  {/*</p>*/}
                  <div className="flex flex-col">
                    <span className="text-gray-350 lg:mb-3">Driver's ID</span>
                    <div>
                      {driverDetails.passportFile && (
                          <ImageComponent
                              imageUrl={driverDetails.passportFile}
                              altText={`${driverDetails.name} ID`}
                          />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="flex flex-col">
                    <span className="text-gray-350 lg:mb-2">Mobile Number</span>
                    <span>{driverDetails.phone}</span>
                  </p>
                  <p className="flex flex-col">
                    <span className="text-gray-350 lg:mb-2">Assigned Truck</span>
                    <span>
                  {driverDetails.truck && `${TruckiTypeNames[driverDetails.truck.truckType]} - ${driverDetails.truck.plateNumber}`}
                </span>
                  </p>
                  <div className="flex flex-col">
                    <span className="text-gray-350 lg:mb-2">Driver's License</span>
                    <div>
                      {driverDetails.driversLicence && (
                          <ImageComponent
                              imageUrl={driverDetails.driversLicence}
                              altText={`${driverDetails.name} License`}
                          />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <p className="flex flex-col">
                    <span className="text-gray-350 lg:mb-2">Address</span>
                    <span>{driverDetails.emailAddress}</span>
                  </p>
                </div>
              </div>
          ) : (
              <div>Something went wrong</div>
          )}
        </div>
  );
};

export default DriverDetails;
