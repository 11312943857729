"use client";
import React, { useState } from "react";
import { GoSearch } from "react-icons/go";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { PiBell } from "react-icons/pi";
import ProfileDropdown from "../dropdown/ProfileDropdown";
import { useNavigate } from "react-router-dom";
import { TfiMenu } from "react-icons/tfi";
import { FaRegUser } from "react-icons/fa";

type ManagerHeaderProps = {
  handleToggleSideBar: (event: React.SyntheticEvent) => void;
  toggleSideBar: boolean;
};

const ManagerHeader = (props: ManagerHeaderProps) => {
  const router = useNavigate();

  const [rotation, setRotation] = useState(0);
  const [profileDropDown, setProfileDropDown] = useState<boolean>(false);

  const handleToggleSideBar = props.handleToggleSideBar;
  const toggleSideBar = props.toggleSideBar;
  const rotateIcon = () => {
    setRotation(rotation + 180);
    setProfileDropDown((prev) => !prev);
  };

  const toProfilePage = () => {
    router("/manager");
  };
  const userString = localStorage.getItem("userKey");
  const user = userString ? JSON.parse(userString) : null;
  return (
    <>
      <header
        className={`flex justify-between items-center bg-white-100 p-4 fixed w-[100%] lg:w-[80%] h-fit ${
          !toggleSideBar && "z-[999]"
        } lg:z-[999]`}
      >
        <div className="lg:hidden">
          <h2
            className="sm:block text-3xl fixed top-6 left-3 z-50 cursor-pointer"
            onClick={handleToggleSideBar}
          >
            <TfiMenu />
          </h2>
        </div>
        <div className="md:w-[60%] lg:w-[30%] relative ml-[12%] md:ml-[20%] lg:ml-0">
          <span className="hidden lg:block absolute top-3 left-2 text-gray-500 text-xl sm:text-3xl lg:text-xl">
            <GoSearch className="w-20 md:w-auto" />
          </span>
          <input
            type="text"
            placeholder="Search"
            className="w-full pl-2 py-2 md:pl-10 bg-white-200 rounded-md outline-none"
          />
        </div>
        <div className="w-[90%] flex items-center justify-end">
          <span className="text-darkBlue-100 mr-3 text-3xl lg:text-xl">
            <PiBell />
          </span>
          <div
            className="flex justify-start items-center cursor-pointer"
            onClick={rotateIcon}
          >
            <span className="w-[30px] h-[30px] sm:h-[40px] sm:w-[40px] bg-white-300 p-1.5 sm:p-3 text-darkBlue-100 rounded-full">
              <FaRegUser />
            </span>
            <span className="mx-2 text-gray-500 hidden md:block">
              {user.firstName.includes("gabriel John")
                ? `${user.firstName}`
                : `${user.firstName} ${user.lastName}`}
            </span>
            <span
              className="text-darkBlue-100 text-3xl lg:text-xl transform rotate-[90deg] transition-transform duration-300"
              style={{ transform: `rotate(${rotation}deg)` }}
            >
              <MdOutlineKeyboardArrowDown />
            </span>
          </div>
        </div>
      </header>

      <ProfileDropdown
        isOpen={profileDropDown}
        handleProfilePage={toProfilePage}
      />
    </>
  );
};

export default ManagerHeader;
