import React from "react";
import {SummaryCardProps} from "../../Types";

const SummaryCard: React.FC<SummaryCardProps> = ({ img, title, rate, handleCardClick }) => {
  return (
    <div
      className="flex justify-start items-center p-4 w-[46%] sm:w-[47%] lg:w-[250px] bg-white-100 rounded-md hover:bg-slate-200 cursor-pointer"
      onClick={handleCardClick}
    >
      <span>{img}</span>
      <div className="flex flex-col pl-4 text-sm">
        <span className="text-[10px] text-gray-500">{title}</span>
        <span className="text-darkBlue-100 font-bold">{rate}</span>
      </div>
    </div>
  );
};

export default SummaryCard;
