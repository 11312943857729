import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

interface RootState {
  auth: {
    userInfo: {
      // Define the shape of userInfo here
    };
  };
}

function AuthRoute() {
  const { userInfo }: { userInfo: RootState["auth"]["userInfo"] } = useSelector(
    (state: RootState) => state.auth
  );

  return userInfo ? <Outlet /> : <Navigate to="/" replace />;
}

export default AuthRoute;
