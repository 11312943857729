import React, { useState, useEffect } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import { Company, ManagerDetails as ManagerDetail } from "../../../../Types/Manager";
import api, { ApiResponse } from "../../../../http/api/api_client";
import { GETMANAGER_BYID, ALLBUSINESS_ENDPOINT, EDIT_MANAGER_ENDPOINT, EditAssignedBusinesses } from "../../../../helpers/constant";
import { Link, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import Modal from "../../../../components/modal/Modal";

const ManagersDetails: React.FC = () => {
  const { managerId } = useParams<{ managerId: string }>();
  const [managerDetails, setManagerDetails] = useState<ManagerDetail | undefined>(undefined);
  const [business, setBusiness] = useState<Company[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  // Fetch manager details
  const fetchManagerDetails = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(`${GETMANAGER_BYID}?id=${managerId}`);
      if (response.statusCode === 200) {
        const data = response.data;
        setManagerDetails(data.data);
        setSelectedCompanies(data.data.company);
      }
    } catch (error) {
      console.error("Error fetching manager data:", error);
      setError("Failed to load manager details. Please try again.");
    } finally {
      setTimeout(() => setIsLoading(false), 2000);
    }
  };

  // Fetch all businesses
  const fetchAllBusiness = async () => {
    try {
      const response: ApiResponse<any> = await api.get(ALLBUSINESS_ENDPOINT);
      if (response.statusCode === 200) {
        setBusiness(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
      setError("Failed to load business data. Please try again.");
    }
  };

  // Handle company selection
  const handleCompanySelect = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption && !selectedCompanies.some((comp) => comp.id === selectedOption.value)) {
      setSelectedCompanies([
        ...selectedCompanies,
        {
          id: selectedOption.value,
          name: selectedOption.label.split(" - ")[0],
          location: selectedOption.label.split(" - ")[1],
          value: selectedOption.value,
          address: "",
          isActive: true,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      ]);
    }
  };

  // Handle company removal
  const handleCompanyRemove = (id: string) => {
    setSelectedCompanies(selectedCompanies.filter((company) => company.id !== id));
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsButtonLoading(true);
    setError(null); // Reset error state
    try {
      const companyIds = selectedCompanies.map((company) => company.id);
      const response: ApiResponse<any> = await api.put(EditAssignedBusinesses, { managerId, companyIds });
      if (response.statusCode === 200) {
        setIsModalOpen(false);
        fetchManagerDetails();
      }
    } catch (error: any) {
      console.error("Error updating manager:", error);
      setError(error?.message || "Something went wrong, try again.");
    } finally {
      setIsButtonLoading(false);
    }
  };

  useEffect(() => {
    fetchManagerDetails();
    fetchAllBusiness();
  }, [managerId]);

  // Filter out selected companies from the dropdown options
  const businessOptions = business
    .filter((biz) => !selectedCompanies.some((comp) => comp.id === biz.id))
    .map((biz) => ({ value: biz.id, label: `${biz.name} - ${biz.location}` }));

  return (
    <div>
      <Link to="/dashboard/managers" className="flex items-center gap-3 text-darkBlue-100">
        <FaCircleArrowLeft />
        <span>Back</span>
      </Link>
      <div className="flex items-center gap-3 pt-4 pb-1 border-b border-gray-200">
        <h1 className="text-gray-350">Managers</h1>/ <span>Manager Details</span>
      </div>
      {isLoading ? (
        <DetailsShimmerCard />
      ) : (
        <div className="rounded-lg px-4 py-6 bg-white-100 text-sm">
          <div className="flex justify-between">
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Name</span>
              <span>{managerDetails?.name}</span>
            </p>
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Mobile Number</span>
              <span>{managerDetails?.phone}</span>
            </p>
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Email</span>
              <span>{managerDetails?.emailAddress}</span>
            </p>
          </div>
          <div className="pt-10">
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Location(s)</span>
              {managerDetails?.company.map((e) => (
                <span key={e.id}>
                  {e.name} - {e.location}
                </span>
              ))}
            </p>
          </div>
          <button
            className="mt-8 text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 px-4 text-xs hover:bg-gray-500"
            onClick={() => setIsModalOpen(true)}
          >
            Assign Business
          </button>
        </div>
      )}

      <Modal isVisible={isModalOpen} onClosed={() => setIsModalOpen(false)}>
        <h2 className="mb-4 text-lg">Edit Assigned Companies</h2>
        {error && <div className="mb-4 text-red-500">{error}</div>}
        <Select
          name="companyId"
          options={businessOptions}
          isMulti={false}
          onChange={handleCompanySelect}
          placeholder="Select Company"
          className="mb-4"
        />
        <div>
          {selectedCompanies.map((company) => (
            <div key={company.id} className="flex items-center justify-between mb-2">
              <span>{`${company.name} - ${company.location}`}</span>
              <button
                onClick={() => handleCompanyRemove(company.id)}
                className="text-red-500 hover:underline"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <button
            onClick={handleSubmit}
            className={`px-4 py-2 bg-darkBlue-100 text-white rounded mr-2 ${isButtonLoading ? "opacity-50" : ""}`}
            disabled={isButtonLoading}
          >
            <span className="text-white-100">
            {isButtonLoading ? "Loading..." : "Save"}
            </span>
          </button>
          <button
            onClick={() => setIsModalOpen(false)}
            className="px-4 py-2 bg-gray-500 text-white rounded"
            disabled={isButtonLoading}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManagersDetails;
