import React from "react";
import { FaRegUser } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logingout } from "../../redux/Slices/authSlice";
import { logout } from "../../http/auth";

interface ProfileDropDownProps {
  isOpen: boolean;
  handleProfilePage: () => void;
}

const ProfileDropdown: React.FC<ProfileDropDownProps> = ({
  isOpen,
  handleProfilePage,
}) => {
  const router = useNavigate();
  // Logout Session
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      dispatch(logingout());
      logout();
      router("/");
    } catch (err) {
      console.error(err);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="absolute top-[10%] right-3 transition-opacity duration-500 ease-in z-[100] rounded-md bg-white-100 p-4 w-[8.5rem]">
      <div
        className="flex items-center gap-x-2  text-sm cursor-pointer hover:bg-white-200 p-2 rounded-md"
        onClick={handleProfilePage}
      >
        <span>
          <FaRegUser />
        </span>
        <span>Profile</span>
      </div>
      <div
        className="flex items-center gap-x-2 text-myred-100 mt-2 text-sm cursor-pointer hover:bg-white-200 p-2 rounded-md"
        onClick={handleLogout}
      >
        <span>
          <TbLogout />
        </span>
        <span>Logout</span>
      </div>
    </div>
  );
};
export default ProfileDropdown;
