import React, { useState } from 'react';
import Modal from './modal/Modal';
import { RiDeleteBin6Line } from 'react-icons/ri';
import api, { ApiResponse } from "../http/api/api_client";
import { ADDROUTETOBUSINESS_ENDPOINT } from "../helpers/constant";
import { toast } from "react-toastify";
import AddressForm from './AddressForm';
interface RoutesData {
    id: string;
    fromRoute: any;
    toRoute: any;
    price: string;
    gtv: string;
    ntons: string;
    isActive: boolean;
    name: string;
    fromRouteLat: string | null;
    FromRouteLng: string | null;
    toRouteLat: string | null;
    ToRouteLng: string | null;
}
interface AddRouteModalProps {
    businessId: string;
    isVisible: any;
    onClosed: () => void;
}

const AddRouteModal = ({
    businessId,
    isVisible,
    onClosed
}: AddRouteModalProps) => {
    const [routes, setRoutes] = useState<RoutesData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [routeToLocationLat, setRouteToLocationLat] = useState<number | null>(null);
    const [routeToLocationLong, setRouteToLocationLong] = useState<number | null>(null);
    const [routeToAddress, setRouteToAddress] = useState<string | null>(null);

    const [routeFromLocationLat, setRouteFromLocationLat] = useState<number | null>(null);
    const [routeFromLocationLong, setRouteFromLocationLong] = useState<number | null>(null);
    const [routeFromAddress, setRouteFromAddress] = useState<string | null>(null);
    const [clearAddress, setClearAddress] = useState(false);

    const handleRouteFromSelect = (lat: number, lng: number, address: string) => {
        setRouteFromLocationLat(lat);
        setRouteFromLocationLong(lng);
        setRouteFromAddress(address);

    };
    const handleRouteToSelect = (lat: number, lng: number, address: string) => {
        setRouteToLocationLat(lat);
        setRouteToLocationLong(lng);
        setRouteToAddress(address);
    };
    const handleAddRoute = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newRoute: RoutesData = { // Create a new RoutesData object
            id: (event.target as HTMLFormElement).id,
            fromRoute: routeFromAddress,
            toRoute: routeToAddress,
            price: (event.target as HTMLFormElement).price.value,
            gtv: (event.target as HTMLFormElement).gtv.value,
            ntons: (event.target as HTMLFormElement).ntons.value,
            // Assuming 'isActive' is a boolean type:
            isActive: true,
            name: `${routeFromAddress} - ${routeToAddress}`,
            fromRouteLat: routeFromLocationLat ? String(routeFromLocationLat) : "",
            FromRouteLng: routeFromLocationLong ? String(routeFromLocationLong) : "",
            toRouteLat: routeToLocationLat ? String(routeToLocationLat) : "",
            ToRouteLng: routeToLocationLong ? String(routeToLocationLong) : "",
        };

        setRoutes([...routes, newRoute]);
        setRouteFromLocationLat(null);
        setRouteFromLocationLong(null);
        setRouteToLocationLat(null);
        setRouteToLocationLong(null);
        setRouteToAddress(null);
        setRouteFromAddress(null);
        setClearAddress(true); // Trigger clear in AddressForm
        setTimeout(() => setClearAddress(false), 100);
        (event.target as HTMLFormElement).reset();
    };

    const handleDeleteRoute = (index: number) => {
        const updatedRoutes = [...routes];
        updatedRoutes.splice(index, 1);
        setRoutes(updatedRoutes);
    };

    const addRouteToBusiness = async () => {
        setIsLoading(true);
        const requestBody = {
            routes,
            businessId
        };

        try {
            const response: ApiResponse<any> = await api.post(ADDROUTETOBUSINESS_ENDPOINT, requestBody);
            if (response.statusCode === 201) {
                setRoutes([])
                setIsLoading(false);
                // onRouteAdded(routes); // Pass added routes to parent component for further actions
                onClosed(); // Close the modal after saving
                toast("Route Added Successfully");
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error adding routes:', error);
            onClosed()
        }
    };

    const isSaveDisabled = routes.length === 0;

    const handleAddMore = () => {
        setRoutes([]);
    };

    const renderRoutes = () => (
        <div className="flex flex-col-reverse">
            {routes.map((route, index) => (
                <div key={index} className="route-item rounded flex flex-row justify-between bg-teal-50 m-2 p-3">
                    <p>
                        {route.fromRoute} - {route.toRoute} - N{route.price}
                    </p>
                    <div className='flex flex-row'>

                        <span
                            className="text-red-500 cursor-pointer"
                            onClick={() => handleDeleteRoute(index)}
                        >
                            <RiDeleteBin6Line />
                        </span>
                    </div>
                </div>
            ))}

        </div>
    );

    return (
        <Modal isVisible={isVisible} onClosed={onClosed}>
            <div>
                <h1 className="font-extrabold mb-4">Add Route</h1>
                <form onSubmit={handleAddRoute}>
                    <div className="flex flex-col justify-between gap-5">

                        <AddressForm onAddressSelect={handleRouteFromSelect} AddressLabel='Route (From)' clearLocationAddress={clearAddress} />
                        <AddressForm onAddressSelect={handleRouteToSelect} AddressLabel='Route (To)' clearLocationAddress={clearAddress} />
                        <input
                            type="text"
                            name="ntons"
                            placeholder="Tonnage"
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                            required
                        />   <input
                            type="text"
                            name="price"
                            placeholder="Price"
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                            required
                        />
                        <input
                            type="text"
                            name="gtv"
                            placeholder="GTV"
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                            required
                        />
                        <input
                            type="hidden"
                            name="businessId"
                            value={businessId}
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />

                        {renderRoutes()}

                        <div className="py-5 text-center flex justify-between">
                            {routes.length < 1 ? (
                                <button
                                    className={`text-ms py-3 border border-gray-400 bg-transparent w-[45%] rounded-md cursor-pointer text-darkBlue-100 hover:bg-gray-200`}
                                    type="submit"

                                >
                                    Add
                                </button>
                            ) : (

                                <>
                                    <button
                                        className={`text-ms py-3 border border-gray-400 bg-transparent w-[45%] rounded-md cursor-pointer text-darkBlue-100 hover:bg-gray-200`}
                                        type="submit"

                                    >
                                        Add
                                    </button>
                                    <button
                                        className={`text-ms py-3 bg-darkBlue-100 w-[45%] rounded-md cursor-pointer text-white-100 ${isLoading ? 'opacity-50' : ''
                                            }`}
                                        type="button"
                                        onClick={addRouteToBusiness}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Adding...' : 'Save'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AddRouteModal;
