import React, { useEffect, useState } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { TransactionSummary } from "../../../Types/DashboardData";
import { TransactionsByManager } from "../../../Types/Manager";
import useLocalStorage from "../../../context/useLocalstorage";
import api, { ApiResponse } from "../../../http/api/api_client";
import {
  GET_FINANCIAL_TRANSACTION_SUMMARY,
  GET_FINANCIAL_TRANSACTIONS,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTIONS,
} from "../../../helpers/constant";
import ManagerLayout from "../../../components/mng-layout/ManagerLayout";
import DetailsShimmerCard from "../../../components/shimmer/DetailsShimmerCard";
import ErrorLoading from "../../../components/shimmer/ErrorLoading";

const Transactions = () => {
  const [activeTransaction, setActiveTransaction] = useState<
    "payout" | "advance" | "balance"
  >("payout");

  const handleClick = (transaction: "payout" | "advance" | "balance") => {
    setActiveTransaction(transaction);
  };
  const [dashboardData, setDashboardData] = useState<TransactionSummary>();
  const [transactions, setTransactions] = useState<TransactionsByManager[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // const userString = localStorage.getItem("userKey");
  // const user = userString ? JSON.parse(userString) : null;
  const [user, setUser] = useLocalStorage<any>("userKey", null);
  const [error, setError] = useState<Error | null>(null);
  const getStatusByIndex = (index: number | undefined) => {
    if (index === 0) {
      return "Initial Payment";
    }
    if (index === 1) {
      return "Sixty Percent Payment";
    }
    if (index === 2) {
      return "Forty Percent Payment";
    }
    if (index === 3) {
      return "Other";
    }
  };
  const fetchDashboardData = async () => {
    try {
      const response: ApiResponse<any> = await api.get(
        user!.role.includes("finance")
          ? GET_FINANCIAL_TRANSACTION_SUMMARY
          : GET_TRANSACTION_SUMMARY
      );
      if (response.statusCode === 200) {
        const data = response.data;
        setDashboardData(data.data);
        console.log(data.data, "dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };
  const getOrderStatusByIndex = (index: number | undefined) => {
    if (index === 0) {
      return "Pending";
    }
    if (index === 1) {
      return "Assigned";
    }
    if (index === 2) {
      return "Loaded";
    }
    if (index === 3) {
      return "InTransit";
    }
    if (index === 4) {
      return "Destination";
    }
    if (index === 5) {
      return "Delivered";
    }
  };
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response: ApiResponse<any> = await api.get(
          user!.role.includes("finance")
            ? GET_FINANCIAL_TRANSACTIONS
            : GET_TRANSACTIONS
        );
        if (response) {
          const data = response.data;
          setTransactions(data.data);
        } else {
          console.error("Error fetching transactions:");
          // Handle errors gracefully, e.g., display an error message to the user
        }
      } catch (error) {
        console.error("API Error:", error);
        // Handle unexpected errors
      } finally {
        setIsLoading(false);
      }
    };
    fetchDashboardData();
    fetchTransactions();
  }, []);

  return (
    <>
      <div>
        <h1>Transactions</h1>
      </div>
      <div className="grid grid-cols-1  md:grid-cols-3 gap-3 md:gap-6 mt-6 lg:max-w-[45vw] overflow-x-auto">
        <div
          className={`flex justify-start items-center p-4 w-[100%] lg:w-[200px] rounded-md cursor-pointer ${
            activeTransaction === "payout"
              ? "bg-darkBlue-100"
              : "bg-white-100 hover:bg-darkBlue-100"
          }`}
          onClick={() => handleClick("payout")}
        >
          <span className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white-200 text-darkBlue-100">
            <IoWalletOutline />
          </span>
          <div className="flex flex-col pl-4 text-sm">
            <span
              className={`text-[10px] ${
                activeTransaction === "payout"
                  ? "text-white-100"
                  : "text-gray-500 hover:text-white-100"
              }`}
            >
              Total Order
            </span>
            <span
              className={`font-bold ${
                activeTransaction === "payout"
                  ? "text-white-100"
                  : "text-darkBlue-100 hover:text-white-100"
              }`}
            >
              {dashboardData?.totalOrderCount}
            </span>
          </div>
        </div>
        <div
          className={`flex justify-start items-center p-4 w-[100%] lg:w-[200px] rounded-md cursor-pointer ${
            activeTransaction === "advance"
              ? "bg-darkBlue-100"
              : "bg-white-100 hover:bg-darkBlue-100"
          }`}
          onClick={() => handleClick("advance")}
        >
          <span className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white-200 text-darkBlue-100">
            <IoWalletOutline />
          </span>
          <div className="flex flex-col pl-4 text-sm">
            <span
              className={`text-[10px] ${
                activeTransaction === "advance"
                  ? "text-white-100"
                  : "text-gray-500 hover:text-white-100"
              }`}
            >
              Total GTV
            </span>
            <span
              className={`font-bold ${
                activeTransaction === "advance"
                  ? "text-white-100"
                  : "text-darkBlue-100 hover:text-white-100"
              }`}
            >
              N{dashboardData?.totalGtv}
            </span>
          </div>
        </div>
        <div
          className={`flex justify-start items-center p-4 w-[100%] lg:w-[200px] rounded-md cursor-pointer ${
            activeTransaction === "balance"
              ? "bg-darkBlue-100"
              : "bg-white-100 hover:bg-darkBlue-100"
          }`}
          onClick={() => handleClick("balance")}
        >
          <span className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white-200 text-darkBlue-100">
            <IoWalletOutline />
          </span>
          <div className="flex flex-col pl-4 text-sm">
            <span
              className={`text-[10px] ${
                activeTransaction === "balance"
                  ? "text-white-100"
                  : "text-gray-500 hover:text-white-100"
              }`}
            >
              Total Pay
            </span>
            <span
              className={`font-bold ${
                activeTransaction === "balance"
                  ? "text-white-100"
                  : "text-darkBlue-100 hover:text-white-100"
              }`}
            >
              N{dashboardData?.totalPayout}
            </span>
          </div>
        </div>
      </div>
      <div className="sm:flex justify-between items-center text-xs mt-5">
        <div className="flex gap-x-2">
          <button className="rounded-sm bg-white-100 py-1 px-2 lg:p-3 text-gray-400 hover:bg-gray-500">
            This Week
          </button>
          <button className="rounded-sm bg-white-100 py-1 px-2 lg:p-3 text-gray-400 hover:bg-gray-500">
            This Month
          </button>
          <button className="rounded-sm bg-white-100 py-1 px-2 lg:p-3 text-gray-400 hover:bg-gray-500">
            This Year
          </button>
        </div>
        <div className="mt-3 sm:mt-0">
          <span>
            <input type="date" placeholder="Date" className="p-2" />
          </span>
        </div>
      </div>
      {activeTransaction === "payout" && (
        <div className="overflow-x-auto mt-6">
          {isLoading ? (
            <DetailsShimmerCard />
          ) : error ? (
            <ErrorLoading
              error={error instanceof Error ? error.message : String(error)}
            />
          ) : (
            <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
              <thead>
                <tr>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                    Order Id
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                    Business
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                    Date
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                    Amount
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                    Truck
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                    Truck Owner
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-right">
                    Type
                  </th>
                  <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-right">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="min-w-full divide-y">
                {transactions.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="text-center py-4">
                     
                      <div className="flex justify-center items-center h-60">
                        <img
                          src="/nodata.png"
                          width={120}
                          height={100}
                          alt="no data"
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  transactions.map((transaction) => (
                    <tr key={transaction.transactionId}>
                      <td className="py-4 px-2">{transaction.orderId}</td>
                      <td className="py-4 px-2">{transaction.businessName}</td>
                      <td className="py-4 px-2">
                        {new Date(
                          transaction.transactionDate
                        ).toLocaleDateString()}
                      </td>
                      <td className="py-4 px-2">{transaction.amount}</td>
                      <td className="py-4 px-2">{transaction.truckNo}</td>
                      <td className="py-4 px-2">{transaction.truckOwner}</td>
                      <td className="py-4 px-2 text-right">
                        {getStatusByIndex(transaction.transactionType)}
                      </td>
                      <td className="py-4 px-2 text-right">
                        {getOrderStatusByIndex(transaction.orderStatus)}
                      </td>
                      {/* ... (other table cells based on your data) ... */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
      {activeTransaction === "advance" && (
        <div className="overflow-x-auto mt-6">
          <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
            <thead>
              <tr>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Transaction ID
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Date
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Beneficiary Name
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Service
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-right">
                  Amount
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-right">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="min-w-full divide-y"></tbody>
          </table>
        </div>
      )}
      {activeTransaction === "balance" && (
        <div className="overflow-x-auto mt-6">
          <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
            <thead>
              <tr>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Transaction ID
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Date
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Beneficiary Name
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider">
                  Service
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-right">
                  Amount
                </th>
                <th className="py-4 px-2 text-darkBlue-100 tracking-wider text-right">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="min-w-full divide-y"></tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Transactions;
