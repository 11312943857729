import React, { useEffect, useRef, useState } from "react";
import { VscCloudUpload } from "react-icons/vsc";
import Select from "react-select";
import Modal from "./modal/Modal";
import { RiDeleteBin6Line } from "react-icons/ri";
import SearchableSelect from "./select_formfield";
import {
  ADD_TRUCKS_ENDPOINT,
} from "../helpers/constant";
import api from "../http/api/api_client";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchTruckOwners } from "../redux/Slices/truckOwnerSlice";
import { AppDispatch, RootState } from "../redux/store";

enum TruckType {
  Flatbed = 0,
  BoxBody = 1,
  BucketBody = 2,
  Lowbed = 3,
  ContainerizedBody = 4,
  Refrigerator = 5,
}

interface AddTruckModalProps {
  handleFetchTrucks: () => void;
  showAddTruckModal: boolean;
  setShowAddTruckModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTruckModal = ({
  showAddTruckModal,
  setShowAddTruckModal,
  handleFetchTrucks,
}: AddTruckModalProps) => {
  const [selectedDocuments, setSelectedDocuments] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(""); // State for error messages
  const [status, setStatus] = useState<string>(""); // State for status messages
  const [selectedOwnerId, setSelectedOwnerId] = useState<string>("");
  const [selectedTruckType, setSelectedTruckType] = useState<TruckType>(0); // State for truck type
  const [truckName, setTruckName] = useState<string>(""); // State for TruckName

  const dispatch = useDispatch<AppDispatch>();
  const { truckOwners, loading, error } = useSelector((state: RootState) => state.truckOwners);

  useEffect(() => {
    dispatch(fetchTruckOwners());
  }, [dispatch]);

  const formRef = useRef<HTMLFormElement | null>(null);

  const uploadToCloudinary = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "truckiupload"); // Replace with your Cloudinary preset

    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dsrasvzjn/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to upload image");
    }

    const data = await response.json();
    return data.secure_url; // URL of the uploaded file
  };

  const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const newFiles = Array.from(event.target.files);
    const validFiles = newFiles.filter((file) => isValidFileType(file));

    setSelectedDocuments((prevDocuments) => [...prevDocuments, ...validFiles]);
  };

  const isValidFileType = (file: File) => {
    const allowedExtensions = ["png", "jpg", "pdf","jpeg"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    return allowedExtensions.includes(extension!);
  };

  const removeDocument = (file: File) => {
    setSelectedDocuments((prevDocuments) =>
      prevDocuments.filter((doc) => doc !== file)
    );
  };

  const ownerOptions = truckOwners.map((truckOwner) => ({
    value: truckOwner.id,
    label: truckOwner.name,
  }));

  const handleOwnerChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setSelectedOwnerId(selectedOption?.value || "");
  };

  const handleTruckTypeChange = (
    selectedOption: { value: TruckType; label: string } | null
  ) => {
    console.log(selectedOption);
    
    setSelectedTruckType(selectedOption?.value || 0);
  };

  const validateForm = () => {
    if (selectedDocuments.length === 0) {
      setErrorMessage("Please upload at least one document.");
      return false;
    }
    if (!selectedOwnerId) {
      setErrorMessage("Please select a truck owner.");
      return false;
    }
    if (selectedTruckType === null) {
      setErrorMessage("Please select a truck type.");
      return false;
    }
    if (!truckName.trim()) {
      setErrorMessage("Please enter the truck name.");
      return false;
    }

    if (!formRef.current) {
      setErrorMessage("Form is not properly initialized.");
      return false;
    }

    const formElements = formRef.current.elements;

    const plateNumber = (formElements.namedItem("plateNumber") as HTMLInputElement)?.value.trim();
    const truckCapacity = (formElements.namedItem("truckCapacity") as HTMLInputElement)?.value.trim();
    const insuranceExpiryDate = (formElements.namedItem("insuranceExpiryDate") as HTMLInputElement)?.value;
    const truckLicenseExpiryDate = (formElements.namedItem("TruckLicenseExpiryDate") as HTMLInputElement)?.value;
    const roadWorthinessExpiryDate = (formElements.namedItem("roadWorthinessExpiryDate") as HTMLInputElement)?.value;

    if (!plateNumber) {
      setErrorMessage("Please enter the truck number.");
      return false;
    }
    if (!truckCapacity) {
      setErrorMessage("Please enter the truck capacity.");
      return false;
    }
    if (!insuranceExpiryDate) {
      setErrorMessage("Please select the insurance expiry date.");
      return false;
    }
    if (!truckLicenseExpiryDate) {
      setErrorMessage("Please select the truck license expiry date.");
      return false;
    }
    if (!roadWorthinessExpiryDate) {
      setErrorMessage("Please select the road worthiness expiry date.");
      return false;
    }

    // Clear any previous error messages
    setErrorMessage("");
    return true;
  };

  const handleAddTrucks = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the form before proceeding
    if (!validateForm()) {
      return;
    }

    if (!formRef.current) return;
    const formElements = formRef.current.elements;
    setIsLoading(true);
    setErrorMessage(""); // Clear previous errors
    setStatus("Uploading images...");

    try {
      // Upload documents to Cloudinary
      const documentUrls = await Promise.all(
        selectedDocuments.map(uploadToCloudinary)
      );

      setStatus("Submitting...");

      const requestBody = {
        truckName: truckName.trim(),
        documents: documentUrls,
        plateNumber: (formElements.namedItem("plateNumber") as HTMLInputElement)
          .value.trim(),
        truckType: selectedTruckType,
        truckCapacity: (
          formElements.namedItem("truckCapacity") as HTMLInputElement
        ).value.trim(),
        truckOwnerId: selectedOwnerId,
        truckLicenseExpiryDate: (
          formElements.namedItem("TruckLicenseExpiryDate") as HTMLInputElement
        ).value,
        roadWorthinessExpiryDate: (
          formElements.namedItem("roadWorthinessExpiryDate") as HTMLInputElement
        ).value,
        insuranceExpiryDate: (
          formElements.namedItem("insuranceExpiryDate") as HTMLInputElement
        ).value,
      };

      // Send data to the API
      const response = await api.post(ADD_TRUCKS_ENDPOINT, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Success handling
      setShowAddTruckModal(false);
      setIsLoading(false);
      handleFetchTrucks();
      toast.success("Truck Added Successfully");
      console.log("Form submission response:", response.data);
    } catch (error: any) {
      setIsLoading(false);
      console.error("Form submission error:", error);
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("Something went wrong. Could not upload truck.");
      }
    } finally {
      setStatus(""); // Reset status
    }
  };

  // *********styling the select components**********
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "none" : "1px solid #CCCCCD",
      boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
      padding: "0.4rem 10px",
      borderRadius: "10px",
    }),
  };

  const truckTypeOptions = [
    { value: TruckType.Flatbed, label: "Flatbed" },
    { value: TruckType.BoxBody, label: "Box Body" },
    { value: TruckType.BucketBody, label: "Bucket Body" },
    { value: TruckType.Lowbed, label: "Lowbed" },
    { value: TruckType.ContainerizedBody, label: "Containerized Body" },
    { value: TruckType.Refrigerator, label: "Refrigerator" },
  ];

  return (
    <>
      <Modal
        isVisible={showAddTruckModal}
        onClosed={() => setShowAddTruckModal(false)}
      >
        <div>
          <h1 className="font-black">Add New Truck</h1>
          <form ref={formRef} onSubmit={handleAddTrucks}>
            <div className="pb-4 w-full border-dashed border border-darkBlue-100 pt-1">
              <div className="pb-4">
                <label className="form-file-holder text-xs flex flex-col justify-center items-center cursor-pointer">
                  <span className="text-lg w-fit text-darkBlue-100 mx-auto pt-3">
                    <VscCloudUpload />
                  </span>
                  <span className="text-darkBlue-100">
                    Upload Documents (PNG, JPG, or PDF):
                  </span>
                  <p className="flex items-center gap-1 pb-3">
                    <span>Drag & drop file or</span>
                    <span className="underline font-bold text-darkBlue-100">
                      Browse
                    </span>
                  </p>
                  <input
                    type="file"
                    id="documents"
                    name="documents"
                    multiple
                    accept=".png,.jpg,.pdf,.jpeg"
                    onChange={handleDocumentUpload}
                    className="hidden"
                  />
                </label>
              </div>
            </div>
            {selectedDocuments.length > 0 && (
              <div className="mt-2">
                {selectedDocuments.map((document) => (
                  <div
                    key={document.name}
                    className="rounded flex flex-row justify-between bg-teal-50 m-2 p-3"
                  >
                    <p>{document.name}</p>
                    <span
                      className="text-red-500 cursor-pointer"
                      onClick={() => removeDocument(document)}
                    >
                      <RiDeleteBin6Line />
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="flex flex-col gap-3 mt-4">
              {/* Truck Name Field */}
              <label className="text-gray-400 text-sm">Truck Name</label>
              <input
                type="text"
                name="truckName"
                placeholder="Truck Name"
                value={truckName}
                onChange={(e) => setTruckName(e.target.value)}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              {/* Truck Owner Field */}
              <label className="text-gray-400 text-sm">Truck Owner</label>
              <SearchableSelect options={ownerOptions} onChange={handleOwnerChange} />

              {/* Truck Number Field */}
              <label className="text-gray-400 text-sm">Truck Number</label>
              <input
                type="text"
                name="plateNumber"
                placeholder="Truck Number"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              {/* Truck Type Field */}
              <label className="text-gray-400 text-sm">Truck Type</label>
              <Select
                styles={customSelectStyles}
                name={"truckType"}
                options={truckTypeOptions}
                placeholder="Select Truck Type"
                onChange={handleTruckTypeChange}
                value={truckTypeOptions.find(option => option.value === selectedTruckType)}
              />

              {/* Truck Capacity Field */}
              <label className="text-gray-400 text-sm">Truck Capacity</label>
              <input
                type="text"
                name="truckCapacity"
                placeholder="Tonnage"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              {/* Insurance Expiry Date Field */}
              <label className="text-gray-400 text-sm">
                Insurance Expiry Date
              </label>
              <input
                type="date"
                name="insuranceExpiryDate"
                placeholder="Insurance Expiry Date"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              {/* Truck License Expiry Date Field */}
              <label className="text-gray-400 text-sm">
                Truck License Expiry Date
              </label>
              <input
                type="date"
                name="TruckLicenseExpiryDate"
                placeholder="Truck License Expiry Date"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              {/* Road Worthiness Expiry Date Field */}
              <label className="text-gray-400 text-sm">
                Road Worthiness Expiry Date
              </label>
              <input
                type="date"
                name="roadWorthinessExpiryDate"
                placeholder="Road Worthiness Expiry Date"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              {/* Display Error Message */}
              {errorMessage && (
                <div className="text-red-500 text-sm mb-2">
                  {errorMessage}
                </div>
              )}

              {/* Submit Button */}
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-full rounded-md cursor-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? `${status}...` : "Add Truck"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddTruckModal;
