import * as React from 'react';
import Modal from "./modal/Modal";
import { useState } from "react";
import api from "../http/api/api_client";
import { RESETUSERSPASSWORD } from "../helpers/constant";
import { toast } from "react-toastify";

type Props = {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    userId: string;
};

export const ResetUserPassword = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission
        setErrorMessage(""); // Reset error message

        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        if (!props.userId) {
            setErrorMessage("User ID cannot be null.");
            return;
        }

        setIsLoading(true);
        const requestBody = {
            userId: props.userId,
            password: password,
        };

        try {
            const response = await api.post(RESETUSERSPASSWORD, requestBody);
            if (response.data.statusCode === 200) {
                toast.success("Password updated successfully!");
                props.setIsVisible(false); // Close the modal
            } else {
                setErrorMessage("Failed to update password.");
            }
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("An error occurred. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            isVisible={props.isVisible}
            onClosed={() => props.setIsVisible(false)}
        >
            <div>
                <h1 className="mb-10 font-bold">Reset Password</h1>
                <form onSubmit={handleResetPassword}>
                    <div className="flex flex-col justify-between gap-5">
                        <input
                            type="password"
                            placeholder="Enter user's password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        <input
                            type="password"
                            placeholder="Confirm user's password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        {errorMessage && (
                            <span className="text-red-500 text-sm">{errorMessage}</span>
                        )}
                        <div className="py-3 lg:py-5 text-center">
                            <button
                                className={`text-ms py-3 bg-darkBlue-100 w-full rounded-md cursor-pointer text-white-100 ${isLoading ? 'opacity-50' : ''}`}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
