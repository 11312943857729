import React, { useState, useEffect } from "react";

type OrderFilters = {
  startDate: string | null;
  endDate: string | null;
  truckNo?: string;
  status?: number;
  quantity?: string;
  createdAt?: string | null;
};

type OrderSearchFilterProps = {
  onSearch: (filters: OrderFilters) => void;
};

const OrderSearchFilter: React.FC<OrderSearchFilterProps> = ({ onSearch }) => {
  const initialFilters: OrderFilters = {
    startDate: null,
    endDate: null,
    truckNo: "",
    status: undefined,
    quantity: "",
    createdAt: null,
  };

  const [filters, setFilters] = useState<OrderFilters>(initialFilters);
  const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]:
        name === "status"
          ? value ? Number(value) : undefined
          : value || null, // set empty strings to null
    }));
  };

  // Enable the end date input only if the start date is selected
  useEffect(() => {
    setIsEndDateEnabled(!!filters.startDate);
  }, [filters.startDate]);

  const handleSubmit = () => {
    const { startDate, endDate } = filters;

    // Convert empty string to null on submission
    const finalFilters = {
      ...filters,
      startDate: filters.startDate || null,
      endDate: filters.endDate || null,
      createdAt: filters.createdAt || null,
    };

    if (
      !finalFilters.startDate &&
      !finalFilters.endDate &&
      !finalFilters.truckNo &&
      finalFilters.status === undefined &&
      !finalFilters.quantity &&
      !finalFilters.createdAt
    ) {
      alert("Please fill at least one field.");
      return;
    }

    if (finalFilters.startDate && !finalFilters.endDate) {
      alert("End Date is required if Start Date is provided.");
      return;
    }

    if (finalFilters.startDate && finalFilters.endDate && new Date(finalFilters.endDate) < new Date(finalFilters.startDate)) {
      alert("End Date cannot be earlier than Start Date.");
      return;
    }

    onSearch(finalFilters);
  };

  const handleReset = () => {
    setFilters(initialFilters);
  };

  return (
    <div className="search-filter lg:flex items-end gap-x-3">
      <div>
        <label className="text-gray-400 text-sm mb-2">Start Date</label>
        <input
          type="date"
          name="startDate"
          value={filters.startDate ?? ""}
          onChange={(e) => handleInputChange(e)}
          className="p-2 text-sm w-full lg:w-[9rem] border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
        />
      </div>
      <div>
        <label className="text-gray-400 text-sm mb-2">End Date</label>
        <input
          type="date"
          name="endDate"
          value={filters.endDate ?? ""}
          onChange={handleInputChange}
          className="p-2 text-sm w-full lg:w-[9rem] border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
          disabled={!isEndDateEnabled} // End date is enabled only if start date is set
        />
      </div>

      <div>
        <label className="text-gray-400 text-sm mb-2">Truck No</label>
        <input
          type="text"
          name="truckNo"
          value={filters.truckNo}
          onChange={handleInputChange}
          placeholder="Truck No"
          className="p-2 text-sm w-full lg:w-[10rem] border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
        />
      </div>

      <div>
        <label className="text-gray-400 text-sm mb-2">Status</label>
        <select
          name="status"
          value={filters.status ?? ""}
          onChange={handleInputChange}
          className="p-2 text-sm w-full lg:w-[10rem] border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
        >
          <option value="">Select Status</option>
          <option value="0">Pending</option>
          <option value="1">Assigned</option>
          <option value="2">Loaded</option>
          <option value="3">In Transit</option>
          <option value="4">Destination</option>
          <option value="5">Delivered</option>
          <option value="6">Flagged</option>
          <option value="7">Archived</option>
          <option value="7">Accepted</option>
          <option value="7">Declined</option>
        </select>
      </div>

      <div>
        <label className="text-gray-400 text-sm mb-2">Quantity</label>
        <input
          type="text"
          name="quantity"
          value={filters.quantity}
          onChange={handleInputChange}
          placeholder="Quantity"
          className="p-2 text-sm w-full lg:w-[10rem] border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
        />
      </div>

      <div>
        <label className="text-gray-400 text-sm mb-2">Created At</label>
        <input
          type="date"
          name="createdAt"
          value={filters.createdAt ?? ""}
          onChange={handleInputChange}
          className="p-2 text-sm w-full lg:w-[10rem] border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
        />
      </div>

      <div className="flex gap-x-3 mt-2">
        <button
          className="text-sm p-3 bg-darkBlue-100 w-[100%] rounded-md cursor-pointer text-white-100"
          onClick={handleSubmit}
        >
          Search
        </button>
        <button
          className="text-sm p-3 bg-gray-500 w-[100%] rounded-md cursor-pointer text-white-100"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default OrderSearchFilter;
