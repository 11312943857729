import React, { Suspense, useState } from "react";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../http/auth";
import { routes } from "../../utiles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal from "../modal/ConfirmationModal";
import { IoClose } from "react-icons/io5";
import useIdleTimeout from "../session-timeout/useIdleTimeout";
import { useDispatch } from "react-redux";
import { logingout } from "../../redux/Slices/authSlice";

type SideBarProps = {
  handleShowSideBar: (event: React.SyntheticEvent) => void;
};

const SideBar = (props: SideBarProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const routerName = location.pathname;
  const router = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  // const handleToggleSideBar = () => {
  //   setToggleSidebar(!toggleSidebar);
  // };

  // Logout Session
  const handleLogout = async () => {
    try {
      dispatch(logingout());
      logout();
      router("/");
    } catch (err) {
      console.error(err);
    }
  };

  // Timeout Function
  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
  };
  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 600 });

  const stay = () => {
    setOpenModal(false);
    idleTimer.reset();
  };
  // Function to handle actions on timeout (e.g., logout, redirect)
  const handleOnTimeout = () => {
    setOpenModal(false);
    dispatch(logingout());
    logout();
    router("/");
  };

  return (
    <aside className="bg-gray-200 w-[300px] sm:w-6/12 md:w-4/12 lg:w-full h-full flex flex-col px-6 peer-focus:left-0 peer:transition ease-out delay-150 duration-200 z-[99999] pl-[10%]">
      <div className="mb-10 pt-7 flex justify-between">
        <img src="/trucki-logo.png" width={100} height={100} alt="logo" />
        <div className="lg:hidden">
          <IoClose onClick={props.handleShowSideBar} className="text-3xl" />
        </div>
      </div>
      <ul className="mt-6">
        {routes.map((routeItem, index) => {
          const isActive =
            routerName === routeItem.layout ||
            routerName.startsWith(routeItem.layout);

          return (
            <Link key={index} to={routeItem.layout}>
              <li
                className={`w-[70%] flex justify-start items-center py-4 rounded-md hover:text-darkBlue-100 hover:bg-white-100 px-2 ${
                  isActive ? "text-darkBlue-100" : "text-gray-500"
                }`}
                onClick={props.handleShowSideBar}
              >
                <span>{isActive ? routeItem.iconColor : routeItem.icon}</span>
                <span className="text-sm font-medium ml-3">
                  {routeItem.name}
                </span>
              </li>
            </Link>
          );
        })}
      </ul>

      <div
        className="flex items-center mt-10 text-red-500 pl-2 cursor-pointer w-[60%]"
        onClick={() => setShowConfirmationModal(true)}
      >
        <span>
          <TbLogout />
        </span>
        <span className="pl-2">Log Out</span>
      </div>
      <ConfirmationModal
        isVisible={showConfirmationModal}
        onClosed={() => setShowConfirmationModal(false)}
      >
        <div className="w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Logout?</span>
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </ConfirmationModal>

      {openModal && (
        <div className="fixed inset-0 z-[999] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold">
                  Session TimeOut
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Your session is about to expire. You'll be automatically signed
                out.
              </p>
              <p className="text-left pb-5 text-sm text-[#141414]/70">
                Do you want to stay signed in?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={() => {
                    handleOnTimeout();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Log out now
                </button>
                <button
                  onClick={() => {
                    stay();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#545252]  px-3 py-4 rounded-lg "
                >
                  Stay signed in
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

export default SideBar;
