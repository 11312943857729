// export const BASE_URL = 'https://localhost:7175';
export const BASE_URL = "https://trucki.azurewebsites.net";
// export const BASE_URL = "https://trucki-trucki-api-staging.azurewebsites.net";

export const LOGIN_ENDPOINT = "/api/Auth/Login";
export const GET_DASHBORA_ENDPOINT = "/api/Admin/GetDashboardData";
export const ALLBUSINESS_ENDPOINT = "/api/Business/GetAllBusiness";
export const ADDBUSINESS_ENDPOINT = "/api/Business/CreateNewBusiness";
export const ADDROUTETOBUSINESS_ENDPOINT = "/api/Business/AddRouteToBusiness";
export const GETBUSINESSBYID_ENDPOINT = "/api/Business/GetBusinessById";
export const EDITBUSINESS_ENDPOINT = "/api/Business/EditBusiness";
export const DELETEBUSINESS_ENDPOINT = "/api/Business/DeleteBusiness";
export const DISABLEBUSINESS_ENDPOINT = "/api/Business/DisableBusiness";
export const ENABLEBUSINESS_ENDPOINT = "/api/Business/EnableBusiness";
export const EDITROUTE_ENDPOINT = "/api/Business/EditRoute";
export const DELETEROUTE_ENDPOINT = "/api/Business/DeleteRoute";
export const GETALLMANAGERROUTE_ENDPOINT = "/api/Manager/GetAllManager";
export const GETMANAGER_BYID = "/api/Manager/GetManager";
export const ADDMANAGERROUTE_ENDPOINT = "/api/Manager/AddManager";
export const DEACTIVATEMANAGERROUTE_ENDPOINT = "/api/Manager/DeleteManager";
export const EDIT_MANAGER_ENDPOINT = "/api/Manager/EditManager";
export const ADD_TRUCK_OWNER_ENDPOINT = "/api/TruckOwner/CreateNewTruckOwner";
export const GET_TRUCK_OWNER_BY_ID_ENDPOINT =
  "/api/TruckOwner/GetTruckOwnerById";
export const EDIT_TRUCK_OWNER_ENDPOINT = "/api/TruckOwner/EditTruckOwner";
export const DELETE_TRUCK_OWNER_ENDPOINT = "/api/TruckOwner/DeleteTruckOwner";
export const GET_ALL_TRUCK_OWNER_ENDPOINT = "/api/TruckOwner/GetAllTruckOwners";
export const ADD_DRIVERS_ENDPOINT = "/api/Driver/AddDriver";
export const GET_ALL_TRUCK_DRIVERS = "/api/Driver/GetAllDrivers";
export const EDIT_DRIVERS = "/api/Driver/EditDriver";
export const GET_ALL_DRIVERS_BYID = "/api/Driver/GetDriverById";

export const SEARCH_DRIVERS = "/api/Driver/SearchDrivers";
export const SEARCH_MANAGERS = "/api/Manager/SearchManagers";
export const SEARCH_BUSINESSES = "/api/Business/SearchBusinesses";
export const SEARCH_TRUCKS = "/api/Truck/SearchTrucks";
export const SEARCH_OFFICERS = "/api/FieldOfficer/SearchOfficers";
export const SEARCH_TRUCKOWNERS = "/api/TruckOwner/SearchTruckOwners";
export const SEARCH_CUSTOMER = "/api/Customer/SearchCustomers";

export const DELETE_DRIVER_ENDPOINT = "/api/Driver/DeactivvateDriver";
export const CREATE_OFFICERS_ENDPOINT = "/api/FieldOfficer/CreateNewOfficer";
export const DELETEOFFICER_ENDPOINT = "/api/FieldOfficer/DeleteOfficer";
export const GET_ALL_OFFICERS_ENDPOINT =
  "/api/FieldOfficer/GetAllFieldOfficers";
export const EDIT_OFFICERS_ENDPOINT = "/api/FieldOfficer/EditOfficer";
export const GET_ALL_OFFICERSBYID = "/api/FieldOfficer/GetOfficerById";
export const GET_ALL_TRUCKS = "/api/Truck/GetAllTrucks";
export const ADD_TRUCKS_ENDPOINT = "/api/Truck/AddNewTruck";
export const EDIT_TRUCKS_ENDPOINT = "/api/Truck/EditTruck";
export const DELETE_TRUCKS_ENDPOINT = "/api/Truck/DeleteTruck";
export const GET_ALL_TRUCKSBYID = "/api/Truck/GetTruckById";
export const GET_TRUCK_DOCUMENT = "/api/Truck/GetTruckDocuments";
export const ASSIGN_DRIVER_TO_TRUCK = "/api/Truck/AssignDriverToTruck";
export const UPDATE_TRUCK_STATUS = "/api/Truck/UpdateTruckStatus";
export const ADD_CUSTOMERS_ENDPOINT = "/api/Customer/AddNewCustomer";
export const EDIT_CUSTOMERS_ENDPOINT = "/api/Customer/EditCustomer";
export const GET_ALL_CUSTOMERS_ENDPOINT = "/api/Customer/GetAllCustomers";
export const DELETE_CUSTOMERS_ENDPOINT = "/api/Customer/RemoveCustomer";
export const GET_CUSTOMERS_BYID = "/api/Customer/GetCustomerById";

export const ADD_ORDER_ENDPOINT = "/api/Order/CreateOrder";
export const GET_ALL_ORDER = "/api/Order/GetAllOrders";
export const GET_ORDER_BY_ID = "/api/Order/GetOrderById";
export const EDIT_ORDER_ENDPOINT = "/api/Order/UpdateOrder";
export const GETALL_ORDER_BYID = "/api/Order/GetOrderById";
export const GETORDER_BYSTATUS = "/api/Order/GetOrdersByStatus";
export const ASSIGN_TRUCK_TO_ORDER = "/api/Order/AssignTruckToOrder";
export const GET_ROUTES_BY_BUSINESSID = "/api/Business/GetRoutesByBusinessId";
export const GET_DASHBOARD_GTV_ENDPOINT = "/api/Admin/GetGtvDashBoardData";
export const GET_MANAGER_DASHBOARD_DATA =
  "/api/Manager/GetManagerDashboardData";
export const UPLOAD_ORDER_MANIFEST = "/api/Order/UploadOrderDocuments";
export const UPLOAD_DELIVERY_MANIFEST = "/api/Order/uploadDeliveryManifest";
export const PAY_60_PERCENT = "/api/Order/pay60Percent";
export const PAY_40_PERCENT = "/api/Order/pay40Percent";
export const GET_TRANSACTION_SUMMARY =
  "/api/Manager/GetTransactionSummaryResponseModel";
export const GET_FINANCIAL_TRANSACTION_SUMMARY =
  "/api/Manager/GetFinancialTransactionSummaryResponseModel";
export const GET_TRANSACTIONS = "/api/Manager/GetTransactionsByManager";
export const GET_FINANCIAL_TRANSACTIONS =
  "/api/Manager/GetTransactionsByFinancialManager";
export const GET_ORDER_STATS = "/api/Admin/GetOrderStatistics";
export const RESETUSERSPASSWORD = "/api/Auth/ResetUserPassword";
export const UPDATE_USER_PASSWORD = "/api/Auth/UpdateUserPassword";
export const APPROVE_TRUCK_OWNER = "/api/TruckOwner/ApproveTruckOwner";
export const REJECT_TRUCK_OWNER = "/api/TruckOwner/NotApproveTruckOwner";
export const BLOCK_TRUCK_OWNER = "/api/TruckOwner/BlockTruckOwner";
export const UNBLOCK_TRUCK_OWNER = "/api/TruckOwner/UnblockTruckOwner";
export const UPDATE_TRUCK_APPROVAL_STATUS =
  "/api/Truck/UpdateTruckApprovalStatus";
export const SEARCH_ORDER_ENDPOINT = "/api/Order/SearchOrders";
export const Get_Manager_Gtv_DashBoard_Summary = "/api/Manager/GetManagerGtvDashBoardSummary";
export const EditAssignedBusinesses = "/api/Manager/EditAssignedBusinesses";
export const GetBusinessGtvDashboardSummary = "/api/Business/GetBusinessGtvDashboardSummary";
export const EditAssignedBusinessesToOfficer = '/api/FieldOfficer/ReassignOfficerCompany';
