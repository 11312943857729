export default function TableShimmer () {
    return (
      <>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 px-2 whitespace-nowrap"></td>
          <td className="py-4 px-2 whitespace-nowrap"></td>
          <td className="py-4 px-2 whitespace-nowrap"></td>
          <td className="py-4 px-2 whitespace-nowrap"></td>
          <td className="py-4 px-2 whitespace-nowrap"></td>
        </tr>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
        </tr>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
        </tr>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
        </tr>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
        </tr>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
        </tr>
        <tr className="border-b border-white-100 bg-gray-350 h-6 w-full animate-pulse">
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
          <td className="py-4 whitespace-nowrap"></td>
        </tr>
      </>
    );
}