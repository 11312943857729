import moment from "moment";

const FormattedDate = ({ date }: any) => {
  const formatDate = (dateString: string) => {
    const parsedDate = moment(dateString, "MM/DD/YYYY HH:mm:ss");
    return parsedDate.format("MMM d yyyy");
  };

  return <span>{formatDate(date)}</span>;
};

export default FormattedDate;
