interface ErrorType {
  error: string | Error | null | undefined;
}

const ErrorMessage = ({ error }: ErrorType) => (
  <p className="text-[12px] sm:text-[12px] sm:pt-0.5 font-light text-red-500">
    {error instanceof Error ? error.message : error || "Unknown error"}
  </p>
);

function ErrorLoading({ error }: { error: string | Error | null | undefined }) {
  return (
    <div className="pb-16 rounded-2xl">
      <div className="mx-auto text-center ">
        <svg
          className="mx-auto  my-2"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9771 2.50002C11.6369 2.50602 8.60418 3.82225 6.36517 5.96197L4.56507 4.16187C3.80297 3.39977 2.5 3.93949 2.5 5.01721V11.7742C2.5 12.4423 3.04158 12.9839 3.70968 12.9839H10.4667C11.5444 12.9839 12.0841 11.6809 11.3221 10.9188L9.21774 8.81449C10.7734 7.35788 12.7863 6.55103 14.9249 6.53258C19.5821 6.49236 23.5077 10.2613 23.4674 15.073C23.4292 19.6376 19.7286 23.4678 15 23.4678C12.9271 23.4678 10.9679 22.7279 9.42389 21.3732C9.18483 21.1635 8.82379 21.1762 8.59889 21.4011L6.5998 23.4002C6.35423 23.6457 6.36638 24.0461 6.62409 24.2789C8.84063 26.2809 11.7779 27.5 15 27.5C21.9035 27.5 27.4999 21.9036 27.5 15.0001C27.5001 8.10451 21.8727 2.48767 14.9771 2.50002Z"
            fill="#4CC5D2"
          />
        </svg>
        <h2 className="text-base md:text-lg font-bold text-[#1B5958]">
          Something went wrong
        </h2>
        <ErrorMessage error={error} />
        <button
          onClick={() => {
            window.location.reload();
          }}
          className="bg-darkBlue-100 rounded-full text-white transition-colors duration-200 dark:hover:text-red-500 text-center hover:text-[#000] focus:outline-none mt-4 py-3 px-12 text-xs"
        >
          Reload
        </button>
      </div>
    </div>
  );
}

export default ErrorLoading;
