"use client";

import React, { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  ADDMANAGERROUTE_ENDPOINT,
  ALLBUSINESS_ENDPOINT,
  DEACTIVATEMANAGERROUTE_ENDPOINT,
  EDIT_MANAGER_ENDPOINT,
  GETALLMANAGERROUTE_ENDPOINT,
  SEARCH_MANAGERS,
} from "../../../helpers/constant";
import api, { ApiResponse } from "../../../http/api/api_client";
import MainLayout from "../../../components/main-layout/MainLayout";
import TableShimmer from "../../../components/shimmer/TableShimmer";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { toast } from "react-toastify";
import { ResetUserPassword } from "../../../components/ResetUserPassword";
interface ManagerData {
  id: string;
  name: string;
  phone: number;
  emailAddress: string;
  managerType: number;
  managerId: any;
  userId: any;
}

interface Business {
  id: string;
  name: string;
}

const Managers = () => {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itFinancialItemOffset, setItFinancialItemOffset] = useState<number>(0);
  const [opManagerPerPage, setOpManagerPerPage] = useState<number>(10);
  const [financialMgerPerPage, setFinancialMgerPerPage] = useState<number>(10);
  const [addManagerModal, setAddManagerModal] = useState<boolean>(false);
  const [toggleManager, setToggleManager] = useState<boolean>(true);
  const [editOperationManager, setEditOperationManager] =
    useState<boolean>(false);
  const [editFinancialManager, setEditFinancialManager] =
    useState<boolean>(false);

  const [operationMngTableData, setOperationMngTableData] = useState<
    ManagerData[]
  >([]);
  const [financialMngTableData, setFinancialMngTableData] = useState<
    ManagerData[]
  >([]);
  const [oppManagerToEdit, setOppManagerToEdit] = useState<ManagerData>();
  const [finManagerToEdit, setFinManagerToEdit] = useState<ManagerData>();

  const [selectedManagerType, setSelectedManagerType] = useState<string | null>(
    null
  );
  const [showRemoveManager, setShowRemoveManager] = useState<boolean>(false);
  const [deletingManagerId, setDeletingManagerId] = useState<string | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddLoading, setIsAddLoading] = useState<boolean>(false);
  const [business, setBusiness] = useState<Business[]>([]);

  const [isResetPasswordModalVisible, setResetPasswordModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const router = useNavigate();

  const handleOpManagerPagechange = ({ selected }: { selected: number }) => {
    const newOffset = selected * opManagerPerPage;
    setItemOffset(newOffset);
  };

  const handleOpMgerPersonPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newOpManagerPerPage = parseInt(e.target.value);
    setOpManagerPerPage(newOpManagerPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(operationMngTableData.length / opManagerPerPage);

  const currentOperationManager = operationMngTableData.slice(
    itemOffset,
    itemOffset + opManagerPerPage
  );

  // ******--------Pagination functionality for Financial Managers------ *********
  const handleFinancialMgerPageChange = ({
    selected,
  }: {
    selected: number;
  }) => {
    const newOffset = selected * financialMgerPerPage;
    setItFinancialItemOffset(newOffset);
  };

  const handleFinancialPersonPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newFinancialMgerPerPage = parseInt(e.target.value);
    setFinancialMgerPerPage(newFinancialMgerPerPage);
    setItFinancialItemOffset(0);
  };

  const financialMgerPageCount = Math.ceil(
    financialMngTableData.length / financialMgerPerPage
  );

  const currFinancialManager = financialMngTableData.slice(
    itFinancialItemOffset,
    itFinancialItemOffset + financialMgerPerPage
  );

  const handleSearchManager = async () => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${SEARCH_MANAGERS}?searchWords=${searchQuery}`
      );
      if (response.statusCode === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error("Error searching managers:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const fetchManagerData = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(
        GETALLMANAGERROUTE_ENDPOINT
      );
      if (response.statusCode === 200) {
        const data = response.data;
        const managers = data.data || [];
        console.log(managers, "loading managers");

        // ***** Filter managers based on their type and update the respective state variable
        const operationalManagers = managers.filter(
          (manager: { managerType: number }) => manager.managerType === 0
        );
        const financialManagers = managers.filter(
          (manager: { managerType: number }) => manager.managerType === 1
        );
        setOperationMngTableData(operationalManagers);
        setFinancialMngTableData(financialManagers);
      }
    } catch (error) {
      console.error("Error fetching manager data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleAddManager = async (manager: {}, managerType: number) => {
    setIsAddLoading(true);
    try {
      const response = await api.post(ADDMANAGERROUTE_ENDPOINT, manager);
      if (response.data.statusCode === 201) {
        const responseData = response.data;
        console.log(responseData);
        if (responseData.isSuccessful) {
          const newManagerData = responseData.data;
          console.log(newManagerData, "new manager");
          if (newManagerData) {
            await fetchManagerData();
            setSearchResults([]);
            setSearchQuery("");
            setAddManagerModal(false);
            toast("Manager Added Successfully");
          } else {
            console.error("Manager data not found in response");
          }
        } else {
          console.error("Failed to add manager:", responseData.responseMessage);
        }
      } else {
        console.error("Failed to add manager:", response.statusText);
      }
      setIsAddLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleManagersDetail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsAddLoading(true);
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const name = formData.get("name") as string;
    const managerType =
      formData.get("managerType") === "Operational Manager" ? 0 : 1;
    const phone = formData.get("phone") as string;
    const emailAddress = formData.get("emailAddress") as string;
    const companyIds = formData.getAll("companyId");

    handleAddManager(
      {
        name: name,
        phone: phone,
        emailAddress: emailAddress,
        companyId: companyIds,
        managerType: managerType,
      },
      managerType
    );
  };

  const handleEditOperationManager = (manager: ManagerData) => {
    setOppManagerToEdit(manager);
    setEditOperationManager(true);
    setSelectedManagerType("Operational Manager");
  };

  const handleEditFinancialManager = (manager: ManagerData) => {
    setFinManagerToEdit(manager);
    setEditFinancialManager(true);
    setSelectedManagerType("Financial Manager");
  };

  const handleEditSubmission = async (
    e: React.FormEvent<HTMLFormElement>,
    managerType: number
  ) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target as HTMLFormElement);
    const managerId = formData.get("managerId");
    const name = formData.get("name");
    const phone = formData.get("phone");
    const emailAddress = formData.get("emailAddress");

    const requestBody = {
      managerId: managerId,
      name: name,
      phone: phone,
      emailAddress: emailAddress,
    };
    const response: ApiResponse<any> = await api.post(
      EDIT_MANAGER_ENDPOINT,
      requestBody
    );
    if (response.statusCode === 200) {
      await fetchManagerData();
      setEditOperationManager(false);
      setEditFinancialManager(false);
      setIsLoading(false);
    } else {
      console.error("Failed to edit manager:", response.statusText);
      // Handle error or provide feedback to the user
      setIsLoading(false);
    }
  };

  const handleDeleteManager = async (managerId: string) => {
    setDeletingManagerId(managerId);
    setShowRemoveManager(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingManagerId) {
      const response: ApiResponse<any> = await api.post(
        `${DEACTIVATEMANAGERROUTE_ENDPOINT}?managerId=${deletingManagerId}`
      );
      if (response.statusCode === 200) {
        setShowRemoveManager(false);
        setDeletingManagerId("");
        setOperationMngTableData((prevData) =>
          prevData.filter((manager) => manager.id !== deletingManagerId)
        );
        setFinancialMngTableData((prevData) =>
          prevData.filter((manager) => manager.id !== deletingManagerId)
        );
      } else {
        console.error("Failed to delete manager:", response.statusText);
      }
    }
  };

  const fetchAllBusiness = async () => {
    try {
      const response: ApiResponse<any> = await api.get(ALLBUSINESS_ENDPOINT);
      if (response.statusCode === 200) {
        const data = response.data;
        setBusiness(data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };

  const businessOptions = business.map((bus) => ({
    value: bus.id,
    label: bus.name,
  }));

  useEffect(() => {
    fetchAllBusiness();
    fetchManagerData();
    if (searchQuery !== "") {
      handleSearchManager();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, searchQuery]);

  // *********styling the select component**********
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "none" : "1px solid #CCCCCD",
      boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
      padding: "0.4rem 6px",
      borderRadious: "10",
    }),
  };

  return (
    <>
      {toggleManager ? (
        <>
          <div className="pb-1 border-b border-gray-300">
            <div className="flex justify-between items-center py-4 flex-wrap gap-y-3">
              <div className="flex items-center">
                <h1>Managers</h1>
                <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
                  <input
                    type="text"
                    placeholder="Search Manager"
                    value={searchQuery}
                    onChange={handleChange}
                    className="outline-none"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchManager();
                      }
                    }}
                  />
                  <span
                    className="text-gray-500 cursor-pointer"
                    onClick={handleSearchManager}
                  >
                    <GoSearch className="w-[20px] h-[20px]" />
                  </span>
                </div>
              </div>
              <span className="font-bold">
                <button
                  className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
                  onClick={() => setAddManagerModal(true)}
                >
                  <span>
                    <FaPlus className="w-10" />
                  </span>{" "}
                  <span>Add Managers</span>
                </button>
              </span>
            </div>
            <div className="flex items-center gap-5 text-xs">
              <h1
                className={`cursor-pointer font-medium ${
                  toggleManager
                    ? "text-myred-100 border-b border-myred-100 pb-1"
                    : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
                onClick={() => setToggleManager(true)}
              >
                OPERATIONAL MANAGERS
              </h1>
              <h1
                className={`cursor-pointer font-medium ${
                  !toggleManager
                    ? "text-myred-100 border-b border-myred-100 pb-1"
                    : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
                onClick={() => setToggleManager(false)}
              >
                FINANCIAL MANAGERS
              </h1>
            </div>
          </div>

          <div className="overflow-x-auto mt-6">
            <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
              <thead className="bg-white-100 text-gray-450 py-4 font-light">
                <tr className="border-b border-gray-200">
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Name
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Mobile Number
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider whitespace-nowrap">
                    Email Address
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="min-w-full divide-y">
                {isLoading ? (
                  <TableShimmer />
                ) : (
                  (searchResults.length > 0
                    ? searchResults
                    : currentOperationManager
                  ).map((data) => (
                    <tr
                      key={data.id}
                      className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                    >
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/managers/${data.id}`)}
                      >
                        {data.name}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/managers/${data.id}`)}
                      >
                        {data.phone}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/managers/${data.id}`)}
                      >
                        {data.emailAddress}
                      </td>
                      <td>
                        <div className="flex flex-row justify-center gap-x-3 items-center ">
                          <span
                            className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 py-1 px-3 rounded-md"
                            onClick={() => {
                              setSelectedUserId(data.userId); // Set the user ID
                              setResetPasswordModal(true); // Show the modal
                            }}
                          >
                            Reset Password
                          </span>
                          <span
                            className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                            onClick={() => handleEditOperationManager(data)}
                          >
                            <FaRegEdit />
                          </span>
                          <span
                            className="text-red-500 ml-4 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                            onClick={() => handleDeleteManager(data.id)}
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
                {searchResults.length === 0 && searchQuery && (
                  <tr>
                    <td colSpan={4}>
                      <div className="flex justify-center items-center h-60">
                        <img
                          src="/nodata.png"
                          width={100}
                          height={100}
                          alt="No data"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleOpMgerPersonPageChange}
                  value={opManagerPerPage}
                  className="p-1 bg-white-100 cursor-pointer"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Managers per page</span>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handleOpManagerPagechange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="pb-1 border-b border-gray-300">
            <div className="flex justify-between items-center py-4">
              <div className="flex items-center">
                <h1>Managers</h1>
                <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
                  <input
                    type="text"
                    placeholder="Search Manager"
                    value={searchQuery}
                    onChange={handleChange}
                    className="outline-none"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchManager();
                      }
                    }}
                  />
                  <span
                    className="text-gray-500 cursor-pointer"
                    onClick={handleSearchManager}
                  >
                    <GoSearch className="w-[20px] h-[20px]" />
                  </span>
                </div>
              </div>
              <span className="font-bold">
                <button
                  className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
                  onClick={() => setAddManagerModal(true)}
                >
                  <span>
                    <FaPlus className="w-10" />
                  </span>{" "}
                  <span>Add Managers</span>
                </button>
              </span>
            </div>
            <div className="flex items-center gap-5 text-xs">
              <h1
                className={`cursor-pointer font-medium ${
                  toggleManager
                    ? "text-myred-100 border-b border-myred-100 pb-1"
                    : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
                onClick={() => setToggleManager(true)}
              >
                OPERATIONAL MANAGERS
              </h1>
              <h1
                className={`cursor-pointer font-medium ${
                  !toggleManager
                    ? "text-myred-100 border-b border-myred-100 pb-1"
                    : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
                onClick={() => setToggleManager(false)}
              >
                FINANCIAL MANAGERS
              </h1>
            </div>
          </div>
          <div className="overflow-x-auto mt-6">
            <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
              <thead className="bg-white-100 text-gray-450 py-4 font-light">
                <tr className="border-b border-gray-200">
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Name
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Mobile Number
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Email Address
                  </th>
                  <th className="py-4 px-2 text-gray-350 tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="min-w-full divide-y">
                {isLoading ? (
                  <TableShimmer />
                ) : (
                  (searchResults.length > 0
                    ? searchResults
                    : currFinancialManager
                  ).map((data) => (
                    <tr
                      key={data.id}
                      className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                    >
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/manager/${data.id}`)}
                      >
                        {data.name}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/manager/${data.id}`)}
                      >
                        {data.phone}
                      </td>
                      <td
                        className="py-4 px-2 whitespace-nowrap"
                        onClick={() => router(`/dashboard/manager/${data.id}`)}
                      >
                        {data.emailAddress}
                      </td>
                      <td className="flex items-center gap-x-4 py-4 px-2">
                        <span
                          className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                          onClick={() => handleEditFinancialManager(data)}
                        >
                          <FaRegEdit />
                        </span>
                        <span
                          className="text-red-500  cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                          onClick={() => handleDeleteManager(data.id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  ))
                )}

                {searchResults.length === 0 && searchQuery && (
                  <tr>
                    <td colSpan={4}>
                      <div className="flex justify-center items-center h-60">
                        <img
                          src="/nodata.png"
                          width={100}
                          height={100}
                          alt="No data"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
            <div className="flex items-center gap-2 text-sm text-gray-450">
              <span>View</span>
              <span>
                <select
                  onChange={handleFinancialPersonPageChange}
                  value={financialMgerPerPage}
                  className="p-1 bg-white-100"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>Managers per page</span>
            </div>
            <Pagination
              pageCount={financialMgerPageCount}
              handlePageClick={handleFinancialMgerPageChange}
            />
          </div>
        </>
      )}
      <ResetUserPassword
        isVisible={isResetPasswordModalVisible}
        setIsVisible={setResetPasswordModal}
        userId={selectedUserId!}
      />
      <Modal
        isVisible={addManagerModal}
        onClosed={() => setAddManagerModal(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Add New Manager</h1>
          <form onSubmit={handleManagersDetail}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="name"
                placeholder="Manager Name"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="managerType"
                styles={customSelectStyles}
                options={[
                  {
                    value: "Operational Manager",
                    label: "Operational Manager",
                  },
                  {
                    value: "Financial Manager",
                    label: "Financial Manager",
                  },
                ]}
                placeholder="Select Manager Type"
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <input
                type="text"
                name="phone"
                placeholder="Mobile Number"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="companyId"
                styles={customSelectStyles}
                options={businessOptions}
                isMulti
                placeholder="Select Company"
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />

              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  {isAddLoading ? "Loading..." : "Add Manager"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isVisible={editOperationManager}
        onClosed={() => setEditOperationManager(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Edit Manager</h1>
          <form onSubmit={(e) => handleEditSubmission(e, 0)}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                defaultValue={oppManagerToEdit?.name}
                name="name"
                placeholder=""
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="managerType"
                styles={customSelectStyles}
                options={[
                  {
                    value: "Operational Manager",
                    label: "Operational Manager",
                  },
                ]}
                placeholder="Default Manager"
                defaultValue={{
                  value: selectedManagerType || "",
                  label: selectedManagerType || "",
                }}
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <input
                type="text"
                placeholder=""
                name="phone"
                defaultValue={oppManagerToEdit?.phone}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                name="managerId"
                defaultValue={oppManagerToEdit?.id}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                placeholder="kaybams9999@gmail.com"
                name="emailAddress"
                defaultValue={oppManagerToEdit?.emailAddress}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isVisible={editOperationManager}
        onClosed={() => setEditOperationManager(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Edit Manager</h1>
          <form onSubmit={(e) => handleEditSubmission(e, 0)}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                defaultValue={oppManagerToEdit?.name}
                name="name"
                placeholder=""
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="managerType"
                styles={customSelectStyles}
                options={[
                  {
                    value: "Operational Manager",
                    label: "Operational Manager",
                  },
                ]}
                placeholder="Default Manager"
                defaultValue={{
                  value: selectedManagerType || "",
                  label: selectedManagerType || "",
                }}
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <input
                type="text"
                placeholder=""
                name="phone"
                defaultValue={oppManagerToEdit?.phone}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                name="managerId"
                defaultValue={oppManagerToEdit?.id}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                placeholder="kaybams9999@gmail.com"
                name="emailAddress"
                defaultValue={oppManagerToEdit?.emailAddress}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isVisible={editFinancialManager}
        onClosed={() => setEditFinancialManager(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Edit Manager</h1>
          <form onSubmit={(e) => handleEditSubmission(e, 1)}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                defaultValue={finManagerToEdit?.name}
                name="name"
                placeholder=""
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />

              <Select
                name="managerType"
                styles={customSelectStyles}
                options={[
                  {
                    value: "Financial Manager",
                    label: "Financial Manager",
                  },
                ]}
                placeholder="Default Manager"
                defaultValue={{
                  value: selectedManagerType || "",
                  label: selectedManagerType || "",
                }}
                className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
              />
              <input
                type="text"
                placeholder=""
                name="phone"
                defaultValue={finManagerToEdit?.phone}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                name="managerId"
                defaultValue={finManagerToEdit?.id}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                placeholder="kaybams9999@gmail.com"
                name="emailAddress"
                defaultValue={finManagerToEdit?.emailAddress}
                className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
              />
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        isVisible={showRemoveManager}
        onClosed={() => setShowRemoveManager(false)}
      >
        <div className="w-[60%] mx-auto lg:w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Manager?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowRemoveManager(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleConfirmDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Managers;
