import React from 'react';
import { TruckCardProps } from "../../Types";

const TruckCard: React.FC<TruckCardProps> = ({ children, onClick }) => {
  return (
    <div
      className="flex justify-between rounded-md bg-white-100 cursor-pointer"
      onClick={onClick} // Attach the onClick handler here
    >
      <div className="text-xs p-2 flex gap-2">
        <span><img src="/truck-group.png" width={50} height={50} alt='' /></span>
        <div>
          {children}
        </div>
      </div>
      <div className="flex justify-end items-end p-2">
        <button className="bg-darkBlue-100 text-white-100 py-1 px-4 text-xs rounded-md">View More</button>
      </div>
    </div>
  );
};

export default TruckCard;
