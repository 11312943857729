import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/dashboard/dashboard/page";
import Business from "./Pages/dashboard/businesses/page";
import BusinessDetails from "./Pages/dashboard/businesses/[businessId]/page";
import Settings from "./Pages/dashboard/settings/page";
import Customers from "./Pages/dashboard/customers/page";
import ManagersCustomers from "./Pages/manager/customers/page";
import Officers from "./Pages/dashboard/officers/page";
import Trucks from "./Pages/dashboard/trucks/page";
import ManagersTrucks from "./Pages/manager/trucks/page";
import TruckOwners from "./Pages/dashboard/truck-owners/page";
import Drivers from "./Pages/dashboard/drivers/page";
import Managers from "./Pages/dashboard/managers/page";
import ManagersDetails from "./Pages/dashboard/managers/[managerId]/page";
import DriverDetails from "./Pages/dashboard/drivers/[driversId]/page";
import TruckownerDetails from "./Pages/dashboard/truck-owners/[truckownerId]/page";
import TruckDetails from "./Pages/dashboard/trucks/[truckId]/page";
import OfficersDetails from "./Pages/dashboard/officers/[officerId]/page";
import CustomerDetails from "./Pages/dashboard/customers/[customerId]/page";
import ManagerDashboard from "./Pages/manager/dashboard/page";
import Orders from "./Pages/manager/orders/page";
import Transactions from "./Pages/manager/transactions/page";
import OrderDetails from "./Pages/manager/orders/[orderId]/page";
import ManagerCustomerDetails from "./Pages/manager/customers/[customerId]/page";
import App from './App';


// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <Login />,
//     },
//     {
//         path: "/dashboard",
//         element: <Dashboard />,
//     },
//     {
//         path: "/dashboard/business",
//         element: <Business />,
//     },  {
//         path: "/dashboard/managers",
//         element: <Managers />,
//     },
//     {
//         path: "/dashboard/manager/:managerId",
//         element: <ManagersDetails />,
//     },
//     {
//         path: "/dashboard/drivers",
//         element: <Drivers />,
//     },
//     {
//         path: "/dashboard/driver/:driversId",
//         element: <DriverDetails />,
//     },
//     {
//         path: "/dashboard/truck-owners",
//         element: <TruckOwners />,
//     },
//     {
//         path: "/dashboard/truck-owners/:truckownerId",
//         element: <TruckownerDetails />,
//     },
//     {
//         path: "/dashboard/trucks",
//         element: <Trucks />,
//     },
//     {
//         path: "/dashboard/trucks/:truckId",
//         element: <TruckDetails />,
//     },
//     {
//         path: "/dashboard/officers",
//         element: <Officers />,
//     },
//     {
//         path: "/dashboard/officers/:officerId",
//         element: <OfficersDetails />,
//     },
//     {
//         path: "/dashboard/customers",
//         element: <Customers />,
//     },
//     {
//         path: "/dashboard/customers/:customerId",
//         element: <CustomerDetails />,
//     },
//     {
//         path: "/dashboard/settings",
//         element: <Settings />,
//     },
//     {
//         path: "/dashboard/business/:businessId",
//         element: <BusinessDetails />,
//     },
//     {
//         path: "/manager",
//         element: <ManagerDashboard />,
//     },
//     {
//         path: "/manager/orders",
//         element: <Orders />,
//     },
//     {
//         path: "/manager/transactions",
//         element: <Transactions />,
//     },
//     {
//         path: "/manager/trucks",
//         element: <ManagersTrucks />,
//     }, {
//         path: "/manager/customers",
//         element: <ManagersCustomers />,
//     },
//     {
//         path: "/manager/customers/:customerId",
//         element: <ManagerCustomerDetails />,
//     },
//     {
//         path: "/manager/order/:orderId",
//         element: <OrderDetails />,
//     },
// ]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
