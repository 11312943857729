import React from "react";
import Chart from "react-google-charts";

export const data = [
  ["Expenses", "Total"],
  ["Labour Cost", 200],
  ["Fuel", 300],
  ["Others", 200],
  ["Maintanance", 400],
];

const ExpensesChart = () => {
  return (
    <div className="sm:">
      <Chart
        chartType="PieChart"
        width="100%"
        height={"400px"}
        data={data}
        options={
          // Chart options
          {
            legend: { position: "bottom" },
            interpolateNulls: false,
            colors: ["#3B82F6", "#F59E0C", "#FACC14", "#15B8A6"],

            is3D: true,
          }
        }
      />
    </div>
  );
};

export default ExpensesChart;
