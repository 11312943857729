import React from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps {
    handlePageClick: ({ selected }: { selected: number }) => void;
    pageCount: number
}

const Pagination: React.FC<PaginationProps> = ({handlePageClick, pageCount}) => {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="flex items-center gap-4 text-darkBlue-100"
        activeClassName="bg-darkBlue-100 text-white-100 px-2 rounded text-sm"

      />
    </div>
  );
};

export default Pagination;
