"use client";

import React, { useState } from "react";
import { CiMail } from "react-icons/ci";
import { LuUser2 } from "react-icons/lu";
import { MdLockOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/main-layout/MainLayout";
import ActivityLog from "../../../components/activity-table/ActivityLog";

const Settings = () => {
  const [activeComponent, setActiveComponent] = useState<
    "profile" | "activeLog" | "roles-permissions"
  >("profile");
  const [oldPassState, setOldPassState] = useState<boolean>(true);
  const [newPassState, setNewPassState] = useState<boolean>(true);
  const [newPassConfState, setNewPassConfState] = useState<boolean>(true);

  const router = useNavigate();

  const handleClick = (
    component: "profile" | "activeLog" | "roles-permissions"
  ) => {
    setActiveComponent(component);
  };

  const handleActivityDetials = () => {
    router("/dashboard/settings/activity-details");
  };

  return (
    <div>
      <h1>Settings</h1>

      <div className="flex flex-1 text-sm my-4">
        <span
          className={`cursor-pointer ${
            activeComponent === "profile"
              ? "text-myred-100 border-b border-myred-100 pb-1 font-extrabold"
              : "hover:text-myred-100 hover:border-b hover:border-myred-100"
          }`}
          onClick={() => handleClick("profile")}
        >
          PROFILE
        </span>
        <span
          className={`mx-4 cursor-pointer ${
            activeComponent === "activeLog"
              ? "text-myred-100 border-b border-myred-100 pb-1 font-extrabold"
              : "hover:text-myred-100 hover:border-b hover:border-myred-100"
          }`}
          onClick={() => handleClick("activeLog")}
        >
          ACTIVITY LOG
        </span>
        <span
          className={`cursor-pointer ${
            activeComponent === "roles-permissions"
              ? "text-myred-100 border-b border-myred-100 pb-1 font-extrabold"
              : "hover:text-myred-100 hover:border-b hover:border-myred-100"
          }`}
          onClick={() => handleClick("roles-permissions")}
        >
          ROLES & PERMISSIONS
        </span>
      </div>
      {activeComponent === "profile" && (
        <div className="flex flex-col lg:flex-row gap-3">
          <div className="w-[100%] lg:w-[50%] bg-white-100 rounded-lg py-8 px-1 lg:px-6">
            <div className="flex justify-between">
              <p className="text-sm flex gap-3">
                <span className="w-[50px] h-[50px] flex items-center justify-center font-extrabold bg-gray-100 ml-2 text-darkBlue-100 rounded-full">
                  TA
                </span>
                <span className="flex flex-col">
                  <span className="font-bold">Trucki Admin</span>
                  <span className="text-gray-300">admin@trucki.co</span>
                </span>
              </p>
              <p>
                <button className="bg-myred-300 text-myred-200 hover:text-white-100  border-1 py-2 px-2 rounded-md border-myred-200 text-sm hover:bg-myred-200">
                  Replace Image
                </button>
              </p>
            </div>
            <form>
              <div className="mt-5">
                <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                  <LuUser2 className="w-5 h-5" />
                </span>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
                />
              </div>
              <div className="mt-6">
                <span className="absolute mt-5 ml-2 z-10 text-gray-450">
                  <CiMail className="w-5 h-5" />
                </span>
                <input
                  type="email"
                  placeholder="Email"
                  className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
                />
              </div>
              <div className="mt-[10%]">
                <button
                  type="submit"
                  className="bg-darkBlue-100 py-4 text-white-100 w-full rounded-md cursor-pointer hover:bg-gray-500"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
          <div className="w-[100%] lg:w-[50%] bg-white-100 rounded-lg py-8 px-1 lg:px-6">
            <h1 className="font-extrabold">Password</h1>
            <p className="text-gray-300">Update your password here</p>
            <form>
              <div className="mt-5">
                <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                  <MdLockOutline className="w-5 h-5" />
                </span>
                <input
                  type={oldPassState ? "password" : "text"}
                  placeholder="Old Password"
                  className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
                />
                <span
                  className="absolute z-10 text-gray-450 mt-5 ml-[-8%] md:ml-[-4%] lg:ml-[-2%] xl:ml-[-2.3%] cursor-pointer"
                  onClick={() => setOldPassState(!oldPassState)}
                >
                  <MdOutlineRemoveRedEye className="w-5 h-5" />
                </span>
              </div>
              <div className="mt-5">
                <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                  <MdLockOutline className="w-5 h-5" />
                </span>
                <input
                  type={newPassState ? "password" : "text"}
                  placeholder="New Password"
                  className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
                />
                <span
                  className="absolute z-10 text-gray-450 mt-5 ml-[-8%] md:ml-[-4%] lg:ml-[-2%] xl:ml-[-2.3%] cursor-pointer"
                  onClick={() => setNewPassState(!newPassState)}
                >
                  <MdOutlineRemoveRedEye className="w-5 h-5" />
                </span>
              </div>
              <div className="mt-5">
                <span className="absolute z-10 mt-5 ml-2 text-gray-450">
                  <MdLockOutline className="w-5 h-5" />
                </span>
                <input
                  type={newPassConfState ? "password" : "text"}
                  placeholder="Re-enter New Password"
                  className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
                />
                <span
                  className="absolute z-10 text-gray-450 mt-5 ml-[-8%] md:ml-[-4%] lg:ml-[-2%] xl:ml-[-2.3%] cursor-pointer"
                  onClick={() => setNewPassConfState(!newPassConfState)}
                >
                  <MdOutlineRemoveRedEye className="w-5 h-5" />
                </span>
              </div>
              <div className="mt-[7%]">
                <button
                  type="submit"
                  className="bg-darkBlue-100 py-4 text-white-100 w-full rounded-md cursor-pointer hover:bg-gray-500"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {activeComponent === "activeLog" && (
        <>
          <ActivityLog handleDetails={handleActivityDetials} />
        </>
      )}

      {activeComponent === "roles-permissions" && (
        <>
          <div className="text-sm px-4 pt-4 bg-white-100 rounded-md">
            <p className="flex justify-between py-2 border-b border-gray-300">
              <span>Role</span>
              <span>Admin</span>
            </p>
            <p className="flex justify-between py-2 border-b border-gray-300">
              <span>Permission</span>
              <span
                className="text-mygreen-200 cursor-pointer"
                onClick={() => router("/dashboard/settings/permission-details")}
              >
                View Details
              </span>
            </p>
            <p className="flex justify-between py-2">
              <span>Action</span>
              <span className="text-myred-100 cursor-pointer">Delete</span>
            </p>
          </div>
          <div className="text-sm p-4 bg-white-100 rounded-md mt-2">
            <p className="flex justify-between py-2 border-b border-gray-300">
              <span>Role</span>
              <span>Operations Manager</span>
            </p>
            <p className="flex justify-between py-2 border-b border-gray-300">
              <span>Permission</span>
              <span
                className="text-mygreen-200 cursor-pointer"
                onClick={() => router("/dashboard/settings/permission-details")}
              >
                View Details
              </span>
            </p>
            <p className="flex justify-between py-2">
              <span>Action</span>
              <span className="text-myred-100 cursor-pointer">Delete</span>
            </p>
          </div>
          <p
            className="text-mygreen-200 flex items-center gap-1 mt-4 w-fit cursor-pointer"
            onClick={() => router("/dashboard/settings/addroles")}
          >
            <span>
              <IoMdAddCircleOutline />
            </span>
            <span>Add Role</span>
          </p>
        </>
      )}
    </div>
  );
};

export default Settings;
