import React from "react";
import { MdLockOutline, MdOutlineRemoveRedEye } from "react-icons/md";

interface ChangePasswordProps {
    handlePasswordChange: ()=> void
}

const ChangePassword: React.FC<ChangePasswordProps> = ({handlePasswordChange}) => {
  return (
    <>
      <div className="w-[100%]">
        <form className="mx-auto w-[90%] text-center">
          <h1 className="text-3xl font-medium mt-10 py-4">Change Password</h1>
          <p className="text-gray-300">
            You new password must be different from previously used password
          </p>
          <div className="mt-10 pt-5">
            <span className="absolute z-10 mt-5 ml-2 text-gray-450">
              <MdLockOutline className="w-5 h-5" />
            </span>
            <input
              type="password"
              placeholder="Password"
              className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
            />
            <span className="absolute z-10 text-gray-450 mt-5 ml-[-8%] md:ml-[-4%] lg:ml-[-2%] xl:ml-[-2.3%] cursor-pointer">
              <MdOutlineRemoveRedEye className="w-5 h-5" />
            </span>
          </div>
          <div className="mt-6">
            <span className="absolute z-10 mt-5 ml-2 text-gray-450">
              <MdLockOutline className="w-5 h-5" />
            </span>
            <input
              type="password"
              placeholder="Password"
              className="py-4 pl-8 pr-4 w-full border-2 border-gray-400 rounded-xl bg-transparent relative"
            />
            <span className="absolute z-10 text-gray-450 mt-5 ml-[-8%] md:ml-[-4%] lg:ml-[-2%] xl:ml-[-2.3%] cursor-pointer">
              <MdOutlineRemoveRedEye className="w-5 h-5" />
            </span>
          </div>
          <div className="mt-[10%] tp-6">
            <button
              type="submit"
              className="bg-darkBlue-200 py-4 text-white-100 w-full rounded-md cursor-pointer"
              onClick={handlePasswordChange}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
