import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { GoSearch } from "react-icons/go";
import { AiOutlineExport } from "react-icons/ai";
import { RiContactsBookUploadLine } from "react-icons/ri";
import { VscCloudUpload } from "react-icons/vsc";
import React, { useContext, useEffect, useState } from "react";
import { Truckowners } from "../../../Types/Truckowners";
import { useNavigate } from "react-router-dom";
import {
  ADD_TRUCK_OWNER_ENDPOINT,
  DELETE_TRUCK_OWNER_ENDPOINT,
  EDIT_TRUCK_OWNER_ENDPOINT,
  SEARCH_TRUCKOWNERS
} from "../../../helpers/constant";
import api, { ApiResponse } from "../../../http/api/api_client";
import MainLayout from "../../../components/main-layout/MainLayout";
import TableShimmer from "../../../components/shimmer/TableShimmer";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import FormFile from "../../../components/formfile/FormFile";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchTruckOwners } from "../../../redux/Slices/truckOwnerSlice";
import AddTruckOwnerModal from "./components/AddTruckOwnerModal";
import EditTruckOwnerModal from "./components/EditTruckOwnerModal";

const TruckOwners = () => {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [ownerPerPage, setOwnerPerPage] = useState<number>(10);
  const [showAddTruckOwner, setShowAddTruckOwner] = useState<boolean>(false);
  const [showEditTruckOwner, setShowEditTruckOwner] = useState<boolean>(false);
  const [showRemoveTruckOwner, setShowRemoveTruckOwner] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { truckOwners, loading, error } = useSelector((state: RootState) => state.truckOwners);

  useEffect(() => {
    dispatch(fetchTruckOwners());
  }, [dispatch]);
  const [deletingTruckOwnerId, setDeletingTruckOwnerId] = useState<string | null>(
    null
  );
  const [selectedTruckOwnerIdCard, setSelectedTruckOwnerIdCard] = useState<String | null>(null);
  const [selectedTruckOwnerPassport, setSelectedTruckOwnerPassport] = useState<String | null>(null);
  const [selectedPicture, setSelectedPicture] = useState<File[]>([]);
  const [showBluckUpload, setShowBluckUpload] = useState<boolean>(false)
  const [editingTruckOwner, setEditingTruckOwner] = useState<Truckowners>();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const router = useNavigate();

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * ownerPerPage;
    setItemOffset(newOffset);
  };

  const handleOwnerPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newOwnerPerPage = parseInt(e.target.value);
    setOwnerPerPage(newOwnerPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(truckOwners.length / ownerPerPage);

  const currentTruckOwner = truckOwners.slice(
    itemOffset,
    itemOffset + ownerPerPage
  );

  const handleSearchTruckOwners = async () => {
    try {
      const response: ApiResponse<any> = await api.get(`${SEARCH_TRUCKOWNERS}?searchWords=${searchQuery}`)
      if (response.statusCode === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error('Error searching truck owners:', error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCreateTruckOwner = async (
    e: React.FormEvent<HTMLFormElement>,
    truckOwnerId: number | null
  ) => {
    e.preventDefault();
    setIsLoading(true)

    const { name, emailAddress, phone, address, BankName, BankAccountNumber, BankAccountName } = e.currentTarget.elements as typeof e.currentTarget.elements & {
      name: HTMLInputElement;
      emailAddress: HTMLInputElement;
      phone: HTMLInputElement;
      address: HTMLInputElement;
      truckId: HTMLInputElement;
      BankName: HTMLInputElement;
      BankAccountNumber: HTMLInputElement;
      BankAccountName: HTMLInputElement;
    };
    // Create the request object
    const requestBody = {
      idCard: selectedTruckOwnerIdCard, // assuming this is a URL or base64 string
      profilePicture: selectedTruckOwnerPassport, // assuming this is a URL or base64 string
      name: name.value,
      emailAddress: emailAddress.value,
      phone: phone.value,
      address: address.value,
      bankName: BankName.value,
      bankAccountNumber: BankAccountNumber.value,
      bankAccountName: BankAccountName.value
    };



    try {
      const response = await api.post(ADD_TRUCK_OWNER_ENDPOINT, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.statusCode === 201) {
        fetchTruckOwners();
        setShowAddTruckOwner(false);
        setSelectedTruckOwnerIdCard(null);
        setSelectedTruckOwnerPassport(null);
        setIsLoading(false)
      }
      console.log("Form submission response:", response.data);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const handleEditTruckOwner = (truckOwner: Truckowners) => {
    setEditingTruckOwner(truckOwner);
    setShowEditTruckOwner(true);
  };

  const handleDeleteTruckOwner = (customerId: string) => {
    setDeletingTruckOwnerId(customerId);
    setShowRemoveTruckOwner(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingTruckOwnerId !== null || deletingTruckOwnerId !== '') {
      const response: ApiResponse<any> = await api.post(`${DELETE_TRUCK_OWNER_ENDPOINT}?ownerId=${deletingTruckOwnerId}`);
      if (response.statusCode === 200) {
        await fetchTruckOwners();
        setShowRemoveTruckOwner(false);
        setDeletingTruckOwnerId('');
      }
    }
  };

  useEffect(() => {
    fetchTruckOwners();
    if (searchQuery !== '') {
      handleSearchTruckOwners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])
  return (
    <>
      <div className="flex justify-between items-start mb-10 py-2 border-b border-gray-300 flex-wrap gap-y-3">
        <div className="flex items-center">
          <h1>Truck Owners</h1>
          <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
            <input
              type="text"
              placeholder="Search Trucks Owner."
              value={searchQuery}
              onChange={handleChange}
              className="outline-none"
            />
            <span
              className="text-gray-500 cursor-pointer"
              onClick={handleSearchTruckOwners}
            >
              <GoSearch className="w-[20px] h-[20px]" />
            </span>
          </div>
        </div>
        <span className="font-bold">
          <button
            className="text-sm text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 hover:bg-gray-500 pr-4"
            onClick={() => setShowAddTruckOwner(true)}
          >
            <span>
              <FaPlus className="w-10" />
            </span>{" "}
            <span>Add Truck Owners</span>
          </button>
        </span>
      </div>

      <div className="flex items-center justify-end my-4 gap-2">
        <button className=" text-sm flex items-center gap-1 justify-between text-darkBlue-100 border border-darkBlue-100 bg-white-100 rounded-md p-2">
          <span>
            <AiOutlineExport />
          </span>
          <span>Export Bluck</span>
        </button>
        <button
          className="text-sm flex items-center gap-1 justify-between border border-myred-100 bg-myred-300 text-myred-100 rounded-md p-2"
          onClick={() => setShowBluckUpload(true)}
        >
          <span>
            <RiContactsBookUploadLine />
          </span>
          <span>Bluck Upload</span>
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
          <thead className="bg-white-100 text-gray-450 py-4 font-light">
            <tr className="border-b border-gray-200 text-[16px]">
              <th className="py-4 px-2 text-gray-350 tracking-wider">Name</th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Address
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Email Address
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">Phone</th>

              <th className="py-4 px-2 text-gray-350 tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="min-w-full divide-y">
            {loading ? (
              <TableShimmer />
            ) : (
              (searchResults.length > 0
                ? searchResults
                : currentTruckOwner
              ).map((data) => (
                <tr
                  key={data.id}
                  className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                >
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/truck-owners/${data.id}`)}
                  >
                    {data.name}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/truck-owners/${data.id}`)}
                  >
                    {data.address}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/truck-owners/${data.id}`)}
                  >
                    {data.emailAddress}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/truck-owners/${data.id}`)}
                  >
                    {data.phone}
                  </td>

                  <td className="flex items-center pt-6 pb-4 px-2 gap-x-4">
                    <span
                      className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                      onClick={() => handleEditTruckOwner(data)}
                    >
                      <FaRegEdit />
                    </span>
                    <span
                      className="text-red-500  cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                      onClick={() => handleDeleteTruckOwner(data.id)}
                    >
                      <RiDeleteBin6Line />
                    </span>
                  </td>
                </tr>
              ))
            )}
            {searchResults.length === 0 && searchQuery && (
              <tr>
                <td colSpan={4}>
                  <div className="flex justify-center items-center h-60">
                    <img
                      src="/nodata.png"
                      width={100}
                      height={100}
                      alt="No data"
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="py-8 mb-[-40px] px-2">
        <div className="lg:flex gap-2 justify-between text-gray-450">
          <div className="flex gap-2">
            <span>View</span>
            <span>
              <select
                onChange={handleOwnerPerPage}
                value={ownerPerPage}
                className="p-1 bg-white-100 cursor-pointer"
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </span>
            <span>Truck owners per page</span>
          </div>
          <div className="flex gap-1">
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageChange}
            />
          </div>
        </div>
      </div>
      <AddTruckOwnerModal isVisible={showAddTruckOwner} onClose={() => setShowAddTruckOwner(false)} fetchTruckOwners={() => fetchTruckOwners()} />

      <EditTruckOwnerModal isVisible={showEditTruckOwner} onClose={() => setShowEditTruckOwner(false)} fetchTruckOwners={() => fetchTruckOwners()} truckOwner={editingTruckOwner!} />

      <Modal
        isVisible={showBluckUpload}
        onClosed={() => setShowBluckUpload(false)}
      >
        <form>
          <div className="py-3 lg:py-5 text-center">
            <button
              className="py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100"
              type="submit"
            >
              Upload
            </button>
          </div>
        </form>
      </Modal>
      <ConfirmationModal
        isVisible={showRemoveTruckOwner}
        onClosed={() => {
          setShowRemoveTruckOwner(false);
          setDeletingTruckOwnerId(null);
        }}
      >
        <div className="w-0 lg:w-[90%]">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Truck owner?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowRemoveTruckOwner(false)}>
              Cancel
            </button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleConfirmDelete}
            >
              Remove
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default TruckOwners;
