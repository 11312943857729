import React from "react";
import { ModalProps } from "../../Types";

const Modal: React.FC<ModalProps> = ({ isVisible, onClosed, children }) => {
  if (!isVisible) return null;

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.id === "wrapper") onClosed();
  };
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-[10000]"
      id="wrapper"
      onClick={handleClose}
    >
      <div className="flex flex-col mt-20 overflow-y-auto max-h-[80vh]">
        <div className="w-[90%] lg:w-[40%] mx-auto text-right">
          <button className="mb-3" onClick={() => onClosed()}>
            <span className="bg-white-300 rounded-[50%] text-darkBlue-100 py-1 px-2">
              X
            </span>
          </button>
        </div>
        <div className="rounded-md bg-white-100 w-[90%] lg:w-[40%] mx-auto py-10 px-5 md:px-10 lg:px-16">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
