import React, { useState } from "react";
import { TbLogout } from "react-icons/tb";
import {logout} from "../../http/auth";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {financialRoutes, mngRoutes, officerRoutes} from "../../utiles";
import ConfirmationModal from "../modal/ConfirmationModal";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { logingout } from "../../redux/Slices/authSlice";

type SideBarProps = {
  handleToggleSideBar: (event: React.SyntheticEvent) => void
}

const MngSideBar = (props: SideBarProps) => {
  const location = useLocation();
  const routerName = location.pathname;
  const router = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  // Logout Session
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      dispatch(logingout());
      logout();
      router("/");
    } catch (err) {
      console.error(err);
    }
  };

  const userString = localStorage.getItem("userKey");
  console.log(userString, "the string");
  const user = userString ? JSON.parse(userString) : null;
  return (
    <aside className="bg-gray-200 w-[300px] sm:w-6/12 md:w-4/12 lg:w-full h-full flex flex-col px-6 peer-focus:left-0 peer:transition ease-out delay-150 duration-200 lg:z-0 z-[99999] pl-[10%]">
      <div className="mb-10 pt-7 flex justify-between">
        <img src="/trucki-logo.png" width={100} height={100} alt="logo" />
        <div className="lg:hidden">
          <IoClose onClick={props.handleToggleSideBar} className="text-3xl" />
        </div>
      </div>
      <ul className="mt-6">
      {user?.role && (user.role.includes("manager") || user.role.includes("chiefmanager")) && (mngRoutes.map((routeItem, index) => {

                  const isActive = routerName === routeItem.layout || routerName.startsWith(routeItem.layout);

                  return (
                      <Link key={index} to={routeItem.layout}>
                          <li
                              className={`w-[70%] flex justify-start items-center py-4 rounded-md hover:text-darkBlue-100 hover:bg-gray-200 px-2 ${
                                  isActive ? "text-darkBlue-100" : "text-gray-500"
                              }`}
                              onClick={props.handleToggleSideBar}
                          >
                <span>
                  {isActive ? routeItem.iconColor : routeItem.icon}
                </span>
                              <span className="text-sm font-medium ml-3">{routeItem.name}</span>
                          </li>
                      </Link>
                  );
              }))}
        {user!.role.includes("finance") &&
          financialRoutes.map((routeItem, index) => {
            const isActive =
              routerName === routeItem.layout ||
              routerName.startsWith(routeItem.layout);

            return (
              <Link key={index} to={routeItem.layout}>
                <li
                  className={`w-[70%] flex justify-start items-center py-4 rounded-md hover:text-darkBlue-100 hover:bg-gray-200 px-2 ${
                    isActive ? "text-darkBlue-100" : "text-gray-500"
                  }`}
                >
                  <span>{isActive ? routeItem.iconColor : routeItem.icon}</span>
                  <span className="pl-2 text-sm font-medium ml-3">
                    {routeItem.name}
                  </span>
                </li>
              </Link>
            );
          })}
        {user!.role.includes("field officer") &&
          officerRoutes.map((routeItem, index) => {
            const isActive =
              routerName === routeItem.layout ||
              routerName.startsWith(routeItem.layout);

            return (
              <Link key={index} to={routeItem.layout}>
                <li
                  className={`w-[70%] flex justify-start items-center py-4 rounded-md hover:text-darkBlue-100 hover:bg-gray-200 px-2 ${
                    isActive ? "text-darkBlue-100" : "text-gray-500"
                  }`}
                >
                  <span>{isActive ? routeItem.iconColor : routeItem.icon}</span>
                  <span className="pl-2 text-sm font-medium ml-3">
                    {routeItem.name}
                  </span>
                </li>
              </Link>
            );
          })}
      </ul>
      <div
        className="flex items-center mt-auto mb-20 text-red-500 ml-6 cursor-pointer w-fit"
        onClick={() => setShowConfirmationModal(true)}
      >
        <span>
          <TbLogout />
        </span>
        <span className="pl-2">Log Out</span>
      </div>
      <ConfirmationModal
        isVisible={showConfirmationModal}
        onClosed={() => setShowConfirmationModal(false)}
      >
        <div className="w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Logout?</span>
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </aside>
  );
};

export default MngSideBar;
