// src/store/Slices/truckOwnerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import api, { ApiResponse } from '../../http/api/api_client';
import { GET_ALL_TRUCK_OWNER_ENDPOINT } from '../../helpers/constant';
import { Truckowners } from '../../Types/Truckowners';

interface TruckOwnerState {
  truckOwners: Truckowners[];
  loading: boolean;
  error: string | null;
}

const initialState: TruckOwnerState = {
  truckOwners: [],
  loading: false,
  error: null,
};

// Async thunk for fetching truck owners
export const fetchTruckOwners = createAsyncThunk<
  Truckowners[],
  void,
  { rejectValue: string }
>('truckOwners/fetchTruckOwners', async (_, { rejectWithValue }) => {
  try {
    const response: ApiResponse<any> = await api.get(GET_ALL_TRUCK_OWNER_ENDPOINT);
    if (response.statusCode === 200) {
      return response.data.data;
    } else {
      return rejectWithValue(response.message || 'Failed to fetch truck owners');
    }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Network Error');
  }
});

const truckOwnerSlice = createSlice({
  name: 'truckOwners',
  initialState,
  reducers: {
    // Define any synchronous actions if needed
    clearTruckOwners(state) {
      state.truckOwners = [];
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTruckOwners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTruckOwners.fulfilled, (state, action: PayloadAction<Truckowners[]>) => {
        state.loading = false;
        state.truckOwners = action.payload;
      })
      .addCase(fetchTruckOwners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch truck owners';
      });
  },
});

export const { clearTruckOwners } = truckOwnerSlice.actions;
export default truckOwnerSlice.reducer;
