"use client";

import React, { useEffect, useState } from "react";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  ADD_CUSTOMERS_ENDPOINT,
  DELETE_CUSTOMERS_ENDPOINT,
  EDIT_CUSTOMERS_ENDPOINT,
  GET_ALL_CUSTOMERS_ENDPOINT,
} from "../../../helpers/constant";
import { CustomerDetails } from "../../../Types/Customer";
import { useNavigate } from "react-router-dom";
import api, { ApiResponse } from "../../../http/api/api_client";
import MainLayout from "../../../components/main-layout/MainLayout";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const Customers = () => {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [customerPerPage, setCustomerPerPage] = useState<number>(10);
  const [showAddCustomers, setShowAddCustomers] = useState<boolean>(false);
  const [showEditCustomer, setShowEditCustomer] = useState<boolean>(false);
  const [showRemoveCustomer, setShowRemoveCustomer] = useState<boolean>(false);
  const [customersTableData, setCustomersTadatable] = useState<
    CustomerDetails[]
  >([]);
  const [customerToEdit, setCustomerToEdit] = useState<CustomerDetails>();
  const [deletingCustomerId, setDeletingCustomerId] = useState<string | null>(
    null
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useNavigate();

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * customerPerPage;
    setItemOffset(newOffset);
  };

  const handleCustomerPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCustomerPerPage = parseInt(e.target.value);
    setCustomerPerPage(newCustomerPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(customersTableData.length / customerPerPage);

  const currentCustomer = customersTableData.slice(
    itemOffset,
    itemOffset + customerPerPage
  );

  const fetchAllCustomers = async () => {
    // setIsLoading(true)
    try {
      const response: ApiResponse<any> = await api.get(
        GET_ALL_CUSTOMERS_ENDPOINT
      );
      if (response.statusCode === 200) {
        const data = response.data;
        setCustomersTadatable(data.data);
        console.log(data.data, "displaying customers data");
      }
    } catch (error) {
      console.error("Error fetching customers data:", error);
    }
  };

  const handleCreatCustomer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target as HTMLFormElement);
    const id = formData.get("id");
    const customerName = formData.get("customerName");
    const phoneNumber = formData.get("phoneNumber");
    const emailAddress = formData.get("emailAddress");
    const location = formData.get("location");
    const rcNo = formData.get("rcNo");

    const requestBody = {
      id,
      customerName,
      phoneNumber,
      emailAddress,
      location,
      rcNo,
    };
    const response: ApiResponse<any> = await api.post(
      ADD_CUSTOMERS_ENDPOINT,
      requestBody
    );
    if (response.statusCode == 201) {
      await fetchAllCustomers();
      setShowAddCustomers(false);
    }
  };

  const handleEditCustomerClick = (customer: CustomerDetails) => {
    setShowEditCustomer(true);
    setCustomerToEdit(customer);
  };

  const handleEditCustomer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.target as HTMLFormElement);
    const customerId = formData.get("customerId");
    const customerName = formData.get("customerName");
    const phoneNumber = formData.get("phoneNumber");
    const emailAddress = formData.get("emailAddress");
    const location = formData.get("location");
    const rcNo = formData.get("rcNo");

    const requestBody = {
      customerId,
      customerName,
      phoneNumber,
      emailAddress,
      location,
      rcNo,
    };

    const response: ApiResponse<any> = await api.post(
      EDIT_CUSTOMERS_ENDPOINT,
      requestBody
    );
    if (response.statusCode == 200) {
      await fetchAllCustomers();
      setShowEditCustomer(false);
      setIsLoading(false);
    }
  };

  const handleDeleteCustomer = (customerId: string) => {
    setDeletingCustomerId(customerId);
    setShowRemoveCustomer(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingCustomerId) {
      const response: ApiResponse<any> = await api.post(
        `${DELETE_CUSTOMERS_ENDPOINT}?customerId=${deletingCustomerId}`
      );
      if (response.statusCode === 200) {
        setShowRemoveCustomer(false);
        setDeletingCustomerId("");
        setCustomersTadatable((customerData) =>
          customerData.filter((customer) => customer.id !== deletingCustomerId)
        );
      } else {
        console.error("Failed to delete customer:", response.statusText);
      }
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, [router]);

  return (
    <>
      <div className="flex justify-between items-start mb-10 py-2 border-b border-gray-300">
        <h1>Customers</h1>
        {/*<span className="font-bold">*/}
        {/*  <button className="text-white-100 bg-darkBlue-100 rounded-sm flex items-center py-3 pr-4 text-xs" onClick={() => setShowAddCustomers(true)}>*/}
        {/*    <span>*/}
        {/*      <FaPlus className="w-10" />*/}
        {/*    </span>{" "}*/}
        {/*    <span>*/}
        {/*      Add Customer*/}
        {/*    </span>*/}
        {/*  </button>*/}
        {/*</span>*/}
      </div>
      <div className="overflow-x-auto">
        <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y text-nowrap">
          <thead className="bg-white-100 text-gray-450 py-4 font-light">
            <tr className="border-b border-gray-200">
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Customer Name
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Mobile Number
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Email Address
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Location
              </th>

              <th className="py-4 px-2 text-gray-350 tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="min-w-full divide-y">
            {customersTableData.length === 0 ? (
              <tr>
                <td colSpan={9}>
                  <div className="flex justify-center items-center h-60">
                    <img
                      src="/nodata.png"
                      width={100}
                      height={100}
                      alt="no data"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              currentCustomer.map((data) => (
                <tr
                  key={data.id}
                  className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                >
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/customers/${data.id}`)}
                  >
                    {data.customerName}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/customers/${data.id}`)}
                  >
                    {data.phoneNumber}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/customers/${data.id}`)}
                  >
                    {data.emailAddress}
                  </td>
                  <td
                    className="py-4 px-2 whitespace-nowrap"
                    onClick={() => router(`/dashboard/customers/${data.id}`)}
                  >
                    {data.location}
                  </td>

                  <td className="flex items-center py-4 px-2 ">
                    <button
                      className="hover:bg-gray-300 p-3 rounded-md"
                      onClick={() => router(`/dashboard/customers/${data.id}`)}
                    >
                      view
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
        <div className="flex items-center gap-2 text-sm text-gray-450">
          <span>View</span>
          <span>
            <select
              onChange={handleCustomerPerPage}
              value={customerPerPage}
              className="p-1 bg-white-100 cursor-pointer"
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </span>
          <span>Location per page</span>
        </div>
        <Pagination pageCount={pageCount} handlePageClick={handlePageChange} />
      </div>
      <Modal
        isVisible={showAddCustomers}
        onClosed={() => setShowAddCustomers(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Add New Customer</h1>
          <form onSubmit={handleCreatCustomer}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="customerName"
                placeholder="Customer Name"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="phoneNumber"
                placeholder="Mobile Number"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="emailAddress"
                placeholder="Email Address"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="location"
                placeholder="Location"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="rcNo"
                placeholder="RC No. (optional)"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />

              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  {isLoading ? "Loading..." : "Add Customer"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isVisible={showEditCustomer}
        onClosed={() => setShowEditCustomer(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Edit Customer</h1>
          <form onSubmit={handleEditCustomer}>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="customerName"
                defaultValue={customerToEdit?.customerName}
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                name="customerId"
                defaultValue={customerToEdit?.id}
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="phoneNumber"
                defaultValue={customerToEdit?.phoneNumber}
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="emailAddress"
                defaultValue={customerToEdit?.emailAddress}
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="location"
                defaultValue={customerToEdit?.location}
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${
                    isLoading ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  {isLoading ? "Loading..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        isVisible={showRemoveCustomer}
        onClosed={() => {
          setShowRemoveCustomer(false);
          setDeletingCustomerId(null);
        }}
      >
        <div className="w-0 lg:w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Customer?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowRemoveCustomer(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleConfirmDelete}
            >
              Remove
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Customers;
