"use client";

import React, { useEffect, useState } from "react";
import SideBar from "../side-nav/SideBar";
import Header from "../header/Header";
import { TfiMenu } from "react-icons/tfi";
// import { IoClose } from "react-icons/io5";
import './mainLayout.scss';
import {Outlet, useNavigate} from "react-router-dom";
import {MainLayoutProps} from "../../Types";
import {ToastContainer} from "react-toastify";
import Preloader from "../Preloader";

const MainLayout: React.FC<MainLayoutProps> = ({ isLoading }) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const router = useNavigate();
  const handleShowSideBar = () => {
    setShowSidebar(!showSidebar);
  };

  const mediaQuery = window.matchMedia("(min-width: 1024px)");
  const [isLargeScreen, setIsLargeScreen] = useState(mediaQuery.matches);

  useEffect(() => {
    mediaQuery.addEventListener("change", (e) => {
      setIsLargeScreen(e.matches)
    })
    if (isLargeScreen) {
      setShowSidebar(true)
    }
  }, [isLargeScreen, mediaQuery])

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('tokenKey');
        if (!token) {
          router('/dashboard');
        } else {
          // setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };

    checkAuth();
  }, [router]);

  return (
    <div className={`flex justify-start lg:justify-end`}>
      {/* {isLoading && <Preloader />}
      <ToastContainer /> */}
      <>
        {/* <div
          className={`${
            // showSidebar ? "sm:block z-50" : "hidden"
          }  h-screen w-[80%] lg:w-[20%] lg:block fixed top-0 left-0`}
        > */}
          {showSidebar? (
            <div
              className="sm:block z-50 h-full w-[80%] lg:w-[20%] lg:block fixed top-0 left-0"
            >
              <SideBar handleShowSideBar={handleShowSideBar}/>
            </div>
          ) : null
            // <div>
            //   <h2
            //     className="sm:block lg:hidden text-3xl fixed top-6 left-3 z-50"
            //     onClick={handleShowSideBar}
            //   >
            //     <TfiMenu />
            //   </h2>
            // </div>
          }
        {/* </div> */}
      </>
      <>
        <main className="w-[100%] lg:w-[80%]">
          <Header handleShowSideBar={handleShowSideBar} showSideBar={showSidebar}/>
          <div className="bg-white-200 p-4 text-lg h-fill mt-16">
            <Outlet />
          </div>
        </main>
      </>
    </div>

  );
};

export default MainLayout;

