import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, DirectionsRenderer, useLoadScript } from "@react-google-maps/api";
import DetailsShimmerCard from "./shimmer/DetailsShimmerCard";

interface OrderTrackingMapProps {
  initialLocation: { lat: number; lng: number };
  orderLocation: { lat: number; lng: number }; // The dynamic location of the order
  routeFrom: { lat: number; lng: number }; // Start location of the route
  routeTo: { lat: number; lng: number };   // End location of the route
  currentLocation: { lat: number; lng: number }; // Current location of the order
}

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const OrderTrackingMap: React.FC<OrderTrackingMapProps> = ({
  initialLocation,
  orderLocation,
  routeFrom,
  routeTo,
  currentLocation
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBwSX4s9x4b_AWDm_Mu9GL60EBp4xQfecA",
    libraries: ["places"],
  });

  // State for the map's center position and directions result
  const [center, setCenter] = useState(initialLocation);
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);

  useEffect(() => {
    // Update the map center and calculate the route when routeFrom or routeTo changes
    setCenter(routeFrom);

    if (isLoaded) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: routeFrom,
          destination: routeTo,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error("Error fetching directions", result);
          }
        }
      );
    }
  }, [routeFrom, routeTo, isLoaded]);

  if (!isLoaded) return <DetailsShimmerCard />;

  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={12} center={center}>
      {/* Render the route using DirectionsRenderer */}
      {directions && <DirectionsRenderer directions={directions} />}

      {/* Marker for the starting point */}
      <Marker position={routeFrom} label="A" />

      {/* Marker for the destination */}
      <Marker position={routeTo} label="B" />

      {/* Marker for the current location */}
      <Marker position={currentLocation} label="Current" />
    </GoogleMap>
  );
};

export default OrderTrackingMap;
