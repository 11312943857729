import React, { useState } from 'react';

const termsData = [
    {
        title: '1. Acceptance of Terms',
        content: 'By using Trucki, you agree to these Terms and Conditions. If you do not agree, please do not use the app. You must be at least 18 years old or have parental permission to use Trucki.'
    },
    {
        title: '2. User Responsibilities',
        content: (
            <ul className="list-disc ml-6">
                <li><strong>Account Security:</strong> Users are responsible for maintaining the confidentiality of their account information and for all activities under their account.</li>
                <li><strong>Compliance with Laws:</strong> Users must comply with all applicable laws and regulations, including those related to freelancing, payments, and employment.</li>
                <li><strong>Prohibited Activities:</strong> Users may not use Trucki to:
                    <ul className="list-disc ml-6">
                        <li>Engage in illegal or unauthorized activities.</li>
                        <li>Misuse the app by spamming, phishing, or fraud.</li>
                        <li>Post harmful, misleading, or inappropriate content.</li>
                    </ul>
                </li>
            </ul>
        )
    },
    {
        title: '3. Trucki’s Responsibilities and Limitations',
        content: (
            <ul className="list-disc ml-6">
                <li><strong>Accuracy of Information:</strong> Trucki provides AI tools and freelance opportunity alerts, but we do not guarantee the accuracy or completeness of job postings or opportunities.</li>
                <li><strong>Third-Party Services:</strong> Trucki integrates with third-party platforms. We are not responsible for the terms, policies, or actions of third-party services.</li>
            </ul>
        )
    },
    {
        title: '4. Data Collection and Privacy',
        content: (
            <ul className="list-disc ml-6">
                <li><strong>Personal Information:</strong> Trucki collects personal information as outlined in our Privacy Policy to provide and improve our services.</li>
                <li><strong>Usage Data:</strong> We may collect usage data to analyze user engagement and improve app performance.</li>
                <li><strong>Third-Party Sharing:</strong> We do not share your personal information with third parties without consent unless required by law or for fraud prevention purposes.</li>
            </ul>
        )
    },
    {
        title: '5. Subscription & Payment Terms',
        content: (
            <ul className="list-disc ml-6">
                <li><strong>Subscription Fees:</strong> Some features of Trucki may require a subscription or payment. By subscribing, you agree to pay all applicable fees.</li>
                <li><strong>Refund Policy:</strong> Refunds are available based on our refund policy, which complies with Google Play’s refund policies.</li>
                <li><strong>Auto-Renewal:</strong> Subscriptions may auto-renew unless canceled. You can manage or cancel subscriptions through Google Play.</li>
            </ul>
        )
    },
    {
        title: '6. Intellectual Property',
        content: (
            <ul className="list-disc ml-6">
                <li><strong>Ownership:</strong> Trucki owns all rights to the app, including text, graphics, and code. Users may not copy or reproduce app content without permission.</li>
                <li><strong>User-Generated Content:</strong> By uploading or submitting content, you grant Trucki a license to use, display, and distribute your content as necessary for app functionality.</li>
            </ul>
        )
    },
    {
        title: '7. Termination and Suspension',
        content: 'Trucki reserves the right to suspend or terminate user accounts for violation of these Terms or if the app is used for unlawful purposes.'
    },
    {
        title: '8. Disclaimers and Limitation of Liability',
        content: (
            <ul className="list-disc ml-6">
                <li><strong>No Guarantee:</strong> Trucki provides services on an “as is” basis, without guarantees or warranties of any kind.</li>
                <li><strong>Limited Liability:</strong> Trucki is not liable for any indirect or incidental damages arising from the use of the app, including loss of data, missed opportunities, or unauthorized access to your account.</li>
            </ul>
        )
    },
    {
        title: '9. Changes to Terms and Conditions',
        content: 'Trucki reserves the right to update these Terms at any time. Users will be notified of significant changes, and continued use of the app constitutes acceptance of updated Terms.'
    },
    {
        title: '10. Governing Law',
        content: 'These Terms are governed by the laws of Nigeria and User’s home state. All disputes will be resolved under this jurisdiction.'
    }
];

const TermsAndConditions = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const toggleSection = (index: React.SetStateAction<number | null>) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <main
        className="flex items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: "url('/login-bg.png')" }}
      >
        <div className="m-5 font-sans  w-2/3">
            <h1 className="text-2xl font-bold mb-5 text-white-200">Trucki Terms and Conditions</h1>
            {termsData.map((section, index) => (
                <div key={index} className="mb-4">
                    <div
                        className="cursor-pointer bg-gray-200 p-4 border border-gray-300 font-bold"
                        onClick={() => toggleSection(index)}
                    >
                        {section.title}
                    </div>
                    {expandedIndex === index && (
                        <div className="p-4 border border-t-0 border-gray-300 text-white-200">
                            {section.content}
                        </div>
                    )}
                </div>
            ))}
        </div>
        </main>
    );
};

export default TermsAndConditions;
