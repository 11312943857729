import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../helpers/constant";

export const apiOne = createApi({
  reducerPath: "apiOne",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    // Define your endpoints here with proper types
    // Example:
    // getExample: builder.query<ExampleType, void>({
    //   query: () => `example-endpoint`,
    // }),
  }),
});
// Define the UserInfo type
interface UserInfo {
  token: string; // Add other properties if needed
}

// Define AuthState and RootState
interface AuthState {
  userInfo: UserInfo | null;
}

interface RootState {
  auth: AuthState;
}

export const apiTwo = createApi({
  reducerPath: "apiTwo",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.auth.userInfo?.token; // Optional chaining to safely access token

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Define your endpoints here
  }),
});