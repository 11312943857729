import React, { useState } from 'react';
import Select from 'react-select';

interface OptionType {
  value: string;
  label: string;
}

interface SearchableSelectProps {
  options: OptionType[];
  onChange: (selectedOption: OptionType | null) => void;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const handleInputChange = (newValue: string) => {
    // Implement search logic here if needed
  };

  const handleChange = (selectedOption: OptionType | null) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

    // *********styling the select component**********
    const customSelectStyles = {
      control: (provided: any, state: { isFocused: any }) => ({
        ...provided,
        border: state.isFocused ? "none" : "1px solid #CCCCCD",
        boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
        padding: "0.4rem 10px",
        borderRadious: "10",
        Zindex: 999,
      }),
    };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      styles={customSelectStyles}
      options={options}
      onInputChange={handleInputChange}
      isSearchable
      placeholder="Search or select..."
    />
  );
};

export default SearchableSelect;
