"use client";

import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { GoSearch } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { VscCloudUpload } from "react-icons/vsc";
import { AllDriverDetails } from "../../../Types/Drivers";
import { useNavigate } from "react-router-dom";
import {
  ADD_DRIVERS_ENDPOINT,
  DELETE_DRIVER_ENDPOINT,
  EDIT_DRIVERS,
  GET_ALL_TRUCK_DRIVERS,
  SEARCH_DRIVERS
} from "../../../helpers/constant";
import api, { ApiResponse } from "../../../http/api/api_client";
import MainLayout from "../../../components/main-layout/MainLayout";
import TableShimmer from "../../../components/shimmer/TableShimmer";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import FormFile from "../../../components/formfile/FormFile";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const Drivers = () => {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [driversPerPage, setDriversPerPage] = useState<number>(10);
  const [driversTableData, setDriversTableData] = useState<
    AllDriverDetails[]
  >([]);
  const [addDriversModal, setAddDriverModal] = useState<boolean>(false);
  const [editingDrivers, setEditingDrivers] = useState<AllDriverDetails>();
  const [showRemoveDriverModal, setShowRemoveDriverModal] = useState<boolean>(false)
  const [deletingDriverId, setDeletingDriverId] = useState<string | null>(
    null
  );
  const [showEditDriverModal, setShowEditDriverModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedPicture, setSelectedPicture] = useState<String | null>(null);
  const [selectedFile, setSelectedFile] = useState<String | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddingDriver, setIsAddingDriver] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const router = useNavigate()

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * driversPerPage;
    setItemOffset(newOffset);
  };

  const handleDriversPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDriversPerPage = parseInt(e.target.value);
    setDriversPerPage(newDriversPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(driversTableData.length / driversPerPage);

  const currentDriver = driversTableData.slice(
    itemOffset,
    itemOffset + driversPerPage
  );

  const handleSearchDrivers = async () => {
    try {
      const response: ApiResponse<any> = await api.get(`${SEARCH_DRIVERS}?searchWords=${searchQuery}`)
      if (response.statusCode === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error('Error searching drivers:', error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const fetchAllDrivers = async () => {
    setIsLoading(true)
    try {
      const response: ApiResponse<any> = await api.get(GET_ALL_TRUCK_DRIVERS);
      if (response.statusCode === 200) {
        const data = response.data;
        setDriversTableData(data.data);
      }
    } catch (error) {
      console.error("Error fetching drivers data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
    }
  }

  const handleAddDriver = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsAddingDriver(true);
    setErrorMessage(null); // Reset error message on new submission

    // Type assertion to access form elements
    const { name, email, number, address, truckId } = e.currentTarget.elements as typeof e.currentTarget.elements & {
      name: HTMLInputElement;
      email: HTMLInputElement;
      number: HTMLInputElement;
      address: HTMLInputElement;
      truckId: HTMLInputElement;
    };

    // Create the request object
    const requestBody = {
      picture: selectedPicture,
      idCard: selectedFile,
      name: name.value,
      email: email.value,
      number: number.value,
      address: address.value,
    };

    try {
      const response = await api.post(ADD_DRIVERS_ENDPOINT, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAddDriverModal(false);
      setIsAddingDriver(false);
      await fetchAllDrivers();
      console.log("Form submission response:", response.data);
    } catch (error: any) {
      console.error("Form submission error:", error);
      setIsAddingDriver(false);

      // Extract error message or set a default
      const message = error.response?.data?.message || "Something went wrong";
      setErrorMessage(message);
    }
  };

  const handleEditDrivers = (driver: AllDriverDetails) => {
    setShowEditDriverModal(true);
    setEditingDrivers(driver);
  };

  const handleUpdateDrivers = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsLoading(true)

    const formData = new FormData(e.target as HTMLFormElement);
    const id = formData.get('id');
    const name = formData.get('name');
    const emailAddress = formData.get('emailAddress');
    const number = formData.get('number');

    try {
      const response = await api.post(EDIT_DRIVERS, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setShowEditDriverModal(false);
      setIsLoading(false)
      await fetchAllDrivers();
      console.log("Form submission response:", response.data);
    } catch (error) {
      console.error("Form submission error:", error);
    }

  };

  const handleDeleteDrivers = (driverId: string) => {
    setDeletingDriverId(driverId);
    setShowRemoveDriverModal(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingDriverId) {
      const response: ApiResponse<any> = await api.post(`${DELETE_DRIVER_ENDPOINT}?driverId=${deletingDriverId}`);
      if (response.statusCode === 200) {
        setShowRemoveDriverModal(false);
        setDeletingDriverId('');
        setDriversTableData(prevData => prevData.filter(driver => driver.id !== deletingDriverId));
      } else {
        console.error('Failed to delete driver:', response.statusText);
      }
    }
  };

  useEffect(() => {
    if (searchQuery !== '') {
      handleSearchDrivers()
    }
    fetchAllDrivers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, searchQuery]);

  return (
    <>
      <div className="flex justify-between items-start mb-10 py-2 border-b border-gray-300 flex-wrap gap-y-3">
        <div className="flex items-center">
          <h1>Drivers</h1>
          <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
            <input
              type="text"
              placeholder="Search Driver"
              value={searchQuery}
              onChange={handleChange}
              className="outline-none"
            />
            <span
              className="text-gray-500 cursor-pointer"
              onClick={handleSearchDrivers}
            >
              <GoSearch className="w-[20px] h-[20px]" />
            </span>
          </div>
        </div>
        <span className="font-bold">
          <button
            className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
            onClick={() => setAddDriverModal(true)}
          >
            <span>
              <FaPlus className="w-10" />
            </span>{" "}
            <span>Add Driver</span>
          </button>
        </span>
      </div>
      <div className="overflow-x-auto">
        <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
          <thead className="bg-white-100 text-gray-450 py-4 font-light">
            <tr className="border-b border-gray-200">
              <th className="py-4 px-2 text-gray-350 tracking-wider">Name</th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Phone Number
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">
                Email Address
              </th>
              <th className="py-4 px-2 text-gray-350 tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="min-w-full divide-y ">
            {isLoading ? (
              <TableShimmer />
            ) : (
              (searchResults.length > 0 ? searchResults : currentDriver).map(
                (data) => (
                  <tr
                    key={data.id}
                    className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                  >
                    <td
                      className="py-4 px-2 whitespace-nowrap"
                      onClick={() => router(`/dashboard/drivers/${data.id}`)}
                    >
                      {data.name}
                    </td>
                    <td
                      className="py-4 px-2 whitespace-nowrap"
                      onClick={() => router(`/dashboard/drivers/${data.id}`)}
                    >
                      {data.phone}
                    </td>
                    <td
                      className="py-4 px-2 whitespace-nowrap"
                      onClick={() => router(`/dashboard/drivers/${data.id}`)}
                    >
                      {data.emailAddress}
                    </td>
                    <td className="flex items-center gap-x-4 py-4 px-2">
                      <span
                        className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                        onClick={() => handleEditDrivers(data)}
                      >
                        <FaRegEdit />
                      </span>
                      <span
                        className="text-red-500  cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                        onClick={() => handleDeleteDrivers(data.id)}
                      >
                        <RiDeleteBin6Line />
                      </span>
                    </td>
                  </tr>
                )
              )
            )}

            {currentDriver.length === 0 &&
              searchResults.length === 0 &&
              !searchQuery && (
                <tr>
                  <td colSpan={4}>
                    <div className="flex justify-center items-center h-60">
                      <img
                        src="/nodata.png"
                        width={100}
                        height={100}
                        alt="No data"
                      />
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
        <div className="flex items-center gap-2 text-sm text-gray-450">
          <span>View</span>
          <span>
            <select
              onChange={handleDriversPerPage}
              value={driversPerPage}
              className="p-1 bg-white-100 cursor-pointer"
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </span>
          <span>Drivers per page</span>
        </div>
        <Pagination pageCount={pageCount} handlePageClick={handlePageChange} />
      </div>

      <Modal
        isVisible={addDriversModal}
        onClosed={() => setAddDriverModal(false)}
      >
        <div>
          <h1 className="mb-10 font-bold">Add New Driver</h1>
          <form onSubmit={handleAddDriver}>
            <div className="pb-4">
              <FormFile
                id="profile-picture-input" // Unique ID
                fileText='Upload Driver"s" Profile Picture (PNG, JPG or PFD)'
                fileText2="Drag & drop file or"
                browse="Browse"
                icon={<VscCloudUpload />}
                onUpload={(e: React.SetStateAction<String | null>) =>
                  setSelectedFile(e)
                }
              />
            </div>
            <div className="pb-4">
              <FormFile
                id="id-card-input" // Unique ID
                fileText='Upload Driver"s" ID Card (PNG, JPG or PFD)'
                fileText2="Drag & drop file or"
                browse="Browse"
                icon={<VscCloudUpload />}
                onUpload={(e: React.SetStateAction<String | null>) =>
                  setSelectedPicture(e)
                }
              />
            </div>
            <div className="flex flex-col justify-between gap-5">
              <input
                type="text"
                name="name"
                placeholder="Driver's Name"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="number"
                placeholder="Mobile number"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="text"
                name="address"
                placeholder="Address"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              <input
                type="hidden"
                name="truckId"
                placeholder="Email"
                className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              />
              {/* Error message */}
              {errorMessage && (
                <div className="text-red-500 text-sm text-center mb-4">
                  {errorMessage}
                </div>
              )}

              <div className="py-3 lg:py-5 text-center">
                <button
                  className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${isAddingDriver ? "opacity-50" : ""
                    }`}
                  type="submit"
                  disabled={isAddingDriver}
                >
                  {isAddingDriver ? "Adding..." : "Add Driver"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isVisible={showEditDriverModal}
        onClosed={() => setShowEditDriverModal(false)}
      >
        <form onSubmit={handleUpdateDrivers}>
          <h1>Edit Driver</h1>
          <div className="mb-4">
            <FormFile
              id="profile-picture-input" // Unique ID
              fileText='Upload Driver"s" Lincense (PNG, JPG or PDF)'
              fileText2="Drag & drop file or"
              browse="Brows"
              icon={<VscCloudUpload />}
              onUpload={(e: React.SetStateAction<String | null>) =>
                setSelectedFile(e)
              }
            />
          </div>
          <div className="flex flex-col justify-between gap-5">
            <input
              type="text"
              name="name"
              defaultValue={editingDrivers?.name}
              className="py-3 pl-4 w-full border-2 border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="hidden"
              name="id"
              defaultValue={editingDrivers?.id}
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="number"
              defaultValue={editingDrivers?.phone}
              className="py-3 pl-4 w-full border-2 border-gray-400 rounded-md bg-transparent"
            />
            <div className="py-5 text-center">
              <button
                className={`text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100 ${isLoading ? "opacity-50" : ""
                  }`}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <ConfirmationModal
        isVisible={showRemoveDriverModal}
        onClosed={() => {
          setShowDeleteModal(false);
          setDeletingDriverId(null);
        }}
      >
        <div className="w-0 lg:w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Driver?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleConfirmDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Drivers;
