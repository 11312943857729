import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserInfo {
  // Define the structure of the userInfo object (modify as per your actual data)
  userkey: {};
  
}

interface AuthState {
  userInfo: UserInfo | null;
}

const initialState: AuthState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    logingout: (state) => {
      state.userInfo = null;
      localStorage.clear(); // This clears all data from localStorage
    },
  },
});

export const { setCredentials, logingout } = authSlice.actions;

export default authSlice.reducer;
