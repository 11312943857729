import React, { ChangeEvent, useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { MdOutlineDateRange } from "react-icons/md";
import { BsArrowUp } from "react-icons/bs";
import { GoArrowDown } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DELETEROUTE_ENDPOINT, EDITROUTE_ENDPOINT, GET_DASHBOARD_GTV_ENDPOINT, GETBUSINESSBYID_ENDPOINT, GetBusinessGtvDashboardSummary } from "../../../../helpers/constant";
import api, { ApiResponse } from "../../../../http/api/api_client";
import { RoutesEntity, BusinessDetails as BusinessDetail, } from "../../../../Types/Business";
import { Link, useParams } from "react-router-dom";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import { FinancialStatusCard } from "../../../../components/finaincial-status/FinancialStatusCard";
import FinancialBarChart from "../../../../components/barchart/FinancialBarChart";
import Modal from "../../../../components/modal/Modal";
import EditRouteModal from "../../../../components/edit-route-modal";
import { BusinessGTVData } from "../../../../Types/DashboardData";
import { formatMoney } from "../../../../utiles/moneyFormatter";


const BusinessDetails = () => {

  const { businessId } = useParams();
  const [businessDetail, setBusinessDetails] = useState<BusinessDetail>();
  const [routeToEdit, setRouteToEdit] = useState<RoutesEntity>();
  const [showEditRouteModal, setShowEditRouteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [gtvData, setGtvData] = useState<BusinessGTVData>({
    totalGtv: 0,
    totalPayout: 0,
    totalRevenue: 0,
    totalCustomers: 0,
    totalOrders: 0,
    pendingOrders: 0,
    completedOrders: 0,
    inTransitOrders: 0,
    monthlyData: [],
  });
  const [preload, setPreload] = useState(true);

  const fetchBusinessDetails = async () => {

    setIsLoading(true)
    try {
      const response: ApiResponse<any> = await api.get(`${GETBUSINESSBYID_ENDPOINT}?businessId=${businessId}`);
      if (response.statusCode === 200) {
        const data = response.data
        setBusinessDetails(data.data);
      }
    } catch (error) {
      console.error("Error fetching business details:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
    }
  }

  const handleEditRouteClick = (routeId: RoutesEntity) => {
    setShowEditRouteModal(true);
    setRouteToEdit(routeId);
  };

  const handleEditRoute = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData(e.target as HTMLFormElement);
      // Extract route data from the form data
      const id = formData.get('id');
      const name = formData.get('name')
      const fromRoute = formData.get('fromRoute');
      const toRoute = formData.get('toRoute');
      const price = formData.get('price');
      const ntons = formData.get('ntons');
      const gtv = formData.get('gtv');
      const isActive = formData.get('isActive') === 'true';

      // Construct the request body with extracted values
      const requestBody = {
        id,
        name,
        fromRoute,
        toRoute,
        price,
        ntons,
        gtv,
        isActive,
      };

      // Send a request to the EDITROUTE_ENDPOINT to update the route
      const response: ApiResponse<any> = await api.post(EDITROUTE_ENDPOINT, requestBody);

      if (response.statusCode === 200) {
        const updatedRoutes = response.data;
        console.log('updated routes', updatedRoutes)
        await fetchBusinessDetails();
      }
    } catch (error) {
      console.error('Error editing route:', error);
    } finally {
      setIsLoading(false);
      setShowEditRouteModal(false);
    }
  };

  useEffect(() => {
    fetchBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId])

  const handleDeleteRoute = async (id: string) => {
    const response: ApiResponse<any> = await api.post(`${DELETEROUTE_ENDPOINT}?routeId=${id}`);
    if (response.statusCode == 200) {
      await fetchBusinessDetails();
    }
  };
  const fetchDashboardGTV = async (start: string, end: string) => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${GetBusinessGtvDashboardSummary}?startDate=${start}&endDate=${end}&businessId=${businessId}`
      );
      if (response.statusCode === 200) {
        const data = response.data;
        setGtvData(data.data);
        console.log(data.data, "GTV data");
      }
    } catch (error) {
      console.error("Error fetching GTV data:", error);
    }
  };

  const handleDateChange =
    (
      setter: React.Dispatch<React.SetStateAction<string>>,
      isStartDate: boolean
    ) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setter(value);

        const newStartDate = isStartDate ? value : startDate;
        const newEndDate = !isStartDate ? value : endDate;

        if (new Date(newStartDate) > new Date(newEndDate)) {
          console.error("Start date cannot be greater than end date");
          return;
        }

        fetchDashboardGTV(newStartDate, newEndDate);
      };

  useEffect(() => {
    // Wait for 3 seconds
    setTimeout(() => {
      setPreload(false);
    }, 3000);

    setIsLoading(true);
    const defaultStart = new Date(
      new Date().setMonth(new Date().getMonth() - 5)
    )
      .toISOString()
      .split("T")[0];
    const defaultEnd = new Date().toISOString().split("T")[0];
    setStartDate(defaultStart);
    setEndDate(defaultEnd);
    fetchDashboardGTV(defaultStart, defaultEnd);
    setIsLoading(false);
  }, []);
  return (
    <>
      <div>
        <Link
          to="/dashboard/business"
          className="flex items-center gap-3 text-darkBlue-100"
        >
          <span>
            <FaCircleArrowLeft />
          </span>
          <span>Back</span>
        </Link>
        <div className="flex items center gap-3 pt-4 pb-1 border-b border-gray-200">
          <h1 className="text-gray-350">Business</h1>/
          <span>Business Details</span>
        </div>
        {isLoading ? (
          <DetailsShimmerCard />
        ) : (
          <div className="flex flex-col justify-between lg:flex-row bg-white-100 py-6 px-4 rounded-md mt-4 h-2/5 flex-nowrap max-w-[100vw] overflow-x-scroll">
            <div className="flex flex-col justify-between text-sm w-[100%]">
              <div className="flex flex-row justify-between">
                <p className="flex flex-col">
                  <span className="text-gray-350 lg:mb-3">Business Name</span>
                  <span className="text-[10px] md:text-[12px]">{businessDetail?.name}</span>
                </p>
                <p className="flex flex-col">
                  <span className="text-gray-350 lg:mb-3">Business Location</span>
                  <span className="text-[10px] md:text-[12px]">{businessDetail?.location}</span>
                </p>
                <p className="flex flex-col">
                  <span className="text-gray-350 lg:mb-3">Business Address</span>
                  <span className="text-[10px] md:text-[12px]">{businessDetail?.address}</span>
                </p>
              </div>
              <p className="flex mt-10 flex-col">
                <span className="text-gray-350 lg:mb-3">Routes</span>
                <div
                  className={`grid grid-cols-1 lg:grid-cols-3 gap-4 overflow-y-auto ${businessDetail?.routes?.length! > 6 ? "h-96" : "h-48"}`}>
                  {businessDetail?.routes?.map(route => (
                    <div key={route.id}
                      className="route-item rounded flex flex-col justify-between bg-teal-50 m-2 p-3">
                      <p>
                        {route.fromRoute} - {route.toRoute} - {route.ntons} tons - N{route.price}
                      </p>
                      <div className='flex flex-row mt-2'>
                        <span
                          className="text-darkBlue-100 cursor-pointer mx-3"
                          onClick={() => handleEditRouteClick(route)}
                        >
                          <FaRegEdit />
                        </span>
                        <span
                          className="text-red-500 cursor-pointer"
                          onClick={() => handleDeleteRoute(route.id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </p>
            </div>
          </div>
        )}

        <div className="flex flex-col lg:flex-row gap-3 justify-between mt-5">
          <div className="w-[100%] lg:w-[70%] bg-white-100 p-2 rounded-md">
            <div className="flex justify-between items-center p-2">
              <span>Financial</span>
              <div
                className="flex items-center justify-start text-sm text-darkBlue-100 gap-x-1 sm:gap-x-2">
                {/* <span className="pr-2">January 2024</span>
                  <span>
                    <MdOutlineDateRange/>
                  </span> */}
                <div className="text-sm text-darkBlue p-1 sm:p-2 rounded-md">
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleDateChange(setStartDate, true)}
                    className="py-3 px-0 sm:px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                  />
                </div>
                <div className="text-sm text-darkBlue p-1 sm:p-2 rounded-md">
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleDateChange(setEndDate, false)}
                    className="py-3 px-0 sm:px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                  />
                </div>
              </div>
            </div>
            <div
              className="flex justify-between py-2 gap-3 px-2 lg:py-4 bg-white-200 overflow-x-auto overflow-hidden md:overflow-x-scroll">
              <FinancialStatusCard
                title="Total GTV"
                amount={formatMoney(gtvData!.totalGtv)}
                percentage={<BsArrowUp />}
              />
              <FinancialStatusCard
                title="Total Payout"
                amount={formatMoney(gtvData!.totalPayout)}
                percentage={<BsArrowUp />}
              />
              <FinancialStatusCard
                title="Total Revenue"
                amount={formatMoney(gtvData!.totalRevenue)}
                percentage={<GoArrowDown />}
              />
              <FinancialStatusCard
                title="Income"
                amount={formatMoney(
                  gtvData!.totalRevenue - gtvData!.totalPayout
                )}
                percentage={<BsArrowUp />}
              />
            </div>
            <div className="mt-[20px] w-[100%] overflow-x-auto overflow-hidden">
              {preload ? (
                <DetailsShimmerCard />
              ) : (
                <FinancialBarChart data={gtvData!.monthlyData} />
              )}
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 text-xs py-3">
                <span className="w-[10px] h-[10px] rounded-[50%] bg-[#CE2A96]"></span>
                <span>Revenue</span>
              </div>
              <div className="flex items-center gap-2 text-xs py-3">
                <span className="w-[10px] h-[10px] rounded-[50%] bg-[#8884d8]"></span>
                <span>Income</span>
              </div>
              <div className="flex items-center gap-2 text-xs py-3">
                <span className="w-[10px] h-[10px] rounded-[50%] bg-[#6B60FF]"></span>
                <span>Income</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-5">
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="bg-white-100 rounded-md text-sm py-4 lg:py-8 px-4 border border-gray-200 flex-1">
                <div className="text-sm flex items-center justify-between lg:gap-2">
                  <p>Total Orders</p>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-darkBlue-100">{gtvData.totalOrders}</span>
                </div>
              </div>
              <div className="bg-white-100 rounded-md text-sm py-4 lg:py-8 px-4 border border-gray-200 flex-1">
                <div className="text-sm flex items-center justify-between lg:gap-2">
                  <p>Total Customers</p>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-darkBlue-100">{gtvData.totalCustomers}</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-5 mt-3 lg:mt-0">
              <div className="bg-white-100 rounded-md text-sm py-4 lg:py-8 px-4 border border-gray-200 flex-1">
                <div className="text-sm flex items-center justify-between lg:gap-2">
                  <p>Pending Orders</p>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-darkBlue-100">{gtvData.pendingOrders}</span>
                </div>
              </div>
              <div className="bg-white-100 rounded-md text-sm py-4 lg:py-8 px-4 border border-gray-200 flex-1">
                <div className="text-sm flex items-center justify-between lg:gap-2">
                  <p>Completed Orders</p>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-darkBlue-100">{gtvData.completedOrders}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {showEditRouteModal && (
        <EditRouteModal
          isVisible={showEditRouteModal}
          onClose={() => setShowEditRouteModal(false)}
          routeToEdit={routeToEdit!}
          onRouteUpdated={fetchBusinessDetails}
        />
      )}

    </>
  );
};

export default BusinessDetails
