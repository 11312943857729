import React from "react";

interface EmailConfirmationProp {
  continueLogin: () => void;
}

const EmailConfirmation: React.FC<EmailConfirmationProp> = ({
  continueLogin,
}) => {
  return (
    <div className="w-[100%] bg-white-100 h-full">
      <div className="py-4 pl-4 lg:pl-10 lg:ml-10">
        <img src="/trucki-logo.png" width={100} height={100} alt="" />
      </div>
      <div className="mx-auto w-[90%]  lg:w-[40%] text-center">
        <div className="w-fit mx-auto mt-8">
          <img src="/mail.png" width={200} height={200} alt="verify-logo" />
        </div>
        <h1 className="text-3xl font-medium mt-10 py-4">Email Confirmation</h1>
        <p className="text-gray-450">
          We have sent an email to {"Johndoe@gmail.com"} to activate your
          account, Please check your email or spam.
        </p>
        <div className="mt-[10%]">
          <button
            type="submit"
            className="bg-darkBlue-200 py-4 text-white-100 w-full rounded-md cursor-pointer"
            onClick={continueLogin}
          >
            Continue
          </button>
        </div>
        <div className="pt-3 text-dark-105">
          <span>Didn’t get your email?</span>{" "}
          <span className="text-red-400 cursor-pointer underline">
            Send it again
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
