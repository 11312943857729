import React, { useEffect, useState } from "react";
import { VscCloudUpload } from "react-icons/vsc";
import { ADD_TRUCK_OWNER_ENDPOINT, EDIT_TRUCK_OWNER_ENDPOINT } from "../../../../helpers/constant";
import Modal from "../../../../components/modal/Modal";
import FormFile from "../../../../components/formfile/FormFile";
import api from "../../../../http/api/api_client";
import { Truckowners } from "../../../../Types/Truckowners";

interface EditTruckOwnerModalProps {
    isVisible: boolean;
    truckOwner: Truckowners;
    onClose: () => void;
    fetchTruckOwners: () => void;
}

const EditTruckOwnerModal: React.FC<EditTruckOwnerModalProps> = ({
    isVisible,
    onClose,
    fetchTruckOwners,
    truckOwner,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTruckOwnerIdCard, setSelectedTruckOwnerIdCard] = useState<String | null>(null);
    const [selectedTruckOwnerPassport, setSelectedTruckOwnerPassport] = useState<String | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleUpdateTruckOwner = async (
        e: React.FormEvent<HTMLFormElement>,
    ) => {
        e.preventDefault();
        setIsLoading(true)

        const { id, name, emailAddress, phone, address } =
            e.currentTarget.elements as typeof e.currentTarget.elements & {
                name: HTMLInputElement;
                emailAddress: HTMLInputElement;
                phone: HTMLInputElement;
                address: HTMLInputElement;
                id: HTMLInputElement;

            };

        const requestBody = {
            idCard: selectedTruckOwnerIdCard,
            profilePicture: selectedTruckOwnerPassport,
            name: name.value,
            emailAddress: emailAddress.value,
            phone: phone.value,
            address: address.value,
            id: id.value,
        };
        try {
            const response = await api.post(EDIT_TRUCK_OWNER_ENDPOINT, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.data.statusCode === 200) {
                fetchTruckOwners();
                setIsLoading(false)
                onClose();
                setSelectedTruckOwnerIdCard(null);
                setSelectedTruckOwnerPassport(null);
                console.log("Form submission response:", response.data);
            } else {
                setErrorMessage(response.data.message || "Something went wrong");
            }
        } catch (error: any) {
            setErrorMessage(
                error.response?.data?.message || "Something went wrong. Please try again."
            );
        } finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {

        if (truckOwner) {
            if (truckOwner.idCardUrl) {
                setSelectedTruckOwnerIdCard(truckOwner.idCardUrl);
            }
            if (truckOwner.profilePictureUrl) {
                setSelectedTruckOwnerPassport(truckOwner.profilePictureUrl);
            }
        }
    })

    return (
        <Modal isVisible={isVisible} onClosed={onClose}>
            <div>
                <h1 className="mb-10 font-bold">Add New Truck Owner</h1>
                <form onSubmit={(e) => handleUpdateTruckOwner(e)}>
                    <div className="pb-4">
                        <FormFile
                            id="Upload Truck Owners id card"
                            fileText="Upload Truck Owners id card (PNG, JPG or PDF)"
                            fileText2="Drag & drop file or"
                            browse="Browse"
                            icon={<VscCloudUpload />}
                            onUpload={(e: React.SetStateAction<String | null>) =>
                                setSelectedTruckOwnerIdCard(e)
                            }
                        />
                    </div>
                    <div className="pb-4">
                        <FormFile
                            id="Upload Truck Owners Passport"
                            fileText="Upload Truck Owners Passport (PNG, JPG or PDF)"
                            fileText2="Drag & drop file or"
                            browse="Browse"
                            icon={<VscCloudUpload />}
                            onUpload={(e: React.SetStateAction<String | null>) =>
                                setSelectedTruckOwnerPassport(e)
                            }
                        />
                    </div>
                    <div className="flex flex-col justify-between gap-5">
                        <input
                            type="text"
                            name="name"
                            defaultValue={truckOwner?.name}
                            placeholder="Name"
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        <input
                            type="text"
                            name="address"
                            defaultValue={truckOwner?.address}
                            placeholder="Address"
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        <input
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            defaultValue={truckOwner?.phone}
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        <input
                            type="text"
                            name="emailAddress"
                            placeholder="Email Address"
                            defaultValue={truckOwner?.emailAddress}
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        <input
                            type="hidden"
                            name="id"
                            defaultValue={truckOwner?.id}
                            className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
                        />
                        {errorMessage && (
                            <div className="text-red-500 text-center mt-2">{errorMessage}</div>
                        )}
                        <div className="py-3 lg:py-5 text-center">
                            <button
                                className={`py-3 bg-darkBlue-100 w-[100%] rounded-md cursor-pointer text-white-100 ${isLoading ? "opacity-50" : ""
                                    }`}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? "Adding..." : "Add Truck Owner"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EditTruckOwnerModal;
