import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { Company, OfficerDetails as OfficerDetail } from "../../../../Types/Officer";
import { ALLBUSINESS_ENDPOINT, EditAssignedBusinessesToOfficer, GET_ALL_OFFICERSBYID } from "../../../../helpers/constant";
import api, { ApiResponse } from "../../../../http/api/api_client";
import MainLayout from "../../../../components/main-layout/MainLayout";
import { Link, useParams } from "react-router-dom";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import Modal from "../../../../components/modal/Modal";
import Select from "react-select";

const OfficersDetails = () => {
  const { officerId } = useParams();
  const [officerDetails, setOfficerDetails] = useState<OfficerDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [business, setBusiness] = useState<Company[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const fetchAllBusiness = async () => {
    try {
      const response: ApiResponse<any> = await api.get(ALLBUSINESS_ENDPOINT);
      if (response.statusCode === 200) {
        const data = response.data;
        setBusiness(data.data);
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };
  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsButtonLoading(true);
    setError(null); // Reset error state
    try {
      const form = e.target as HTMLFormElement;
      const formData = new FormData(form);
      const companyId = formData.get("companyId") as string;
      const response: ApiResponse<any> = await api.post(`${EditAssignedBusinessesToOfficer}?officerId=${officerId}&newCompanyId=${companyId}`);
      if (response.statusCode === 200) {
        setIsModalOpen(false);
        fetchOfficerDetails();
      }
    } catch (error: any) {
      console.error("Error updating officer:", error);
      setError(error?.message || "Something went wrong, try again.");
    } finally {
      setIsButtonLoading(false);
    }
  }; const fetchOfficerDetails = async () => {
    setIsLoading(true)
    try {
      const response: ApiResponse<any> = await api.get(`${GET_ALL_OFFICERSBYID}?officerId=${officerId}`);
      if (response.statusCode == 200) {
        const data = response.data;
        console.log(data, 'displaying the manager data')
        setOfficerDetails(data.data);
      }
    } catch (error) {
      console.error("Error fetching officers data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
    }
  }

  useEffect(() => {

    fetchOfficerDetails();
    fetchAllBusiness();
  }, [officerId])
  const businessOptions = business.map((bus) => ({
    value: bus.id,
    label: `${bus.name} - ${bus.location}`,
  }));
  // *********styling the select component**********
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "none" : "1px solid #CCCCCD",
      boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
      padding: "0.4rem 6px",
      borderRadious: "10",
    }),
  };

  return (
    <div>
      <Link
        to="/dashboard/officers/"
        className="flex items-center gap-3 text-darkBlue-100"
      >
        <span>
          <FaCircleArrowLeft />
        </span>
        <span>Back</span>
      </Link>
      <div className="flex items center gap-3 pt-4 pb-1 border-b border-gray-200">
        <h1 className="text-gray-350">Officers</h1>/
        <span>Officers Detials</span>
      </div>
      {isLoading ? <DetailsShimmerCard /> :
        <div className="rounded-lg px-4 py-6 bg-white-100 text-sm">
          <div className="flex justify-between">
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Name</span>
              <span>{officerDetails?.officerName}</span>
            </p>
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Mobile Number</span>
              <span>{officerDetails?.phoneNumber}</span>
            </p>
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Email</span>
              <span>{officerDetails?.emailAddress}</span>
            </p>
          </div>
          <div className="pt-10">
            <p className="flex flex-col">
              <span className="text-gray-350 pb-4">Business</span>
              {officerDetails?.company && <span>
                {officerDetails?.company.name} - {officerDetails?.company.location}
              </span>}
            </p>
          </div>
          <button
            className="mt-8 text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 px-4 text-xs hover:bg-gray-500"
            onClick={() => setIsModalOpen(true)}
          >
            Assign Business
          </button>
        </div>}
      <Modal isVisible={isModalOpen} onClosed={() => setIsModalOpen(false)}>
        <form onSubmit={handleSubmit}>
          <h2 className="mb-4 text-lg">Edit Assigned Company</h2>
          {error && <div className="mb-4 text-red-500">{error}</div>}

          <Select
            name="companyId"
            styles={customSelectStyles}
            options={businessOptions}
            placeholder="Select Company"
            className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
          />

          <div className="mt-4">
            <button
              type="submit"
              className={`px-4 py-2 bg-darkBlue-100 text-white rounded mr-2 ${isButtonLoading ? "opacity-50" : ""}`}
              disabled={isButtonLoading}
            >
              <span className="text-white-100">
                {isButtonLoading ? "Loading..." : "Save"}
              </span>
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 bg-gray-500 text-white rounded"
              disabled={isButtonLoading}
            >
              Cancel
            </button>

          </div>
        </form>
      </Modal>
    </div>
  );
};

export default OfficersDetails;
