"use client";

import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import api, { ApiResponse } from "../../../../http/api/api_client";
import { GET_CUSTOMERS_BYID } from "../../../../helpers/constant";
import MainLayout from "../../../../components/main-layout/MainLayout";
import { Link, useParams } from "react-router-dom";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import { CustomerDetails as CustomerDetail } from "../../../../Types/Customer";
import ManagerLayout from "../../../../components/mng-layout/ManagerLayout";

const ManagerCustomerDetails = () => {
  const { customerId } = useParams();

  const [customerDetails, setCustomerDetails] = useState<CustomerDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setIsLoading(true);
      try {
        const response: ApiResponse<any> = await api.get(
          `${GET_CUSTOMERS_BYID}?customerId=${customerId}`
        );
        if (response.statusCode === 200) {
          const data = response.data;
          console.log(data.data, 'displaying the customer"s data');
          setCustomerDetails(data.data);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };
    fetchCustomerDetails();
  }, [customerId]);
  return (
    <>
      <Link
        to="/manager/customers/"
        className="flex items-center gap-3 text-darkBlue-100"
      >
        <span>
          <FaCircleArrowLeft />
        </span>
        <span>Back</span>
      </Link>
      <div className="flex items center gap-3 pt-4 pb-1 border-b border-gray-200">
        <h1 className="text-gray-350">Customers</h1>/
        <span>Customer Detials</span>
      </div>
      {isLoading ? (
        <DetailsShimmerCard />
      ) : (
        <div className="bg-white-100 rounded-md p-3 mt-3 text-sm">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="text-gray-300 pb-2">Name</span>
              <span>{customerDetails?.customerName}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-300 pb-2">Mobile</span>
              <span>{customerDetails?.phoneNumber}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-300 pb-2">Email Address</span>
              <span>{customerDetails?.emailAddress}</span>
            </div>
          </div>

          <div className="mt-8 flex flex-col">
            <span className="text-gray-300 pb-2">Location</span>
            <span>{customerDetails?.location}</span>
          </div>
          <div className="mt-8">
            <button className="bg-myred-100 text-white-100 py-2 px-4 rounded-md">
              Disable Customer
            </button>
          </div>
        </div>
      )}

      <table className="w-[100%] text-[10px] md:text-[12px] text-left min-w-full divide-y mt-3">
        <thead className="text-gray-450 py-4 font-light">
          <tr className="my-2">
            <th className="py-4 px-2 text-gray-350 tracking-wider">
              Invoice ID
            </th>
            <th className="py-4 px-2 text-gray-350 tracking-wider">Date</th>
            <th className="py-4 px-2 text-gray-350 tracking-wider">Status</th>
            <th className="py-4 px-2 tracking-wider"></th>
          </tr>
        </thead>
        <tbody className="min-w-full divide-y">
          <tr className="shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-white-100">
            <td className="py-4 px-2 whitespace-nowrap">TRUCKI101234</td>
            <td className="py-4 px-2 whitespace-nowrap">07/02/2024</td>
            <td className="py-4 px-2 whitespace-nowrap">
              <span className="p-1 bg-mygreen-300 text-mygreen-100 flex items-center w-fit">
                <span className="bg-mygreen-100 w-[10px] h-[10px] flex  mr-1 rounded-full font-black"></span>
                <span>Payment complete</span>
              </span>
            </td>
            <td className="py-4 px-2 whitespace-nowrap">
              <Link to="#">View file</Link>
            </td>
          </tr>
          <tr className="shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-white-100">
            <td className="py-4 px-2 whitespace-nowrap">TRUCKI101234</td>
            <td className="py-4 px-2 whitespace-nowrap">07/02/2024</td>
            <td className="py-4 px-2 whitespace-nowrap">
              <span className="p-1 bg-myred-300 text-myred-100 flex items-center w-fit">
                <span className="bg-myred-100 w-[10px] h-[10px] flex mr-1 rounded-full font-black"></span>
                <span>Awaiting payment</span>
              </span>
            </td>
            <td className="py-4 px-2 whitespace-nowrap">
              <Link to="#">View file</Link>
            </td>
          </tr>
          <tr className="shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-white-100">
            <td className="py-4 px-2 whitespace-nowrap">TRUCKI101234</td>
            <td className="py-4 px-2 whitespace-nowrap">07/02/2024</td>
            <td className="py-4 px-2 whitespace-nowrap">
              <span className="p-1 bg-mygreen-300 text-mygreen-100 flex items-center w-fit">
                <span className="bg-mygreen-100 w-[10px] h-[10px] flex justify-center items-center mr-1 rounded-full font-black"></span>
                <span>Payment complete</span>
              </span>
            </td>
            <td className="py-4 px-2 whitespace-nowrap">
              <Link to="#">View file</Link>
            </td>
          </tr>
          <tr className="shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-white-100">
            <td className="py-4 px-2 whitespace-nowrap">TRUCKI101234</td>
            <td className="py-4 px-2 whitespace-nowrap">07/02/2024</td>
            <td className="py-4 px-2 whitespace-nowrap">
              <span className="p-1 bg-myred-300 text-myred-100 flex items-center w-fit">
                <span className="bg-myred-100 w-[10px] h-[10px] flex justify-center items-center mr-3 rounded-full font-black"></span>
                <span>Awaiting payment</span>
              </span>
            </td>
            <td className="py-4 px-2 whitespace-nowrap">
              <Link to="#">View file</Link>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ManagerCustomerDetails;
