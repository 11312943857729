"use client";

import React, { useEffect, useState } from "react";
import { TfiMenu } from "react-icons/tfi";
import { IoClose } from "react-icons/io5";

import MngSideBar from "../mng-sidebar/MngSideBar";
import ManagerHeader from "../mng-header/ManagerHeader";
import './mngMainLayout.scss';
// import {ManagerLayoutProps} from "../../Types";
import {Outlet, useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";

const ManagerLayout = () => {
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const router = useNavigate();
  const handleToggleSideBar = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const mediaQuery = window.matchMedia("(min-width: 1024px)");
  const [isLargeScreen, setIsLargeScreen] = useState(mediaQuery.matches);

  useEffect(() => {
    mediaQuery.addEventListener("change", (e) => {
      setIsLargeScreen(e.matches)
    })
    if (isLargeScreen) {
      setToggleSidebar(true)
    }
  }, [isLargeScreen, mediaQuery])

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('tokenKey');
        if (!token) {
          router('/');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };

    checkAuth();
  }, [router]);

  return (
    <div className="flex justify-start lg:justify-end">
      {/* <ToastContainer /> */}
      {/* <div
        className={`${isLoading ? "flex" : "hidden"} justify-center items-center fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 w-screen h-screen`}
      >
        <div className='loader'></div>
      </div> */}
      <>
        {/* <h2
          className="sm: block lg:hidden text-3xl absolute top-6 left-3 z-50"
          onClick={handleToggleSideBar}
        >
          <TfiMenu />
        </h2> */}
        <div
          className={`${
            toggleSidebar ? "sm:block z-50" : "hidden"
          }  h-screen w-[80%] lg:w-[20%] lg:block fixed top-0 left-0`}
        >
          {toggleSidebar? (
            <div
              className="sm:block z-50 h-full w-[80%] lg:w-[20%] lg:block fixed top-0 left-0"
            >
              <MngSideBar handleToggleSideBar={handleToggleSideBar}/>
            </div>
          ) : null
            // <div>
            //   <h2
            //     className="sm:block lg:hidden text-3xl fixed top-6 left-3 z-50"
            //     onClick={handleShowSideBar}
            //   >
            //     <TfiMenu />
            //   </h2>
            // </div>
          }
          {/* <h2
            className="absolute text-3xl top-6 pr-3 right-0 lg:hidden"
            onClick={handleToggleSideBar}
          >
            <IoClose />
          </h2> */}
        </div>
        <main className="w-[100%] lg:w-[80%]">
          <ManagerHeader handleToggleSideBar={handleToggleSideBar} toggleSideBar={toggleSidebar}/>
          <div className="bg-white-200 p-4 text-lg h-fill mt-16">
            <Outlet />
          </div>
        </main>
      </>
    </div>
  );
};

export default ManagerLayout;
