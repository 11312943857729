import React, { useState } from 'react';


interface ImageComponentProps {
    imageUrl: string;
    altText: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ imageUrl, altText }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div>
            <img
                src={imageUrl}
                alt={altText}

                className="w-48 h-48 object-cover cursor-pointer"
                onClick={openModal}
            />
            <button
                onClick={openModal}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
            >
                View
            </button>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                    <div className="relative bg-white p-4 rounded-lg max-w-3xl w-full">
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-2 text-2xl text-white font-bold"
                        >
                            &times;
                        </button>
                        <img
                            src={imageUrl}
                            alt={altText}
                            className="w-full h-auto object-cover"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageComponent;
