import React, { ChangeEvent, useEffect, useState } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import { BsArrowUp } from "react-icons/bs";
import { GoArrowDown } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {
  DashboardData,
  GTVData,
  OrderStats,
} from "../../../Types/DashboardData";
import api, { ApiResponse } from "../../../http/api/api_client";
import {
  GET_DASHBOARD_GTV_ENDPOINT,
  GET_DASHBORA_ENDPOINT,
  GET_ORDER_STATS,
} from "../../../helpers/constant";
import MainLayout from "../../../components/main-layout/MainLayout";
import SummaryCard from "../../../components/summary-card/SummaryCard";
import {
  FinancialStatusCard,
  StatsCard,
} from "../../../components/finaincial-status/FinancialStatusCard";
import FinancialBarChart from "../../../components/barchart/FinancialBarChart";
import ExpensesChart from "../../../components/barchart/ExpensesChart";
import DrivingChart from "../../../components/barchart/DrivingChart";
import Preloader from "../../../components/Preloader";
import withAuth from "../../../components/protect-route/WithAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { formatMoney } from "../../../utiles/moneyFormatter";
import { motion } from "framer-motion";
import DetailsShimmerCard from "../../../components/shimmer/DetailsShimmerCard";
import TripBarChart from "../../../components/barchart/TripBarChart";

const Dashboard = () => {
  const router = useNavigate();
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [gtvData, setGtvData] = useState<GTVData>({
    totalGtv: 0,
    totalPayout: 0,
    totalRevenue: 0,
    monthlyData: [],
  });
  const [orderStats, setOrderStats] = useState<OrderStats>({
    completedOrders: 0,
    flaggedOrders: 0,
    inTransitOrders: 0,
    totalOrders: 0,
    monthlyData: [],
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(true);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(GET_DASHBORA_ENDPOINT);
      if (response.statusCode === 200) {
        const data = response.data;
        setDashboardData(data.data);
        console.log(data.data, "dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrderStats = async (start: string, end: string) => {
    try {
      const response: ApiResponse<any> = await api.get(`${GET_ORDER_STATS}?startDate=${start}&endDate=${end}`);
      if (response.statusCode === 200) {
        const data = response.data;
        setOrderStats(data.data);
        console.log(data.data, "order stats data");
      }
    } catch (error) {
      console.error("Error fetching order stats:", error);
    }
  };

  const fetchDashboardGTV = async (start: string, end: string) => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${GET_DASHBOARD_GTV_ENDPOINT}?startDate=${start}&endDate=${end}`
      );
      if (response.statusCode === 200) {
        const data = response.data;
        setGtvData(data.data);
        console.log(data.data, "GTV data");
      }
    } catch (error) {
      console.error("Error fetching GTV data:", error);
    }
  };

  const handleDateChange =
    (
      setter: React.Dispatch<React.SetStateAction<string>>,
      isStartDate: boolean
    ) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setter(value);

        const newStartDate = isStartDate ? value : startDate;
        const newEndDate = !isStartDate ? value : endDate;

        if (new Date(newStartDate) > new Date(newEndDate)) {
          console.error("Start date cannot be greater than end date");
          return;
        }

        fetchDashboardGTV(newStartDate, newEndDate);
        fetchOrderStats(newStartDate, newEndDate);
      };

  useEffect(() => {
    // Wait for 3 seconds
    setTimeout(() => {
      setPreload(false);
    }, 3000);

    setIsLoading(true);
    fetchDashboardData();
    const defaultStart = new Date(
      new Date().setMonth(new Date().getMonth() - 5)
    )
      .toISOString()
      .split("T")[0];
    const defaultEnd = new Date().toISOString().split("T")[0];
    setStartDate(defaultStart);
    setEndDate(defaultEnd);
    fetchDashboardGTV(defaultStart, defaultEnd);
    fetchOrderStats(defaultStart, defaultEnd);
    setIsLoading(false);
  }, []);
  return (
    <>
      <div>
        <h1 className="text-xl pb-3">Dashboard</h1>
        <motion.div
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          className="flex gap-5 flex-wrap"
        >
          <SummaryCard
            img={<img src="/group1.png" width={50} height={50} alt="" />}
            title="Total Businesses"
            rate={dashboardData?.totalBusiness}
            handleCardClick={() => {
              router("/dashboard/business");
            }}
          />
          <SummaryCard
            img={<img src="/group2.png" width={50} height={50} alt="" />}
            title="Total Customers"
            rate={dashboardData?.totalCustomers}
            handleCardClick={() => {
              router("/dashboard/customers");
            }}
          />
          <SummaryCard
            img={<img src="/group3.png" width={50} height={50} alt="" />}
            title="Total No. of Trucks"
            rate={dashboardData?.totalTrucks}
            handleCardClick={() => {
              router("/dashboard/trucks/");
            }}
          />
          <SummaryCard
            img={<img src="/group4.png" width={50} height={50} alt="" />}
            title="Active Truck"
            rate={dashboardData?.totalActiveTrucks}
            handleCardClick={() => {
              router("/dashboard/trucks/");
            }}
          />
        </motion.div>
        <div className="flex flex-col gap-5 lg:flex-row lg:justify-between mt-4">
          <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 300, opacity: 0 }}
            className="w-full lg:w-1/2 bg-white-100 rounded-md p-2"
          >
            <div className="sm:flex justify-evenly sm:justify-between items-center p-2">
              <span className="text-sm sm:text-base">Financial</span>
              <div className="flex sm:gap-x-2 gap-x-1">
                <div className="text-sm text-darkBlue p-2 rounded-md">
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleDateChange(setStartDate, true)}
                    className="py-3 px-0 sm:px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                  />
                </div>
                <div className="text-sm text-darkBlue p-2 sm:p-2 rounded-md">
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleDateChange(setEndDate, false)}
                    className="py-3 px-0 sm:px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2  md:grid-cols-4 py-2 gap-3 px-2 lg:py-4 bg-white-100 overflow-x-auto overflow-hidden md:overflow-x-scroll">
              <FinancialStatusCard
                title="Total GTV"
                amount={formatMoney(gtvData!.totalGtv)}
                percentage={<BsArrowUp />}
              />
              <FinancialStatusCard
                title="Total Payout"
                amount={formatMoney(gtvData!.totalPayout)}
                percentage={<BsArrowUp />}
              />
              <FinancialStatusCard
                title="Total Revenue"
                amount={formatMoney(gtvData!.totalRevenue)}
                percentage={<GoArrowDown />}
              />
              <FinancialStatusCard
                title="Income"
                amount={formatMoney(
                  gtvData!.totalGtv - gtvData!.totalPayout
                )}
                percentage={<BsArrowUp />}
              />
            </div>
            <div className="mt-[20px] w-[100%] overflow-x-auto overflow-hidden">
              {preload ? (
                <DetailsShimmerCard />
              ) : (
                <FinancialBarChart data={gtvData!.monthlyData} />
              )}
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 text-xs py-3">
                <span className="w-[10px] h-[10px] rounded-[50%] bg-[#CE2A96]"></span>
                <span>Revenue</span>
              </div>
              <div className="flex items-center gap-2 text-xs py-3">
                <span className="w-[10px] h-[10px] rounded-[50%] bg-[#8884d8]"></span>
                <span>Income</span>
              </div>
              <div className="flex items-center gap-2 text-xs py-3">
                <span className="w-[10px] h-[10px] rounded-[50%] bg-[#6B60FF]"></span>
                <span>Payout</span>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ x: -300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            className="w-full lg:w-1/2 bg-white-100 rounded-md p-2"
          >
            <div className="flex justify-between items-center p-2">
              <span>Financial</span>
              <div className="text-sm text-darkBlue p-2 rounded-md">
                <input
                  type="date"
                  className="py-3 px-2 w-full border darkBlue rounded-md focus:border-darkBlue bg-transparent"
                />
              </div>
            </div>
            {/* <div className="flex flex-col lg:flex-row mt-0 lg:mt-5">
            <ExpensesChart />
            <div className="flex flex-wrap md:block mt-0 md:mt-5 pt-4 md:pt-6 ml-0 md:ml-0 lg:ml-5">
              <div className="flex items-center text-sm my-3">
                <span className="rounded-full bg-mygreen w-2 h-2 flex items-center"></span>
                <p className="flex flex-col">
                  <span>Maintainance</span>
                  <span className="flex justify-between text-xs text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                </p>
              </div>
              <div className="flex items-center text-sm my-3">
                <span className="rounded-full bg-darkBlue w-2 h-2 flex items-center"></span>
                <p className="flex flex-col">
                  <span>Labour Cost</span>
                  <span className="flex justify-between text-xs text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                </p>
              </div>
              <div className="flex items-center text-sm my-3">
                <span className="rounded-full bg-myyellow w-2 h-2 flex items-center"></span>
                <p className="flex flex-col">
                  <span>Fuel</span>
                  <span className="flex justify-between text-xs text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                </p>
              </div>
              <div className="flex items-center text-sm my-3">
                <span className="rounded-full bg-myyellow-light w-2 h-2 flex items-center"></span>
                <p className="flex flex-col">
                  <span>Others</span>
                  <span className="flex justify-between text-xs text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                </p>
              </div>
            </div>
          </div> */}
            <div className=" ">
              <ExpensesChart />
              {/* <div className="flex justify-between md:block mt-0 md:mt-10 pt-4 md:pt-6 ml-0 md:ml-0 lg:ml-20">
                <div className="flex items-center text-[12px] my-3">
                  <p className="flex flex-col">
                    <span className="flex items-center gap-x-2">
                      <span className="rounded-full text-mygreen-200 bg-mygreen-200 w-[8px] h-[8px] flex items-center"></span>
                      <span className="">Maintainance</span>
                    </span>

                    <span className="flex justify-between text-[10px] text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
                <div className="flex items-center text-[12px] my-3">
                  <p className="flex flex-col">
                    <span className="flex items-center gap-x-2">
                      <span className="rounded-full text-darkBlue-100 bg-darkBlue-100 w-[8px] h-[8px] flex items-center"></span>
                      <span className="">Labour Cost</span>
                    </span>

                    <span className="flex justify-between text-[10px] text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
                <div className="flex items-center text-[12px] my-3">
                  <p className="flex flex-col">
                    <span className="flex items-center gap-x-2">
                      <span className="rounded-full text-myyellow-200 bg-myyellow-200 w-[8px] h-[8px] flex items-center"></span>
                      <span className="">Fuel</span>
                    </span>

                    <span className="flex justify-between gap-x-4 text-[10px] text-gray-500 ">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
                <div className="flex items-center text-[12px] my-3">
                  <p className="flex flex-col">
                    <span className="flex items-center gap-x-2">
                      <span className="rounded-full text-myyellow-100 bg-myyellow-100 w-[8px] h-[8px] flex items-center"></span>
                      <span className="">Others</span>
                    </span>

                    <span className="flex justify-between gap-x-4 text-[10px] text-gray-500">
                      <span>₦ 0</span>
                      <span>30%</span>
                    </span>
                  </p>
                </div>
              </div> */}
            </div>
          </motion.div>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 justify-between mt-4">
          <div className="w-full lg:w-1/2 bg-white-100 rounded-md p-2 overflow-x-auto">
            <div>
              <div className="flex justify-between items-center p-2">
                <span>Driving Distance</span>
              </div>
              {preload ? <DetailsShimmerCard /> : <DrivingChart />}
            </div>
          </div>
          <div className="w-full lg:w-1/2 bg-white-100 rounded-md p-2">
            <div className="flex justify-between items-center p-2">
              <span>Trips Performance</span>
            </div>
            <div className="grid grid-cols-2  md:grid-cols-4 py-2 gap-3 px-2 lg:py-4 bg-white overflow-x-auto">
              <StatsCard
                title="Pending"
                amount={orderStats.totalOrders}
                percentage={<BsArrowUp />}
              />
              <StatsCard
                title="Intransit"
                amount={orderStats.inTransitOrders}
                percentage={<BsArrowUp />}
              />
              <StatsCard
                title="Flagged"
                amount={orderStats.flaggedOrders}
                percentage={<GoArrowDown />}
              />
              <StatsCard
                title="Completed"
                amount={orderStats.completedOrders}
                percentage={<BsArrowUp />}
              />
            </div>
            <div className="mt-5 w-full overflow-x-auto">
              {preload ? (
                <DetailsShimmerCard />
              ) : (
                <TripBarChart data={orderStats!.monthlyData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(Dashboard);
